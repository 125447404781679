/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { FC } from 'react';

import { PsychicAvatarWrapperLink } from 'entities/PsychicAvatarWrapperLink';
import { getPsychicCtaButtons } from 'lib/psychic.service';
import { PsychicPhotoModule } from 'constants/enums';
import { PsychicSimpleViewButtons as Buttons } from 'entities/PsychicSimpleViewButtons';
import { PsychicImageModule } from 'entities/PsychicPhotoModules';
import { PsychicRatingLine } from 'entities/PsychicRatingLine';

import styles from './styles.module.scss';

import { CardInterface } from '../config/declarations';

const Card: FC<CardInterface> = ({
  user,
  psychicSkeleton,
  positionInArray,
  psychic,
  bootStatus,
  view = PsychicPhotoModule.WITH_RATE,
}) => {
  const { profileButton, avatar } = psychicSkeleton;
  const avatarUrl = avatar?.url || '';

  const buttons = getPsychicCtaButtons(psychic, psychicSkeleton, { hashedId: user?.hashedId });

  return (
    <li className={styles.card}>
      <PsychicAvatarWrapperLink
        className={styles.link}
        positionInArray={positionInArray}
        profileButton={profileButton}
        psychic={psychic}
      >
        <PsychicImageModule
          view={view}
          psychic={psychic}
          psychicSkeleton={psychicSkeleton}
          src={psychic.psychicImageUrl || avatarUrl || ''}
        />
        <PsychicRatingLine
          overallScore={psychic.overallScore}
          psychicFrame={psychicSkeleton}
          responses={psychic.responses}
          styles={styles}
        />
      </PsychicAvatarWrapperLink>
      <Buttons
        bootStatus={bootStatus}
        chatButton={buttons.chatButton}
        talkButton={buttons.talkButton}
        callbackButton={buttons.callbackButton}
        inQueueButton={buttons.inQueueButton}
        messageButton={buttons.messageButton}
        psychicFrame={psychicSkeleton}
        shape="Oval"
        psychic={psychic}
      />
    </li>
  );
};

export default Card;
