import { FC } from 'react';
import cn from 'classnames';

import { DataButton } from 'components/Shared/SharedComponents';
import { capitalizeFirstLetter } from 'lib/text.service';

import styles from './CardsViewButton.module.scss';
import type { ICardsViewButton } from './declarations';

const CardsViewButton: FC<ICardsViewButton> = ({ isSelected, shape, ...rest }) => (
  <DataButton
    className={cn(styles.icon, styles[`icon${capitalizeFirstLetter(shape)}`])}
    {...rest}
  >
    {new Array((shape === 'square') ? 4 : 2).fill(0).map((_, i) => (
      <i
        key={i.toString()}
        className={cn(
          styles[shape],
          isSelected ? styles.colorActive : styles.colorInactive,
        )}
      />
    ))}
  </DataButton>
);

export default CardsViewButton;
