/* eslint-disable max-classes-per-file */
/* eslint-disable react/jsx-props-no-spreading */
import React, {
  ChangeEvent,
  useEffect,
  useState,
} from 'react';
import cn from 'classnames';

import styles from 'components/Sections/HoroscopeNewsletters/HoroscopeNewsletters.module.scss';
import { InputAlert, useAlertStore } from 'components/Sections/HoroscopeNewsletters/Inputs/InputsSharedComponents';
import {
  AlertTypes,
  ErrorCodeSignUpNewsletters,
  FreeHoroscopeModalInputs,
} from 'constants/enums';
import { ValidationMark } from 'components/Shared/SharedComponents';
import { FreeHoroscopesCommonInputInterface } from 'components/Sections/HoroscopeNewsletters/Inputs/declarations';

const CommonTextInput: React.FC<FreeHoroscopesCommonInputInterface> = ({
  placeholder,
  value,
  inputClassName,
  inputProps,
  modalErrors,
  isValid,
  isEmail,
  alerts,
  dispatch,
}) => {
  const type = isEmail
    ? FreeHoroscopeModalInputs.IS_EMAIL_VALID
    : FreeHoroscopeModalInputs.IS_NAME_VALID;
  const {
    alert,
    setValidState,
    setInvalidState,
  } = useAlertStore(alerts, type, dispatch);
  const [isFirstEntering, setFirstEnteringState] = useState<boolean>(true);

  useEffect(() => {
    if (!modalErrors?.length) {
      return;
    }

    modalErrors.forEach((error) => {
      if (error.code === ErrorCodeSignUpNewsletters.EMAIL && isEmail) {
        if (error.message && isEmail) {
          setInvalidState(null, error.message);
        } else {
          setInvalidState(AlertTypes.WRONG_EMAIL);
        }
      }
    });
  }, [modalErrors, isEmail]);

  const validateAndChangeValidationState = (value: string) => {
    const regexp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    const isValid = isEmail
      ? !(!value || !value.toLowerCase().match(regexp))
      : Boolean(value);

    if (isValid) {
      setValidState();
    } else {
      const alertType = isEmail
        ? AlertTypes.WRONG_EMAIL
        : AlertTypes.FIRST_NAME_REQUIRED;
      setInvalidState(alertType);
    }
  };

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const inputedValue = e.target.value;

    if (!isValid) {
      validateAndChangeValidationState(inputedValue);
    }

    const type = isEmail ? FreeHoroscopeModalInputs.EMAIL : FreeHoroscopeModalInputs.NAME;

    return dispatch({ type, payload: inputedValue });
  };

  const onLoseFocus = () => {
    validateAndChangeValidationState(value);

    if (isFirstEntering) {
      setFirstEnteringState(false);
    }
  };

  return (
    <div className={styles.freeHoroscopeModalFormControl}>
      <div
        className={cn(
          styles.freeHoroscopeModalFormInput,
        )}
      >
        <label
          htmlFor={inputClassName}
          className={styles.freeHoroscopeModalFormInputPlaceholder}
        >
          {placeholder}
        </label>
        <input
          id={inputClassName}
          placeholder={`Enter ${placeholder}`}
          className={cn(
            inputClassName,
            !isValid && styles.freeHoroscopeModalFormInputInvalid,
          )}
          value={value}
          onChange={onChange}
          onBlur={onLoseFocus}
          required
          {...inputProps}
        />
        { !isFirstEntering && (
          <div className={styles.freeHoroscopeModalFormInputMark}>
            <ValidationMark
              isValid={isValid}
            />
          </div>
        )}
      </div>
      <InputAlert isValid={isValid} alert={alert} />
    </div>
  );
};

export default CommonTextInput;
