import { FC } from 'react';

import { DataLink, WebpImage } from 'components/Shared/SharedComponents';
import { useProfileLink } from 'src/shared/lib/psychics/hooks';

import styles from './PsychicCardTile.module.scss';

import type { IPsychicImage } from '../config/declarations';

const Name: FC<IPsychicImage> = ({ psychic, psychicFrame }) => {
  const labelIcon = psychicFrame.labelsCollection?.items
    ?.find((item) => item?.slug === 'verification-icon');

  const {
    profileLink,
    profileUrl,
  } = useProfileLink(psychicFrame, psychic);

  return (
    <DataLink
      link={profileLink!}
      href={profileUrl}
      className={styles.nameWrapper}
    >

      <span className={styles.name}>{psychic.lineName}</span>
      {labelIcon?.image?.url && (
        <WebpImage
          image="external"
          src={labelIcon?.image?.url}
          className={styles.icon}
        />
      )}
    </DataLink>
  );
};

export default Name;
