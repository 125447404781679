import type { Page, Sidebar } from 'src/__generated__/graphqlTypes';
import variables from 'src/styles/_commonVariables.module.scss';
import { ViewerDevice } from 'constants/enums';

import type { DeviceInfoForSidebar } from './types';

export const getDeviceByWidth = (width: number | null) => {
  if (!width) {
    return null;
  }

  const mobileWidth = +variables.mobileViewMaxWidth;
  const tabletWidth = +variables.commonPageMaxWidth;

  if (width <= mobileWidth) {
    return ViewerDevice.MOBILE;
  }

  if (width > mobileWidth && width <= tabletWidth) {
    return ViewerDevice.TABLET;
  }

  return ViewerDevice.DESKTOP;
};

export const getAsideScreenSpace = (viewerDevice: ViewerDevice, sidebar: Sidebar) => {
  const defaultValue = 20;

  if (viewerDevice === ViewerDevice.DESKTOP && sidebar.screenSpace) {
    return sidebar.screenSpace;
  }

  if (viewerDevice === ViewerDevice.TABLET) {
    if (sidebar.collapse === ViewerDevice.TABLET) {
      return null;
    }

    const tabletSidebarSpace = sidebar.screenSpaceTablet
        || sidebar.screenSpace
        || defaultValue;

    return tabletSidebarSpace;
  }

  if (viewerDevice === ViewerDevice.MOBILE) {
    if (sidebar.collapse === ViewerDevice.MOBILE || sidebar.collapse === ViewerDevice.TABLET) {
      return null;
    }

    const mobileSidebarSpace = sidebar.screenSpaceMobile
        || sidebar.screenSpaceTablet
        || sidebar.screenSpace
        || defaultValue;

    return mobileSidebarSpace;
  }
};

export const shouldSidebarBeActive = (
  deviceInfo: DeviceInfoForSidebar,
  sidebar: Page['sidebar'],
) => {
  if (!deviceInfo.device || !sidebar) {
    return false;
  }

  const calculatedViewerDevice = deviceInfo.width
    ? getDeviceByWidth(deviceInfo.width) || deviceInfo.device
    : deviceInfo.device;

  return Boolean(getAsideScreenSpace(calculatedViewerDevice, sidebar));
};
