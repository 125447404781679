import { HeartImgElementSlug } from 'constants/enums';
import type { Link, Psychic } from 'src/__generated__/graphqlTypes';
import type { RatePerMinuetType, RightPsychic } from 'types/objectTypes';

export const getRatePerMinute = (psychic: RightPsychic, frame: Psychic): RatePerMinuetType => {
  if (psychic.customerPrice === psychic.basePrice) {
    return {
      discount: `${psychic.customerPrice}/${frame.ratePerMinute}`,
      price: `${psychic.basePrice}/${frame.ratePerMinute}`,
    };
  }

  return {
    discount: `${psychic.customerPrice}/${frame.ratePerMinute}`,
    price: psychic.basePrice,
  };
};

export const getPsychicSelectionIcon = (psychic: RightPsychic, frame: Psychic) => {
  const heartButton = frame
    .favoritePsychicCollection?.items
    ?.find((item) => item?.slug === HeartImgElementSlug.BUTTON) as Link | undefined;

  if (psychic.isFavorite && heartButton?.image?.url) {
    return heartButton.image.url;
  }

  if (psychic.isInCircle && frame.circleImage?.url) {
    return frame.circleImage.url;
  }

  return null;
};
