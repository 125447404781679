import { FC } from 'react';
import { useSelector } from 'react-redux';

import { Store } from 'app-redux/types/storeTypes';
import { DataButton } from 'components/Shared/SharedComponents';
import { CustomerType, GAReplacementValue } from 'constants/enums';
import { IFilterActionButtons } from 'components/Sections/PsychicsSetMediumSizeImagesClone/declarations';

const FilterActionButtons: FC<IFilterActionButtons> = ({
  styles,
  doneButton,
  doneButtonText,
  clearButton,
  disabledFilterButton,
  clearFilters,
  updateFilters,
}) => {
  const user = useSelector((store: Store) => store.server.auth.user);

  return (
    <div className={styles.buttons}>
      {doneButton && (
        <DataButton
          disabled={disabledFilterButton}
          link={doneButton}
          onClick={() => {
            if (!disabledFilterButton) {
              updateFilters();
            }
          }}
          className={styles.done}
        >
          {doneButtonText}
        </DataButton>
      )}
      {clearButton && (
        <DataButton
          link={clearButton}
          className={styles.clear}
          gaData={{
            [GAReplacementValue.USER]: user
              ? CustomerType.EC.toUpperCase()
              : CustomerType.NC.toUpperCase(),
          }}
          onClick={clearFilters}
        >
          {clearButton.title}
        </DataButton>
      )}
    </div>
  );
};

export default FilterActionButtons;
