import React from 'react';
import LinkWrapper from 'next/link';
import { BLOCKS, MARKS } from '@contentful/rich-text-types';

import styles from 'components/Sections/HoroscopesSignsHoroscopesAndPredictions/MoreHoroscopes/MoreHoroscopes.module.scss';
import { CommonRichText as Title, WebpImage } from 'components/Shared/SharedComponents';
import classes from 'src/styles/_commonClasses.module.scss';
import { Link } from 'src/__generated__/graphqlTypes';
import { HOROSCOPE_PAGE_DESKTOP_ICON_SIDE_SIZE, HOROSCOPE_PAGE_MOBILE_ICON_SIDE_SIZE } from 'constants/constants';
import { replaceContentfulVariables } from 'lib/text.service';
import { WebpWidthLimitType, RichTextParsersConfig } from 'types/objectTypes';
import { ImageFitMode } from 'constants/enums';
import { MoreHoroscopesInterface } from 'components/Sections/HoroscopesSignsHoroscopesAndPredictions/MoreHoroscopes/declarations';

const getParsersConfig = (): RichTextParsersConfig => ({
  [BLOCKS.HEADING_3]: {
    classNames: styles.moreHoroscopesTitle,
  },
  [BLOCKS.HEADING_2]: {
    classNames: styles.moreHoroscopesTitle,
  },
  [MARKS.BOLD]: {
    classNames: classes.textBold,
  },
});

const MoreHoroscopes: React.FC<MoreHoroscopesInterface> = ({
  moreHoroscopes,
  mobileViewMaxWidth,
  isMobileViewWidth,
}) => {
  const { contentTypesCollection, richTitle } = moreHoroscopes;

  const imageSideSize = `${
    isMobileViewWidth
      ? HOROSCOPE_PAGE_MOBILE_ICON_SIDE_SIZE
      : HOROSCOPE_PAGE_DESKTOP_ICON_SIDE_SIZE}px`;

  const applyLinks = () => {
    const links = contentTypesCollection?.items;

    if (!links) return null;

    const currentDate = new Date();
    const locale = 'en-US';
    const currentMonth = currentDate.toLocaleString(locale, { month: 'long' }).toLowerCase();
    const currentYear = currentDate.toLocaleString(locale, { year: 'numeric' }).toLowerCase();
    const replacementDynamicContentfulValues = { currentMonth, currentYear };

    return links.map((link) => {
      const newLink = link as Link;

      if (!newLink) return null;

      const { image, title, src } = newLink;

      const constructImageWidthLimit = (sideSize: number, isMinWidth: boolean = false) => ({
        width: sideSize,
        height: sideSize,
        isMinWidth,
        media: `${mobileViewMaxWidth}px`,
        fit: ImageFitMode.SCALE,
      });

      const imageWidthLimit: Array<WebpWidthLimitType> = [
        constructImageWidthLimit(HOROSCOPE_PAGE_MOBILE_ICON_SIDE_SIZE),
        constructImageWidthLimit(HOROSCOPE_PAGE_DESKTOP_ICON_SIDE_SIZE, true),
      ];

      const finalSrc = replaceContentfulVariables(src || '', replacementDynamicContentfulValues);

      return (
        <LinkWrapper key={finalSrc} href={finalSrc}>
          <a
            title={title!}
            className={styles.moreHoroscopesLinksLink}
          >
            {image && (
              <WebpImage
                image={image}
                width={imageSideSize}
                height={imageSideSize}
                widthLimit={imageWidthLimit}
                className={styles.moreHoroscopesLinksLinkImage}
              />
            )}
            <span className={styles.moreHoroscopesLinksLinkTitle}>{title}</span>
          </a>
        </LinkWrapper>
      );
    });
  };

  return (
    <div className={styles.moreHoroscopes}>
      <Title
        content={richTitle!}
        parsersConfig={getParsersConfig()}
      />
      <hr className={styles.moreHoroscopesUnderline} />
      <div className={styles.moreHoroscopesLinks}>
        {applyLinks()}
      </div>
    </div>
  );
};

export default MoreHoroscopes;
