import { FC } from 'react';

import { PsychicCardClickableImage } from 'entities/PsychicCardClickableImage';

import styles from './PsychicCardList.module.scss';
import DescriptionInfo from './DescriptionInfo';

import { PsychicDescriptionInterface } from '../config/declarations';

const PsychicDescription: FC<PsychicDescriptionInterface> = ({
  psychic,
  psychicFrame,
  positionInArray,
}) => (
  <div className={styles.description}>
    <PsychicCardClickableImage
      styles={styles}
      psychic={psychic}
      psychicFrame={psychicFrame}
      positionInArray={positionInArray}
    />
    <DescriptionInfo
      psychic={psychic}
      psychicFrame={psychicFrame}
      positionInArray={positionInArray}
    />
    {psychic.usp && (
      <i className={styles.usp}>
        {psychic.usp}
      </i>
    )}
  </div>
);

export default PsychicDescription;
