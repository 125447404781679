import {
  FC,
  MouseEvent,
  useEffect,
  useState,
} from 'react';
import cn from 'classnames';

import type { Link } from 'src/__generated__/graphqlTypes';
import { DataButton, DataLink } from 'components/Shared/SharedComponents';
import { CTMNumber } from 'entities/CTMPhone';

import styles from './styles.module.scss';
import SecondLevelMenu from './2LMenu';

import { useLocalContext, useLocalDispatch } from '../lib/hooks';
import type { IFirstLevelItem, IHeadingButton } from '../config/declarations';

/* Nested Component */
const ButtonWithChildren: FC<IFirstLevelItem> = ({
  headline,
  links,
  back,
}) => {
  const dispatch = useLocalDispatch();
  const { isSubMenuVisible } = useLocalContext();
  const [isSubMenuVisibleLocal, setSubMenuVisibilityState] = useState<boolean>(false);

  const onClick = () => {
    setSubMenuVisibilityState(true);
    dispatch({ type: 'isSubMenuVisible', payload: true });
  };

  useEffect(() => () => setSubMenuVisibilityState(false), []);
  useEffect(() => {
    if (!isSubMenuVisible) {
      setSubMenuVisibilityState(false);
    }
  }, [isSubMenuVisible]);

  if (!headline || headline.__typename === 'Text') {
    return null;
  }

  const link = headline as Link;

  return (
    <li className={cn(styles.li, styles.liBorder)}>
      <DataButton
        className={styles.liButton}
        link={link}
        onClick={onClick}
      >
        {link.title}
      </DataButton>
      <i className={styles.reveal} />
      <SecondLevelMenu
        isSubMenuVisible={isSubMenuVisibleLocal}
        links={links}
        back={back}
        title={link.title}
      />
    </li>
  );
};

/* Nested Component */
const HeadingButton: FC<IHeadingButton> = ({
  headline,
  user,
}) => {
  const isExtraHandling = headline.slug === 'contact';
  const onClick = (e: MouseEvent<HTMLAnchorElement>) => {
    const isInnerLinkClicked = (e.target as HTMLAnchorElement).href && e.currentTarget.href
     && (e.target as HTMLAnchorElement).href !== e.currentTarget.href;

    if (isInnerLinkClicked) {
      e.stopPropagation();
    }
  };

  if (headline.slug === 'karma' && !user?.isKarmaMember) {
    return null;
  }

  if (headline.slug === 'join-karma' && user?.isKarmaMember) {
    return null;
  }

  return (
    <DataLink
      onClickCapture={onClick}
      className={cn(
        styles.liButton,
        { [styles.liButtonFlex]: isExtraHandling },
      )}
      link={headline as Link}
    >
      {(headline as Link).title}
      {isExtraHandling && (
        <CTMNumber
          number=""
          className={styles.number}
          link={headline}
        />
      )}
    </DataLink>
  );
};

/* Main Component */
const FirstLevelItem: FC<IFirstLevelItem> = ({
  user,
  headline,
  links,
  back,
}) => {
  if (!headline || headline.__typename === 'Text') {
    return null;
  }

  if (links?.length > 0) {
    return (
      <ButtonWithChildren
        headline={headline}
        links={links}
        back={back}
      />
    );
  }

  return (
    <li className={cn(styles.li, styles.liBorder)}>
      <HeadingButton user={user} headline={headline as Link} />
    </li>
  );
};

export default FirstLevelItem;
