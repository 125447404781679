import React, { useState } from 'react';

import { Tick } from 'src/shared/ui/Tick';
import styles from 'components/Sections/HoroscopesSignsAllAboutSigns/SelfCareArticles/SelfCareArticles.module.scss';
import { SignFeatureContainer } from 'components/Sections/HoroscopesSignsAllAboutSigns/Shared';
import { SelfCareArticlePicture } from 'components/Sections/HoroscopesSignsAllAboutSigns/SelfCareArticles';
import { DataLink } from 'components/Shared/SharedComponents';
import { Link } from 'src/__generated__/graphqlTypes';
import { AllAboutSignsSelfCareArticlesInterface } from 'components/Sections/HoroscopesSignsAllAboutSigns/SelfCareArticles/declarations';

const SelfCareArticles: React.FC<AllAboutSignsSelfCareArticlesInterface> = ({
  selfCare,
  viewerDevice,
  horoscopeTabletMaxWidth,
  mobileViewMaxWidth,
}) => {
  const [maxImageWidth, setMaxWidth] = useState<number>(0);
  const [maxImageHeight, setImageMaxHeight] = useState<number>(0);

  if (!selfCare) {
    return <SignFeatureContainer containerClassName={styles.gemstones} />;
  }

  const { title, contentTypesCollection, link } = selfCare;
  const selfCareArticlesArray = (contentTypesCollection?.items as Array<Link>) || [];

  const applySelfCareArticlesArray = () => selfCareArticlesArray.map(({
    entryName,
    title,
    image,
  }) => (
    <SelfCareArticlePicture
      key={entryName}
      viewerDevice={viewerDevice}
      maxWidth={maxImageWidth}
      maxHeight={maxImageHeight}
      entryName={entryName!}
      title={title!}
      image={image!}
      horoscopeTabletMaxWidth={horoscopeTabletMaxWidth}
      mobileViewMaxWidth={mobileViewMaxWidth}
      setMaxHeight={setImageMaxHeight}
      setMaxWidth={setMaxWidth}
    />
  ));

  const applyLinkIfExists = () => {
    const isIncompleteData = !link || !link.title || !link.image || !link.src;

    if (isIncompleteData) {
      return null;
    }

    const { title, src } = link!;

    return (
      <DataLink
        href={src!}
        link={link!}
        className={styles.selfCareLink}
      >
        <span className={styles.selfCareLinkTitle}>
          {title}
        </span>
        <Tick
          sideSize={8}
          direction="right"
          color="#F18536"
        />
      </DataLink>
    );
  };

  return (
    <SignFeatureContainer
      title={title!}
      containerClassName={styles.selfCare}
      underlineClassName={styles.selfCareTitleUnderline}
      titleClassName={styles.selfCareTitle}
    >
      <div className={styles.selfCareContent}>
        {applySelfCareArticlesArray()}
      </div>
      {applyLinkIfExists()}
    </SignFeatureContainer>
  );
};

export default SelfCareArticles;
