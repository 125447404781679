import { HYDRATE } from 'next-redux-wrapper';
import { combineReducers } from 'redux';

import auth from 'app-redux/reducers/authReducer';
import app from 'app-redux/reducers/appReducer';
import appServer from 'app-redux/reducers/appServerReducer';
import page from 'app-redux/reducers/pageReducer';
import api from 'app-redux/reducers/apiReducer';
import { ReducerAction, Store } from 'app-redux/types/storeTypes';

const reducers = combineReducers({
  client: combineReducers({ app, api }),
  server: combineReducers({ auth, page, app: appServer }),
});
const mainReducer = (state: any = {}, action: ReducerAction): Store => {
  if (action.type === HYDRATE) {
    const clientData = action.payload.client || {};
    const nextState: Store = {
      client: {
        ...state.client,
        app: {
          ...state.client.app,
          isLoading: false,
          clientWidth: clientData.app?.clientWidth || state.client.app.clientWidth,
          isSidebarActive: clientData.app?.isSidebarActive || state.client.app.isSidebarActive,
        },
      },
      server: {
        ...state.server,
        ...action.payload.server,
      },
    };

    return nextState;
  }

  return reducers(state, action);
};

export default mainReducer;
