import { useEffect, useState } from 'react';

import { useCustomRouter } from 'src/shared/lib/history/hooks';

export const useStylesForPage = () => {
  const router = useCustomRouter();
  const [currentPath, setCurrentPath] = useState<string>(router.pathname);

  useEffect(() => {
    const onChangeComplete = (path: string) => setCurrentPath(path);
    router.events.on('routeChangeStart', onChangeComplete);

    return () => {
      router.events.off('routeChangeStart', onChangeComplete);
    };
  }, []);

  if (currentPath === '/psychics/[slug]' || currentPath === '/psychics-new/[slug]') {
    return {
      desktop: `
      .wrap {
        max-width: 1320px;  
        padding-top: 25px; 
        padding-bottom: 75px;  
      }
      `,
      mobile: '',
      mobileNoSidebar: '',
      desktopNoSidebar: '',
    };
  }

  return {
    desktop: '',
    mobile: '',
    mobileNoSidebar: '',
    desktopNoSidebar: '',
  };
};
