import { FC } from 'react';
import cn from 'classnames';

import { formatDateWithMonthName } from 'lib/date.service';

import styles from './styles.module.scss';

import { HiatusBarInterface } from '../config/declaration';

const HiatusBar: FC<HiatusBarInterface> = ({ psychic, contentfulData, className = '' }) => {
  if (!psychic?.onHiatus) {
    return null;
  }

  const time = psychic?.onHiatusReturnDate
    ? formatDateWithMonthName(psychic?.onHiatusReturnDate)
    : contentfulData.hiatusText?.text;

  const hiatusText = `${contentfulData?.hiatusText?.fullText} ${time}`;

  return (
    <div className={cn(styles.hiatus, className)}>
      {hiatusText}
    </div>
  );
};

export default HiatusBar;
