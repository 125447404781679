import { FC, useState } from 'react';
import cn from 'classnames';

import type { Link } from 'src/__generated__/graphqlTypes';
import { useUpdateCustomerDetails } from 'src/shared/lib/auth/hooks';

import Greet from './Greet';
import Inbox from './Inbox';
import Balance from './Balance';
import Karma from './Karma';
import CollapseButton from './CollapseButton';

import styles from '../styles.module.scss';
import type { IGreeting } from '../../config/declarations';
import { getGreetingLinks } from '../../lib';

const Greeting: FC<IGreeting> = ({ greeting }) => {
  const [isCollapsed, setCollapsedState] = useState<boolean>(false);
  const toggleCollapseButton = () => setCollapsedState((prev) => !prev);
  useUpdateCustomerDetails();

  if (!greeting) {
    return null;
  }

  const restLinks = greeting.contentTypesCollection?.items as Array<Link>;

  const {
    balance,
    joinKarmaRewords,
    karmaHub,
    collapse,
  } = getGreetingLinks(restLinks);

  if (isCollapsed) {
    return (
      <div className={cn(styles.box, styles.collapsedBox)}>
        <Greet text={greeting?.title} />
        <div className={styles.accountInfo}>
          {collapse?.title}
          <CollapseButton
            isCollapsed
            button={collapse}
            toggleCollapseButton={toggleCollapseButton}
          />
        </div>
      </div>
    );
  }

  return (
    <div className={styles.greetingRoot}>
      <Greet text={greeting.title} />
      <div className={cn(styles.box, styles.greeting)}>
        <Inbox link={greeting.link} />
        <hr className={styles.divider} />
        <Balance link={balance} />
        <hr className={styles.divider} />
        <Karma joinKarma={joinKarmaRewords} karmaHub={karmaHub} />
        <CollapseButton
          button={collapse}
          isCollapsed={isCollapsed}
          toggleCollapseButton={toggleCollapseButton}
        />
      </div>
    </div>
  );
};

export default Greeting;
