import { MouseEvent } from 'react';

export const getCardClickHandler = (flipper: () => void) => (e: MouseEvent<HTMLDivElement>) => {
  const element = e.target as Element;
  const tagName = element.tagName.toLowerCase();

  const parentButton = element.closest('[data-button]');
  const modal = element.closest('.ReactModal__Content');
  const modalOverlay = element.closest('.ReactModal__Overlay');

  if (modal || parentButton || modalOverlay || tagName === 'button' || tagName === 'a' || tagName === 'input') {
    return;
  }

  return flipper();
};
