import { FC } from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { useSelector } from 'react-redux';

import type { Store } from 'src/redux/types/storeTypes';
import { Block, ImageWrapper } from 'src/__generated__/graphqlTypes';
import { SectionComponentInterface } from 'types/componentTypes';
import { setItemInObjectBySlug } from 'lib/sharedMethods.service';

import PredefinedReview from './PredefinedReview';
import styles from './PredefinedReviews.module.scss';

import { IconSlug as Slug, slugs } from '../config/constants';

/* Main component */
const PredefinedReviews: FC<SectionComponentInterface> = ({
  blocks,
  bgColor,
}) => {
  const isMobileViewWidth = useSelector((store: Store) => store.server.app.isMobileViewWidth);

  const [block] = blocks;

  const {
    contentTypesCollection,
    imagesCollection,
  } = block;

  if (!block) {
    return null;
  }

  const customerReviews = contentTypesCollection?.items as Array<Block> || [];
  const reducer = setItemInObjectBySlug(slugs);
  const {
    chat,
    star,
  } = (imagesCollection?.items as Array<ImageWrapper>)
    ?.reduce(reducer, {} as Record<Slug, ImageWrapper>) || {};

  const bindReviews = () => customerReviews.map((block) => (
    <PredefinedReview
      key={block.entryName}
      block={block}
      chatImage={chat.image}
      starImage={star.image}
    />
  ));

  return (
    <section
      className={styles.wrapper}
      style={{ background: bgColor! }}
    >
      <div className={styles.review}>
        {isMobileViewWidth
          ? (
            <Carousel
              swipeable
              showArrows={false}
              className={styles.blogCarousel}
              showStatus={false}
              infiniteLoop
            >
              {bindReviews()}
            </Carousel>
          )
          : bindReviews()}
      </div>
    </section>
  );
};

export default PredefinedReviews;
