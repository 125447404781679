import { FC, memo } from 'react';
import { useSelector } from 'react-redux';

import { Store } from 'src/redux/types/storeTypes';
import { FooterLinks } from 'components/Footer/Links';
import { FooterLinksContainerInterface } from 'components/Footer/Links/declarations';

const FooterLinksContainer: FC<FooterLinksContainerInterface> = ({
  navBar,
  mobileApps,
  footerType,
  socialMedias,
  additional,
}) => {
  const isMobileViewWidth = useSelector((store: Store) => store.server.app.isMobileViewWidth);
  const viewerDevice = useSelector((store: Store) => store.server.app.viewerDevice);

  return (
    <FooterLinks
      navBar={navBar}
      footerType={footerType}
      mobileApps={mobileApps}
      socialMedias={socialMedias}
      viewerDevice={viewerDevice}
      isMobileViewWidth={isMobileViewWidth}
      additional={additional}
    />
  );
};

export default memo(FooterLinksContainer);
