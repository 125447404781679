import {
  FC,
  KeyboardEvent,
  useState,
} from 'react';
import { useSelector } from 'react-redux';

import { WebpImage } from 'components/Shared/SharedComponents';
import { Store } from 'app-redux/types/storeTypes';

import styles from './styles.module.scss';
import { PsychicsList } from './PsychicsList';

import { ISearchBoxTooltip, ISearchBoxTooltipClear } from '../config/declarations';

/* Nested component */
const Clear: FC<ISearchBoxTooltipClear> = ({
  searchValue,
  clear,
  searchBox,
}) => {
  if (!searchValue) {
    return null;
  }

  return (
    <button
      className={styles.clear}
      type="button"
      onClick={clear}
    >
      {searchBox?.clear?.title}
    </button>
  );
};

const SearchBarInput: FC<ISearchBoxTooltip> = ({
  searchRef,
  searchValue,
  searchBox,
  psychicsList,
  search,
  clear,
  changeHandler,
  image,
}) => {
  const [isPsychicListVisible, setPsychicListVisibilityState] = useState<boolean>(false);
  const isMobileView = useSelector((store: Store) => store.server.app.isMobileViewWidth);
  const str = searchBox?.placeholder;
  const mobilePlaceholder = str?.replace(/\b(topics)\b/, 'categories')
    .replace(/\s*,\s*(tools|skills)\b/g, '')
    .replace(/\s*,\s*/g, ', ')
    .replace(/\s*,\s*$/, '')
    .trim();
  const searchPlaceholder = isMobileView ? mobilePlaceholder : searchBox.placeholder;

  const onPressSend = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      search?.();
    }
  };

  return (
    <>
      {
        image?.url
          && (
            <WebpImage
              src={image?.url}
              image="external"
              className={styles.searchIcon}
            />
          )
      }
      <input
        ref={searchRef}
        value={searchValue}
        onKeyUp={onPressSend}
        onChange={changeHandler}
        type="text"
        className={styles.input}
        onFocus={setPsychicListVisibilityState.bind(this, true)}
        data-type="search"
        placeholder={searchPlaceholder || ''}
      />
      <Clear
        searchValue={searchValue}
        clear={clear}
        searchBox={searchBox}
      />
      <PsychicsList
        baseLink={searchBox.baseLink!}
        psychicsList={psychicsList}
        isPsychicListVisible={isPsychicListVisible}
      />
    </>
  );
};

export default SearchBarInput;
