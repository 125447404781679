import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';

import { Store } from 'app-redux/types/storeTypes';
import { ViewerDevice } from 'constants/enums';
import { Page, Sidebar } from 'src/__generated__/graphqlTypes';
import { JsxElement } from 'src/widgets/OptionalTrustpilot/config/declarations';
import { Sidebar as SidebarComponent } from 'src/widgets/Sidebar';
import { setSidebarActivity } from 'app-redux/actions/appActions';

import {
  getAsideScreenSpace,
  shouldSidebarBeActive as checkShouldSidebarBeActive,
  getDeviceByWidth,
} from '.';

let wasSidebarDispatched = false;

const useSidebarActivityState = (viewerDevice: ViewerDevice, sidebar: Page['sidebar']) => {
  const dispatch = useDispatch();
  const isSidebarActive = useSelector((store: Store) => store.client.app.isSidebarActive);

  useEffect(() => {
    if (!sidebar || !sidebar.contentCollection?.items?.length) {
      dispatch(setSidebarActivity(false));
      wasSidebarDispatched = true;

      return;
    }

    const shouldSidebarBeActive = checkShouldSidebarBeActive({ device: viewerDevice }, sidebar);

    if (shouldSidebarBeActive === isSidebarActive) {
      wasSidebarDispatched = false;

      return;
    }

    if (wasSidebarDispatched) {
      return;
    }

    wasSidebarDispatched = true;
    dispatch(setSidebarActivity(shouldSidebarBeActive));
  }, [viewerDevice, sidebar, isSidebarActive]);
};

export const useSidebarScreenSpace = (sidebar: Page['sidebar']) => {
  const viewerDevice = useSelector((store: Store) => store.server.app.viewerDevice);
  const clientWidth = useSelector(sidebar
    ? (store: Store) => store.client.app.clientWidth
    : () => null);
  const deviceByWidth = getDeviceByWidth(clientWidth);
  const currentDevice = deviceByWidth || viewerDevice;

  useSidebarActivityState(currentDevice, sidebar);

  if (!sidebar) {
    return null;
  }

  return getAsideScreenSpace(currentDevice, sidebar);
};

export const useSidebarIndexInContent = () => {
  const slug = useSelector((store: Store) => store.server.page.slug);
  const page = useSelector((store: Store) => store.server.page.pages[slug]);
  const { sidebar } = page;
  const screenSpace = useSidebarScreenSpace(sidebar);

  if (typeof sidebar?.collapseAfter !== 'number') {
    return null;
  }

  if (screenSpace) {
    return null;
  }

  return sidebar.collapseAfter;
};

export const useComponentsWithSidebar = (
  components: Array<JsxElement>,
  sidebarComponent: JsxElement,
) => {
  const index = useSidebarIndexInContent();

  if (index === null) {
    return components;
  }

  return components
    .splice(Math.min(index + 1, components.length), 0, sidebarComponent);
};

export const useSidebarInformation = (sidebar: Sidebar) => {
  const viewerDevice = useSelector((store: Store) => store.server.app.viewerDevice);

  return {
    screenSpace: getAsideScreenSpace(viewerDevice, sidebar),
  };
};

export const useSidebarNextToMain = (sidebarComponent: JsxElement) => {
  const index = useSidebarIndexInContent();

  if (!index) {
    return sidebarComponent;
  }

  return null;
};

export const useSidebarComponent = (components: Array<JsxElement>) => {
  const index = useSidebarIndexInContent();
  const sidebarComponent = <SidebarComponent key="sidebar" />;

  if (index) {
    const pasteIndex = Math.min(index, components.length);
    const componentsWithCollapsedSidebar = [...components];
    componentsWithCollapsedSidebar.splice(pasteIndex, 0, sidebarComponent);

    return {
      standaloneSidebar: null,
      componentsWithCollapsedSidebar,
    };
  }

  return {
    standaloneSidebar: sidebarComponent,
    componentsWithCollapsedSidebar: components,
  };
};

export const useGridTemplates = (sidebar: Page['sidebar'], space?: number | null) => {
  if (!sidebar || !space) {
    return null;
  }

  return {
    area: (sidebar.side === 'right')
      ? 'm s'
      : 's m',
    /**
     * Here are used 99 and -1 because some elements can have shadows
     * that won't be visible if we use 100%
    */
    columns: (sidebar.side === 'right')
      ? `${99 - space}fr ${space - 1}fr`
      : `${space}fr ${99 - space - 1}fr`,
  };
};
