import { FC } from 'react';

import { WebpImage } from 'components/Shared/SharedComponents';

import { PsychicNameWithIconInterface } from '../config/declarations';

const PsychicNameWithIcon: FC<PsychicNameWithIconInterface> = ({
  psychicName,
  image,
  className,
  wrapperClass,
}) => (
  <span
    title={psychicName}
    className={wrapperClass}
  >
    {psychicName}
    { image?.url && (
      <WebpImage
        image="external"
        src={image.url}
        className={className}
      />
    )}
  </span>
);

export default PsychicNameWithIcon;
