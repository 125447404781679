import { FC } from 'react';
import { BLOCKS, MARKS } from '@contentful/rich-text-types';
import cn from 'classnames';
import { useRouter } from 'next/router';

import styles from 'components/Sections/HoroscopesSignsSignIntro/SignIntro.module.scss';
import classes from 'styles/_commonClasses.module.scss';
import { CommonRichText, WebpImage } from 'components/Shared/SharedComponents';
import { RichTextParsersConfig, SectionExtraDataType } from 'types/objectTypes';
import { capitalizeFirstLetter } from 'lib/text.service';
import {
  CommonSize,
  FlexDirection,
  Heading,
  MetaReplacementValue,
  ViewerDevice,
} from 'constants/enums';
import {
  SectionWithSlugInterface,
  SignIntroBackgroundInterface,
  SignIntroContentInterface,
  SignIntroTitleInterface,
} from 'components/Sections/HoroscopesSignsSignIntro/declarations';
import { mapBlocksHeadingWithParameters } from 'lib/richTextMappers';
import { getHoroscopesTitleDate } from 'lib/date.service';
import { ImageWrapper } from 'src/__generated__/graphqlTypes';
import { setItemInObjectBySlug } from 'lib/sharedMethods.service';
import { HOROSCOPE_SIGN_NAME } from 'constants/constants';

import SignIntroPicture from './SignIntroPicture';

const getParsersConfig = (
  extraData: SectionExtraDataType,
  replacementValues?: Partial<Record<MetaReplacementValue, string>>,
): RichTextParsersConfig => {
  const titleClasses = cn(styles.signIntroTextTitle, styles[`signIntroTextTitle${capitalizeFirstLetter(extraData.contentDirection)}`]);

  return ({
    [BLOCKS.HEADING_1]: {
      classNames: titleClasses,
      mapper: mapBlocksHeadingWithParameters({ replacementValues }, Heading.FIRST),
    },
    [BLOCKS.HEADING_2]: {
      classNames: titleClasses,
      mapper: mapBlocksHeadingWithParameters({ replacementValues }),
    },
    [BLOCKS.PARAGRAPH]: {
      classNames: styles.signIntroTextParagraph,
    },
    [MARKS.BOLD]: {
      classNames: classes.textBold,
    },
  });
};

const imagesSlugs = {
  [ViewerDevice.DESKTOP]: ViewerDevice.DESKTOP,
  [ViewerDevice.MOBILE]: ViewerDevice.MOBILE,
};

/* Nested component */
const Background: FC<SignIntroBackgroundInterface> = ({ backgroundImage }) => {
  if (!backgroundImage?.image) {
    return null;
  }

  const { image } = backgroundImage;
  const width = Math.round(image.width!);
  const height = Math.round(image.height!);

  return (
    <WebpImage
      width={width}
      height={height}
      additionalProps={{ h: height, w: width }}
      image={image}
      className={styles.signIntroBackground}
    />
  );
};

/* Nested component */
const Content: FC<SignIntroContentInterface> = ({
  content,
  contentDirection,
  replacementValues,
}) => {
  if (!content) {
    return null;
  }

  return (
    <CommonRichText
      content={content}
      parsersConfig={getParsersConfig({ contentDirection }, replacementValues)}
    />
  );
};

/* Nested component */
const Title: FC<SignIntroTitleInterface> = ({
  richTitle,
  contentDirection,
  replacementValues,
  title,
  titleClassName,
}) => {
  if (richTitle) {
    return (
      <CommonRichText
        content={richTitle}
        parsersConfig={getParsersConfig({ contentDirection }, replacementValues)}
      />
    );
  }

  return (
    <h1
      className={cn(
        styles.signIntroTextTitle,
        styles[`${titleClassName}`],
      )}
    >
      <strong className={styles.textBold}>
        {title}
      </strong>
    </h1>
  );
};

const getCurrentTitleDate = (signName?: string | Array<string>) => {
  const now = new Date();

  if (!signName || Array.isArray(signName)) {
    return now;
  }

  if (signName?.includes('tomorrow')) {
    return new Date(now.setDate(now.getDate() + 1));
  }

  if (signName?.includes('yesterday')) {
    return new Date(now.setDate(now.getDate() - 1));
  }

  return now;
};

/* Main component */
const SignIntro: FC<SectionWithSlugInterface> = ({
  slug,
  blocks,
  bgColor,
  extraData,
  zodiacSign,
  viewerDevice,
  mobileViewMaxWidth,
}) => {
  const router = useRouter();
  const {
    verticalPadding = CommonSize.MEDIUM,
    contentDirection = FlexDirection.ROW,
  } = extraData || {};
  const [block] = blocks;

  const replacementValues = {
    [MetaReplacementValue.CURRENT_DATE]: getHoroscopesTitleDate(
      getCurrentTitleDate(router.query[HOROSCOPE_SIGN_NAME]),
      router.locale,
    ),
  };

  if (!block) {
    return null;
  }

  const commonClass = extraData?.page === 'zodiacSigns' ? 'zodiacSigns' : 'zodiac';
  const { image, content, title, richTitle, imagesCollection } = block;
  const reducer = setItemInObjectBySlug(imagesSlugs);
  const { mobile, desktop } = (imagesCollection?.items as Array<ImageWrapper>)
    ?.reduce(reducer, {} as Omit<Record<ViewerDevice, ImageWrapper>, ViewerDevice.TABLET>) || {};
  const backgroundImage = (viewerDevice === ViewerDevice.MOBILE) ? mobile : desktop;
  const isBackgroundPresented = Boolean(mobile || desktop);
  const isPaddingForWrapper = !(isBackgroundPresented
    && (contentDirection === FlexDirection.ROW
      || contentDirection === FlexDirection.ROW_REVERSE));

  return (
    <section
      style={{ background: bgColor }}
      className={cn(
        styles.wrapper,
        slug && styles[`wrapper${capitalizeFirstLetter(slug)}`],
        classes[`sharedWrapperPaddingVertical${capitalizeFirstLetter(verticalPadding)}`],
        styles[`${commonClass}Wrapper`],
      )}
    >
      <div
        className={cn(
          styles.signIntro,
          !isPaddingForWrapper && styles[`sharedWrapperPaddingVertical${capitalizeFirstLetter(CommonSize.SMALL)}`],
          styles[`signIntro${capitalizeFirstLetter(contentDirection)}`],
        )}
        key={title}
      >
        <div
          className={cn(
            styles.signIntroText,
            isBackgroundPresented && styles[`signIntroTextColor${capitalizeFirstLetter(zodiacSign)}`],
            styles[`signIntroText${capitalizeFirstLetter(contentDirection)}`],
          )}
        >
          <SignIntroPicture
            image={image}
            viewerDevice={viewerDevice}
            mobileViewMaxWidth={mobileViewMaxWidth}
            imageClassName={`${commonClass}Picture`}
          />
          <Title
            title={title}
            richTitle={richTitle}
            contentDirection={contentDirection}
            replacementValues={replacementValues}
            titleClassName={`${commonClass}Title`}
          />
          <Content
            content={content}
            contentDirection={contentDirection}
            replacementValues={replacementValues}
          />
        </div>
        <Background backgroundImage={backgroundImage} />
      </div>
    </section>
  );
};

export default SignIntro;
