import { FC } from 'react';
import {
  BLOCKS,
  MARKS,
  INLINES,
} from '@contentful/rich-text-types';
import cn from 'classnames';

import { CommonRichText, DataLink } from 'components/Shared/SharedComponents';
import classes from 'src/styles/_commonClasses.module.scss';
import { RichTextParsersConfig } from 'types/objectTypes';
import LocalLoader from 'src/shared/ui/LocalLoader/LocalLoader';
import { GAReplacementValue } from 'constants/enums';
import { Link } from 'src/__generated__/graphqlTypes';
import { mapHyperlinkWithGAData, mapBlocksParagraphWithoutTitle } from 'lib/richTextMappers';

import OfferHint from './Tooltip';

import styles from '../styles.module.scss';
import { useCustomerOffers } from '../../lib/hooks';
import { IOffersButtons } from '../../config/declarations';

const getGaEventNameString = (title: string) => {
  if (!title) return '';

  return title.toLowerCase().replace('promo', '');
};

const getParsersConfig = (isSingleOffer: boolean, link?: Link): RichTextParsersConfig => ({
  [BLOCKS.PARAGRAPH]: {
    classNames: cn(
      styles.contentDescription,
      {
        [styles.contentDescriptionSingleOffer]: isSingleOffer,
        [styles.contentDescriptionMultiOffer]: !isSingleOffer,
      },
    ),
    mapper: mapBlocksParagraphWithoutTitle(),
  },
  [MARKS.BOLD]: {
    classNames: classes.textBold,
  },
  [INLINES.HYPERLINK]: {
    classNames: styles.contentDescription,
    mapper: mapHyperlinkWithGAData(link, {
      [GAReplacementValue.NAME]: getGaEventNameString(link?.title!),
    }),
  },
});

const OffersButtons: FC<IOffersButtons> = ({ link, content, setIsSingleOffer }) => {
  const { isLoading, offers } = useCustomerOffers(setIsSingleOffer);
  const isSingleOffer = offers?.length === 1;

  if (isLoading) {
    return (
      <div className={styles.buttonsWrapper}>
        <LocalLoader
          className={styles.loader}
          withBackground={false}
        />
      </div>
    );
  }

  if (!offers?.length && link) {
    return (
      <div className={styles.buttonsWrapper}>
        <div className={styles.buttons}>
          <DataLink
            gaData={{ [GAReplacementValue.NAME]: getGaEventNameString(link.title!) }}
            className={cn(styles.button, styles.buttonSizeBig)}
            link={link}
          >
            <span>{link?.title}</span>
          </DataLink>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className={cn(styles.buttonsWrapper, {
        [styles.buttonsWrapperSingleOffer]: isSingleOffer,
      })}
      >
        <div className={styles.buttons}>
          {offers?.map((offer) => {
            const hintText = offer.longDescription || offer.description || offer.offerName || 'No description';
            const offerName = offer.description;

            return (
              <div key={offer.offerId} className={styles.buttonWrapper}>
                <DataLink
                  link={link!}
                  gaData={{ [GAReplacementValue.NAME]: offerName }}
                  className={cn(
                    styles.button,
                    {
                      [styles.buttonSizeFixed]: !isSingleOffer,
                      [styles.buttonSizeLong]: isSingleOffer,
                    },
                  )}
                >
                  <span>{offerName}</span>
                </DataLink>
                <OfferHint
                  id={offer.offerId}
                  description={hintText}
                />
              </div>
            );
          })}
        </div>
      </div>
      <CommonRichText
        content={content}
        parsersConfig={getParsersConfig(isSingleOffer, link!)}
      />
    </>
  );
};

export default OffersButtons;
