import { FC } from 'react';

import { PsychicComponentInterface } from 'types/componentTypes';
import { getPsychicCtaButtons } from 'lib/psychic.service';
import { PsychicCtaButtons } from 'entities/PsychicCtaButton';
import { HiatusBar } from 'entities/HiatusBar';

import PsychicDescription from './PsychicDescription';
import PsychicWorkDescription from './PsychicWorkDescription';
import styles from './PsychicCardList.module.scss';

const Card: FC<PsychicComponentInterface> = ({
  psychicSkeleton: psychicFrame,
  bootStatus,
  user,
  psychic,
  positionInArray,
}) => {
  const cta = getPsychicCtaButtons(psychic, psychicFrame, { hashedId: user?.hashedId });

  return (
    <li className={styles.card}>
      <PsychicDescription
        psychic={psychic}
        psychicFrame={psychicFrame}
        positionInArray={positionInArray}
      />
      <PsychicWorkDescription
        topics={psychic.specialities}
        abilities={psychic.skills as any}
        tools={psychic.tools}
        totalReadings={psychic.totalReadings}
        startYear={psychic.serviceStartYear}
        contentfulData={psychicFrame}
      />
      {!psychic.onHiatus && (
        <PsychicCtaButtons
          shape="Oval"
          bootStatus={bootStatus}
          className={styles.interaction}
          chatButton={cta.chatButton}
          talkButton={cta.talkButton}
          inQueueButton={cta.inQueueButton}
          callbackButton={cta.callbackButton}
          messageButton={cta.messageButton}
          psychicFrame={psychicFrame}
          psychic={psychic}
        />
      )}
      <HiatusBar
        psychic={psychic}
        contentfulData={psychicFrame}
        className={styles.hiatus}
      />
    </li>
  );
};

export default Card;
