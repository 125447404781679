import { FC, useState } from 'react';
import cn from 'classnames';
import { useSelector } from 'react-redux';

import LocalLoader from 'src/shared/ui/LocalLoader/LocalLoader';
import type { Store } from 'app-redux/types/storeTypes';
import type { SectionComponentInterface } from 'types/componentTypes';
import { PlainCalendar } from 'entities/PlainCalendar';
import type { Block, Link } from 'src/__generated__/graphqlTypes';

import styles from './AppointmentCalendar.module.scss';
import CalendarHeader from './CalendarHeader';
import ColorInfo from './ColorInfo';
import Appointment from './Appointment';
import NoSidebarContent from './NoSidebarContent';
import TopNavigation from './Navigation/TopNavigation';

import { getCalendarOnChange, parseBlocks } from '../lib';
import {
  useDatesHandlers,
  useHours,
  useNestedDispatch,
  useSelected,
  useTimeSlots,
} from '../lib/hooks';

const AppointmentCalendar: FC<SectionComponentInterface> = ({
  blocks,
  bgColor,
}) => {
  const isSidebar = useSelector((store: Store) => store.client.app.isSidebarActive);
  const [isCollapsed, setCollapsedState] = useState<boolean>(false);
  const { calendar, menu, modals } = parseBlocks(blocks);
  const navigationButton = calendar?.contentTypesCollection?.items
    ?.find?.((item) => item?.__typename === 'Link' && item.slug === 'navigation-button');
  const { data, isLoading } = useTimeSlots();
  const [selected, setSelected] = useSelected(data?.psychicSchedules);
  const hours = useHours(data);
  const { dispatch, setDispatch } = useNestedDispatch();
  const handlers = useDatesHandlers(hours);

  return (
    <section
      style={{ background: bgColor }}
      className={
        isSidebar
          ? styles.wrapperSide
          : styles.wrapper
      }
    >
      <NoSidebarContent
        title={calendar?.title}
        isSidebar={isSidebar}
        isCollapsed={isCollapsed}
        toggle={() => setCollapsedState((prev) => !prev)}
      />
      <div className={cn(
        styles.calendar,
        styles.widget,
        { [styles.hidden]: isCollapsed },
      )}
      >
        { isLoading && (
          <div className={styles.loader}>
            <LocalLoader isVisible={isLoading} />
          </div>
        )}
        <CalendarHeader
          block={calendar}
          selected={selected}
        />
        <TopNavigation
          dispatch={dispatch}
          date={selected.date}
          dateFormat="short"
          link={navigationButton as Link}
        />
        <PlainCalendar
          dateButton={navigationButton as Link}
          weekdays={menu?.textList}
          selected={selected.date}
          onChange={getCalendarOnChange(setSelected, data)}
          getState={({ dispatch }) => setDispatch(dispatch)}
          datesHandlers={handlers}
        />
        <ColorInfo list={calendar?.textList} />
      </div>
      { !isCollapsed
      && (
        <Appointment
          isCollapsed={isCollapsed}
          selected={selected}
          modals={modals?.contentTypesCollection?.items as Array<Block>}
          menu={menu}
          navigationButton={navigationButton as Link}
          button={calendar?.link}
          dispatch={dispatch}
        />
      )}
    </section>
  );
};

export default AppointmentCalendar;
