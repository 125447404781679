/* eslint-disable react/jsx-props-no-spreading */
import { useSelect } from 'downshift';
import {
  FC,
  useEffect,
  useState,
} from 'react';
import { BLOCKS } from '@contentful/rich-text-types';
import cn from 'classnames';

import { Tick } from 'src/shared/ui/Tick';
import { mapBlocksParagraphWithModifiableText } from 'lib/richTextMappers';
import { ViewerDevice } from 'constants/enums';
import type { RichTextParsersConfig } from 'types/objectTypes';
import { CommonRichText } from 'components/Shared/SharedComponents';
import type { ISortBySelect } from 'components/Sections/PsychicsSetMediumSizeImagesClone/declarations';

import styles from '../PsychicFilter.module.scss';

const getParsersConfig = (viewerDevice: ViewerDevice): RichTextParsersConfig => ({
  [BLOCKS.PARAGRAPH]: {
    classNames: styles.placeholder,
    mapper: mapBlocksParagraphWithModifiableText((text: string) => {
      if (viewerDevice !== ViewerDevice.MOBILE) {
        return text;
      }

      return text.split(' ')?.[0];
    }),
  },
});

const SortBySelect: FC<ISortBySelect> = ({
  options = [],
  sortTitle,
  viewerDevice,
  isLoading = false,
  onChange,
}) => {
  const [tickPosition, setTickPosition] = useState<'bottom' | 'top'>('bottom');
  const [isValueSelected, setIsValueSelected] = useState<boolean>(false);
  const {
    isOpen,
    selectedItem,
    getToggleButtonProps,
    getMenuProps,
    getItemProps,
  } = useSelect({
    items: options,
    onSelectedItemChange: ({ selectedItem }) => {
      if (selectedItem) {
        setIsValueSelected(true);
        onChange(selectedItem);
      }
    },
  });
  const tickSize = (viewerDevice === ViewerDevice.MOBILE) ? 8 : 10;
  useEffect(() => {
    if (isOpen) {
      setTickPosition('top');
    } else {
      setTickPosition('bottom');
    }
  }, [isOpen]);

  if (!options.length) {
    return null;
  }

  const placeholder = (selectedItem?.text)
    ? <strong>{selectedItem?.text}</strong>
    : (
      <CommonRichText
        content={sortTitle}
        parsersConfig={getParsersConfig(viewerDevice)}
      />
    );

  return (
    <div className={styles.drop}>
      <button
        className={cn(
          styles.button,
          styles.sortBySelected,
          {
            [styles.sortBySelectedUnderline]: isValueSelected,
          },
        )}
        type="button"
        disabled={isLoading}
        {...getToggleButtonProps()}
      >
        {placeholder}
        <Tick
          sideSize={tickSize}
          color="#1c573d"
          direction={tickPosition}
          className={cn(styles.buttonTick, {
            [styles.buttonTickTop]: tickPosition === 'top',
          })}
        />
      </button>
      <ul
        className={cn(
          styles.list,
          { [styles.hidden]: !isOpen },
        )}
        {...getMenuProps()}
      >
        {options.map((item, index) => {
          const isSelected = item.sys.id === selectedItem?.sys?.id;
          const classNamePart = isSelected ? 'Selected' : 'Common';

          return (
            <li
              key={item.sys.id}
              className={cn(
                styles.item,
                styles[`item${classNamePart}`],
              )}
              {...getItemProps({ item, index })}
            >
              <i className={cn(styles.circle, styles[`circle${classNamePart}`])} />
              <span>{item.text}</span>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default SortBySelect;
