import { FC } from 'react';
import cn from 'classnames';

import styles from './PsychicCardTile.module.scss';

import type { IControls, IControlsButton } from '../config/declarations';

/* Nested Component */
const Button: FC<IControlsButton> = ({ setCardStep, cardStep, step }) => (
  <input
    type="button"
    onClick={() => setCardStep(step)}
    className={cn(
      styles.button,
      { [styles.active]: cardStep === step },
    )}
  />
);

/* Main component */
const Controls: FC<IControls> = ({ setCardStep, cardStep }) => (
  <div className={styles.controls}>
    <Button
      step="first"
      cardStep={cardStep}
      setCardStep={setCardStep}
    />
    <Button
      step="second"
      cardStep={cardStep}
      setCardStep={setCardStep}
    />
  </div>
);

export default Controls;
