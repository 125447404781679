/* eslint-disable prefer-const */
/* eslint-disable eqeqeq */
import {
  useEffect,
  useRef,
  Dispatch,
  SetStateAction,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import hash from 'object-hash';
import {
  DocumentData,
  Firestore,
  FirestoreError,
  Query,
  QueryDocumentSnapshot,
  collection,
  getDocs,
  onSnapshot,
  query,
  where,
  documentId,
} from 'firebase/firestore';

import { setFirebaseLoadedState } from 'app-redux/actions/appActions';
import { Store } from 'app-redux/types/storeTypes';
import { FirebaseDocId, RightPsychic } from 'types/objectTypes';
import { FirestoreChangeType, Status } from 'src/constants/enums';
import { Logger } from 'lib/logger';
import { getStatus } from 'lib/psychic.service';
import { getModuleNotLoadedErrorHandler } from 'src/shared/lib/modules';

const START_OF_NUMERIC_ID_PART = 3;

const getElementId = (
  doc: QueryDocumentSnapshot<DocumentData>,
) => {
  const docId = doc.id as FirebaseDocId;

  return +docId.substring(START_OF_NUMERIC_ID_PART);
};

const unsubscribePreviousButchEvents = (unsubscribeFunctions: Array<Function>) => {
  if (unsubscribeFunctions.length) {
    unsubscribeFunctions.forEach((func) => func());
    unsubscribeFunctions.splice(0, unsubscribeFunctions.length - 1);
  }
};

const useUnsubscribePreviousEvents = (unsubscribeFunctions: Array<Function>) => {
  useEffect(
    () => () => unsubscribePreviousButchEvents(unsubscribeFunctions),
    [unsubscribeFunctions],
  );
};

const applyNewDataIfNeeded = (
  condition: boolean,
  psychic: RightPsychic,
  data: any,
) => {
  if (condition) {
    const newPsychic = psychic;
    newPsychic.phoneStatus = data.phoneStatus;
    newPsychic.chatStatus = data.chatStatus;
    newPsychic.lineStatus = getStatus({
      chatStatus: data.chatStatus,
      phoneStatus: data.phoneStatus,
    });
    newPsychic.messageStatus = data.messageStatus === 'enabled'
      ? Status.AVAILABLE
      : Status.OFFLINE;
    newPsychic.isDirectMessageEnabled ??= data.isMessageEnabled;
    newPsychic.isChatEnabled ??= data.isChatEnabled ?? data.chatStatus === Status.AVAILABLE;
    newPsychic.isPhoneEnabled ??= data.isPhoneEnabled ?? data.phoneStatus === Status.AVAILABLE;
    newPsychic.phoneQueue = data.PhoneQueueOrder;

    if (data.estimatedWaitTime) {
      newPsychic.estimatedWaitTime = data.estimatedWaitTime;
    }

    if (data.onBreakMinutes) {
      newPsychic.onBreakMinutes = data.onBreakMinutes;
    }

    return newPsychic;
  }

  return psychic;
};

let firestoreInstance: Firestore;

const setUpFirebaseIfRequired = async () => {
  if (!firestoreInstance) {
    try {
      const { default: getFirestoreInstance } = await import('src/firebase/firestore');
      firestoreInstance = getFirestoreInstance();
    } catch (e) {
      Logger.warn(e);
      try {
        const { default: getFirestoreInstance } = await import('src/firebase/firestore');
        firestoreInstance = getFirestoreInstance();
      } catch (e) {
        const errorHandler = getModuleNotLoadedErrorHandler(
          'Modules connected with statuses updates weren\'t loaded, do you want to reload the page to load them again?',
          'Firestore config wasn\'t loaded and user rejected page refresh',
        );
        errorHandler(e);
      }
    }
  }
};

const updateModifiedPsychics = (
  setPsychics: Dispatch<SetStateAction<RightPsychic[]>>,
  doc: QueryDocumentSnapshot<DocumentData>,
) => setPsychics((prevPsychics) => {
  const newPsychics = [...prevPsychics];
  const newPsychicData = doc.data();
  const resourceId = getElementId(doc);

  const array = newPsychics.map((psychic: RightPsychic) => {
    const isIdsEqualsIgnoreType = psychic.extId == resourceId;

    return applyNewDataIfNeeded(isIdsEqualsIgnoreType, psychic, newPsychicData);
  });

  return array;
});

const getPsychicDataFromDocs = (
  docs: Array<QueryDocumentSnapshot<DocumentData>>,
  extraHandler?: (item: DocumentData) => boolean,
) => docs.reduce((store, item) => {
  if (item.exists()) {
    const data = item.data();
    const resourceId = getElementId(item);

    if (!extraHandler) {
      // eslint-disable-next-line no-param-reassign
      store[resourceId] = data;

      return store;
    }

    const shouldBeUpdated = extraHandler!(data);

    if (shouldBeUpdated) {
      // eslint-disable-next-line no-param-reassign
      store[resourceId] = data;
    }
  }

  return store;
}, {} as Record<string, any>);

const getPsychicsMapper = (psychicsData: Record<string, any>) => (
  psychics: Array<RightPsychic>,
  psychic: RightPsychic,
) => {
  const appropriateElement = psychicsData[psychic.extId];

  if (appropriateElement) {
    psychics.push(applyNewDataIfNeeded(true, psychic, appropriateElement));
  }

  return psychics;
};

const setUpFirestoreListener = (
  psychicCollections: Array<Query<DocumentData>>,
  unsubscribeFunctions: Array<Function>,
  setPsychics: Dispatch<SetStateAction<RightPsychic[]>>,
  setPsychicsLoadedState?: () => void,
): void => {
  let psychicData = {} as Record<string, any>;
  psychicCollections.forEach((collection, i) => {
    const unsubscribe = onSnapshot(collection, (snapshot) => {
      /** Set initial data if exists */
      if (setPsychicsLoadedState) {
        psychicData = { ...psychicData, ...getPsychicDataFromDocs(snapshot.docs) };

        if (i + 1 === psychicCollections.length && Object.keys(psychicData).length) {
          setPsychics((prevPsychics) => prevPsychics.map((psychic) => {
            const appropriateElement = psychicData[psychic.extId];

            return applyNewDataIfNeeded(!!appropriateElement, psychic, appropriateElement);
          }));

          setPsychicsLoadedState();
        }
      }

      snapshot.docChanges().forEach((change) => {
        const { doc, type } = change;

        if (type === FirestoreChangeType.MODIFIED) {
          updateModifiedPsychics(setPsychics, doc);
        }
      });
    },
    (error: FirestoreError) => {
      Logger.error(error);
    });

    unsubscribeFunctions.push(unsubscribe);
  });
};

/** Now this method is deprecated because the rest of the hooks don't use
 * loadInitialData that way that it's implemented here
 */
export const useAvailablePsychics = (
  setPsychics: Dispatch<SetStateAction<RightPsychic[]>>,
) => {
  const isFirebaseLoaded = useSelector((store: Store) => store.client.app.isFirebaseLoaded);
  const isFirebaseRequired = useSelector((store: Store) => store.client.app.isFirebaseRequired);
  const wasPsychicsLoadedRef = useRef<boolean>(false);
  const { current: unsubscribeFunctions } = useRef<Array<Function>>([]);

  const loadInitialData = async (
    psychicCollection: Array<Query<DocumentData>>,
  ) => {
    try {
      const psychicsData = (await Promise
        .all(psychicCollection.map((collection) => getDocs(collection))))
        .reduce((store, { docs }) => {
          const extraHandler = (item: DocumentData) => {
            const { phoneStatus, chatStatus } = item;

            const isBusyOrPending = phoneStatus === Status.BUSY || phoneStatus === Status.PENDING
              || chatStatus === Status.BUSY || chatStatus === Status.PENDING;

            if (isBusyOrPending) {
              return false;
            }

            return true;
          };
          const items = getPsychicDataFromDocs(docs, extraHandler);

          return { ...store, ...items };
        }, {} as Record<string, any>);

      setPsychics((prevPsychics) => prevPsychics
        ?.reduce(getPsychicsMapper(psychicsData!), [] as Array<RightPsychic>));
      wasPsychicsLoadedRef.current = true;
    } catch (e) {
      Logger.error(e);
    }
  };

  useEffect(() => {
    if (!isFirebaseRequired || !isFirebaseLoaded) {
      return;
    }

    (async () => {
      await setUpFirebaseIfRequired();
      const advisorCollection = query(collection(firestoreInstance, ('advisors')));
      const availablePhoneCollection = query(advisorCollection, where('phoneStatus', '==', Status.AVAILABLE));
      const availableChatCollection = query(advisorCollection, where('chatStatus', '==', Status.AVAILABLE));
      const psychicCollection = (await Promise
        .all([availablePhoneCollection, availableChatCollection])).flat();
      const wasPsychicsLoaded = wasPsychicsLoadedRef.current;

      if (!wasPsychicsLoaded) {
        await loadInitialData(psychicCollection);
        setUpFirestoreListener(
          psychicCollection,
          unsubscribeFunctions,
          setPsychics,
        );
        wasPsychicsLoadedRef.current = true;
      }
    })();
  },
  [
    isFirebaseLoaded,
    isFirebaseRequired,
    wasPsychicsLoadedRef.current,
  ]);

  useUnsubscribePreviousEvents(unsubscribeFunctions);
};

export const usePsychicStatuses = (
  idList: Array<number>,
  setPsychics: Dispatch<SetStateAction<RightPsychic[]>>,
  shouldBeUsed: boolean = true,
) => {
  const isFirebaseLoaded = useSelector((store: Store) => store.client.app.isFirebaseLoaded);
  const isFirebaseRequired = useSelector((store: Store) => store.client.app.isFirebaseRequired);
  const wasPsychicsLoadedRef = useRef<boolean>(false);
  const { current: unsubscribeFunctions } = useRef<Array<Function>>([]);
  const setPsychicsLoadedState = () => { wasPsychicsLoadedRef.current = true; };

  useEffect(() => {
    const isFirebaseNotReady = !isFirebaseRequired || !isFirebaseLoaded;
    const isListEmpty = !idList || idList.length === 0;

    if (isListEmpty || isFirebaseNotReady || !shouldBeUsed) {
      return;
    }

    (async () => {
      await setUpFirebaseIfRequired();
      const batches: Array<Query<DocumentData>> = [];
      const advisorCollection = query(collection(firestoreInstance, ('advisors')));

      while (idList.length) {
        const batch = idList.splice(0, 10).map((id) => {
          if (id.toString().startsWith('cp')) {
            return id;
          }

          return `cp-${id}`;
        });
        batches.push(query(advisorCollection, where(documentId(), 'in', batch)));
      }

      const psychicCollection = (await Promise.all(batches)).flat();
      const wasPsychicsLoaded = wasPsychicsLoadedRef.current;

      if (!wasPsychicsLoaded) {
        setUpFirestoreListener(
          psychicCollection,
          unsubscribeFunctions,
          setPsychics,
          setPsychicsLoadedState,
        );
      }
    })();
  },
  [
    idList,
    isFirebaseLoaded,
    isFirebaseRequired,
    wasPsychicsLoadedRef.current,
    shouldBeUsed,
  ]);

  useUnsubscribePreviousEvents(unsubscribeFunctions);
};

export const usePsychicStatusesWithUnsubscribe = (
  idList: Array<number>,
  setPsychics: Dispatch<SetStateAction<RightPsychic[]>>,
  shouldBeUsed: boolean = true,
) => {
  const isFirebaseLoaded = useSelector((store: Store) => store.client.app.isFirebaseLoaded);
  const isFirebaseRequired = useSelector((store: Store) => store.client.app.isFirebaseRequired);
  const wasPsychicsLoadedRef = useRef<boolean>(false);
  const { current: unsubscribeFunctions } = useRef<Array<Function>>([]);
  const prevHashRef = useRef<string>('');

  const setPsychicsLoadedState = () => { wasPsychicsLoadedRef.current = true; };

  useEffect(() => {
    const isFirebaseNotReady = !isFirebaseRequired || !isFirebaseLoaded;
    const isListEmpty = !idList || idList.length === 0;

    if (isListEmpty || isFirebaseNotReady || !shouldBeUsed) {
      return;
    }

    const currentHash = hash.MD5(idList);

    if (currentHash === prevHashRef.current) {
      return;
    }

    (async () => {
      await setUpFirebaseIfRequired();
      const batches: Array<Query<DocumentData>> = [];
      const advisorCollection = query(collection(firestoreInstance, ('advisors')));
      const idListClone = [...idList];

      while (idListClone.length) {
        const batch = idListClone.splice(0, 10).map((id) => {
          if (id.toString().startsWith('cp')) {
            return id;
          }

          return `cp-${id}`;
        });
        batches.push(query(advisorCollection, where(documentId(), 'in', batch)));
      }

      const psychicCollection = (await Promise.all(batches)).flat();
      unsubscribePreviousButchEvents(unsubscribeFunctions);
      prevHashRef.current = currentHash;

      setUpFirestoreListener(
        psychicCollection,
        unsubscribeFunctions,
        setPsychics,
        setPsychicsLoadedState,
      );
    })();
  },
  [
    idList,
    isFirebaseLoaded,
    isFirebaseRequired,
    wasPsychicsLoadedRef.current,
    shouldBeUsed,
  ]);

  useUnsubscribePreviousEvents(unsubscribeFunctions);
};

export const useFirebaseLazyLoading = () => {
  const isFirebaseLoaded = useSelector((store: Store) => store.client.app.isFirebaseLoaded);
  const isFirebaseRequired = useSelector((store: Store) => store.client.app.isFirebaseRequired);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isFirebaseRequired && !isFirebaseLoaded) {
      import('firebase/app')
        .then(() => dispatch(setFirebaseLoadedState(true)))
        .catch((e) => {
          Logger.warn(e);

          return import('firebase/app')
            .then(() => dispatch(setFirebaseLoadedState(true)))
            .catch(getModuleNotLoadedErrorHandler(
              'Modules connected with statuses updates weren\'t loaded, do you want to reload the page to load them again?',
              'Firebase modules weren\'t loaded and user rejected page refresh',
            ));
        });
    }
  }, [isFirebaseRequired, isFirebaseLoaded, dispatch]);
};
