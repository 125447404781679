import { FC } from 'react';
import { INLINES, MARKS } from '@contentful/rich-text-types';
import { Tooltip } from 'react-tooltip';
import cn from 'classnames';

import { CommonRichText } from 'components/Shared/SharedComponents';
import { RichTextParsersConfig } from 'types/objectTypes';
import classes from 'styles/_commonClasses.module.scss';

import styles from './styles.module.scss';

import { HintInterface } from '../config/declarations';

const getParsersConfig = (): RichTextParsersConfig => ({
  [INLINES.HYPERLINK]: {
    classNames: styles.hintLink,
  },
  [MARKS.BOLD]: {
    classNames: classes.textBold,
  },
});

export const Hint: FC<HintInterface> = ({
  isAddToFavoriteHintActive,
  hint,
  className,
  tooltipId,
}) => {
  if (!isAddToFavoriteHintActive || !hint || (!hint.text && !hint.richText)) {
    return null;
  }

  const hintComponent = hint.richText
    ? (
      <CommonRichText
        content={hint.richText!}
        parsersConfig={getParsersConfig()}
      />
    )
    : hint.text;

  return (
    <Tooltip
      delayShow={100}
      delayHide={100}
      openOnClick
      clickable
      isOpen={isAddToFavoriteHintActive}
      className={cn(styles.hint, className)}
      id={tooltipId}
    >
      {hintComponent}
    </Tooltip>
  );
};
