import { FC } from 'react';
import { useSelector } from 'react-redux';

import { Store } from 'app-redux/types/storeTypes';
import { SectionComponentInterface } from 'types/componentTypes';
import { getZodiacSign } from 'lib/sharedMethods.service';
import { SignIntro } from 'components/Sections/HoroscopesSignsSignIntro';

const SignIntroContainer: FC<SectionComponentInterface> = ({
  blocks,
  extraData,
  bgColor,
}) => {
  const slug = useSelector((store: Store) => store.server.page.slug);
  const { mobileViewMaxWidth } = useSelector((store: Store) => store.server.app.scssVariables);
  const viewerDevice = useSelector((store: Store) => store.server.app.viewerDevice);
  const zodiacSign = getZodiacSign(slug);

  return (
    <SignIntro
      slug={slug}
      blocks={blocks}
      bgColor={bgColor}
      extraData={extraData}
      zodiacSign={zodiacSign}
      viewerDevice={viewerDevice}
      mobileViewMaxWidth={mobileViewMaxWidth}
    />
  );
};

export default SignIntroContainer;
