import { FC } from 'react';
import cn from 'classnames';

import { ImageFitMode, ViewerDevice } from 'constants/enums';
import { WebpWidthLimitType } from 'types/objectTypes';
import { WebpImage } from 'components/Shared/SharedComponents';
import styles from 'components/Sections/HoroscopesSignsSignIntro/SignIntro.module.scss';
import { SignIntroPictureInterface } from 'components/Sections/HoroscopesSignsSignIntro/declarations';

const RATIO = 0.7;

const SignIntroPicture: FC<SignIntroPictureInterface> = ({
  image,
  viewerDevice,
  mobileViewMaxWidth,
  imageClassName,
}) => {
  if (!image?.url) {
    return null;
  }

  const width = Math.round(image?.width!);
  const height = Math.round(image?.height!);
  const mobileWidth = Math.round(width * RATIO);
  const mobileHeight = Math.round(height * RATIO);
  const imageWidthLimit: Array<WebpWidthLimitType> = [{
    width: mobileWidth,
    height: mobileHeight,
    media: `${mobileViewMaxWidth}px`,
    fit: ImageFitMode.SCALE,
  }];

  return (
    <WebpImage
      height={(viewerDevice === ViewerDevice.MOBILE) ? mobileHeight : height}
      width={(viewerDevice === ViewerDevice.MOBILE) ? mobileWidth : width}
      image={image}
      pictureClassName={cn(
        styles.signIntroPicture,
        styles[`${imageClassName}`],
      )}
      widthLimit={imageWidthLimit}
    />
  );
};

export default SignIntroPicture;
