import { FC } from 'react';

import { PsychicStatusIndicator } from 'entities/PsychicStatus';
import { getStatus } from 'lib/psychic.service';
import classes from 'src/styles/_commonClasses.module.scss';
import { capitalizeFirstLetter } from 'lib/text.service';

import { StatusIndicatorWithTextInterface } from '../config/declarations';

const StatusIndicatorWithText: FC<StatusIndicatorWithTextInterface> = ({
  chatStatus,
  phoneStatus,
  className,
  wrapperClass,
}) => {
  const status = getStatus({ chatStatus, phoneStatus });

  return (
    <div className={wrapperClass}>
      <PsychicStatusIndicator
        status={status}
        className={className}
      />
      <span
        className={classes[`statusText${capitalizeFirstLetter(status)}`]}
      >
        {capitalizeFirstLetter(status)}
      </span>
    </div>
  );
};

export default StatusIndicatorWithText;
