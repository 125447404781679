import { FC } from 'react';
import { useSelector } from 'react-redux';
import cn from 'classnames';

import type { ImageWrapper } from 'src/__generated__/graphqlTypes';
import type { Store } from 'app-redux/types/storeTypes';
import { WebpImage } from 'components/Shared/SharedComponents';
import {
  ElementAlign,
  ImageFitMode,
  ViewerDevice,
} from 'constants/enums';
import { setItemInObjectBySlug } from 'lib/sharedMethods.service';
import { getImagesWithLimitedSize } from 'lib/image.service';
import { WebpWidthLimitType } from 'types/objectTypes';

import styles from './TextAndSideAsset.module.scss';

import { useImageStyles } from '../lib/hooks';
import type { IImage } from '../config/declarations';
import { getCurrentImage } from '../lib';
import { imageSlug } from '../config/objects';

const DESKTOP_TABLET_IMAGE_WIDTH = 500;
const MOBILE_IMAGE_WIDTH = 330;

const Image: FC<IImage> = ({
  images,
  image: oldImage,
  extraData,
  viewerDevice,
  ...rest
}) => {
  const { mobileViewMaxWidth } = useSelector((store: Store) => store.server.app.scssVariables);
  const imageClasses = useImageStyles({
    extraData,
    isImages: (images?.length || 0) > 0,
    viewerDevice,
  });

  /* Supports old functionality, can be removed lately */
  if (!images?.length && oldImage) {
    const { assetAlign = ElementAlign.LEFT } = extraData || {};
    const mobileHeight = Math.round((oldImage.height! / oldImage.width!) * MOBILE_IMAGE_WIDTH);
    const widthLimit: Array<WebpWidthLimitType> = oldImage
      ? getImagesWithLimitedSize([{
        width: MOBILE_IMAGE_WIDTH,
        height: mobileHeight,
        media: `${mobileViewMaxWidth}px`,
      },
      {
        width: DESKTOP_TABLET_IMAGE_WIDTH,
        isMinWidth: true,
        media: `${mobileViewMaxWidth}px`,
      },
      ], { fit: ImageFitMode.SCALE })
      : [];

    return (
      <WebpImage
        image={oldImage}
        widthLimit={widthLimit}
        className={cn(styles[`${assetAlign}Asset`], styles[`${assetAlign}AssetImage`])}
      />
    );
  }

  if (!images?.length) {
    return null;
  }

  const reducer = setItemInObjectBySlug(imageSlug);
  const sortedImages = images.reduce(reducer, {} as Record<ViewerDevice, ImageWrapper>);
  const currentImage = getCurrentImage(sortedImages, viewerDevice);
  const { image } = currentImage || {};

  if (!image) {
    return null;
  }

  return (
    <WebpImage
      image={image}
      className={imageClasses.img}
      pictureClassName={imageClasses.pic}
      {...rest}
    />
  );
};

export default Image;
