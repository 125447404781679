/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { FC } from 'react';
import cn from 'classnames';
import { useSelector } from 'react-redux';

import type { Store } from 'app-redux/types/storeTypes';
import { PsychicSimpleViewButtons as Buttons } from 'entities/PsychicSimpleViewButtons';
import { getPsychicCtaButtons } from 'lib/psychic.service';
import { PsychicCardImage } from 'entities/PsychicCardImage';
import { PsychicAvatarWrapperLink } from 'entities/PsychicAvatarWrapperLink';
import { PsychicNameWithIcon, StatusIndicatorWithText } from 'entities/JsxParserComponents';
import { HiatusBar } from 'entities/HiatusBar';

import styles from './styles.module.scss';

import type { CardInterface } from '../config/declarations';

const Card: FC<CardInterface> = ({
  psychicSkeleton,
  positionInArray,
  psychic,
  bootStatus,
}) => {
  const user = useSelector((store: Store) => store.server.auth.user);
  const { profileButton, avatar } = psychicSkeleton;
  const avatarUrl = avatar?.url || '';
  const psychicImageUrl = psychic.psychicImageUrl || avatarUrl || '';

  const cta = getPsychicCtaButtons(psychic, psychicSkeleton, { hashedId: user?.hashedId });
  const labelIcon = psychicSkeleton?.labelsCollection?.items
    ?.find((item) => item?.slug === 'verification-icon');

  return (
    <li className={styles.card}>
      <div className={styles.interaction}>
        <div>
          <PsychicNameWithIcon
            psychicName={psychic.psychicName}
            image={labelIcon?.image!}
            wrapperClass={cn(styles.psychicName, styles.name)}
            className={styles.psychicIcon}
          />
          <StatusIndicatorWithText
            chatStatus={psychic.chatStatus}
            phoneStatus={psychic.phoneStatus}
            wrapperClass={styles.status}
          />
        </div>
        { !psychic.onHiatus && (
          <Buttons
            className={styles.buttons}
            bootStatus={bootStatus}
            chatButton={cta.chatButton}
            talkButton={cta.talkButton}
            callbackButton={cta.callbackButton}
            inQueueButton={cta.inQueueButton}
            messageButton={cta.messageButton}
            psychicFrame={psychicSkeleton}
            psychic={psychic}
            shape="Oval"
          />
        )}
        <HiatusBar
          psychic={psychic}
          contentfulData={psychicSkeleton}
          className={styles.hiatus}
        />
      </div>
      <PsychicAvatarWrapperLink
        className={styles.link}
        positionInArray={positionInArray}
        profileButton={profileButton}
        psychic={psychic}
      >
        <PsychicCardImage
          psychic={psychicSkeleton}
          image="external"
          src={psychicImageUrl}
          alt={psychic.psychicName}
          className={styles.image}
        />
      </PsychicAvatarWrapperLink>
    </li>

  );
};

export default Card;
