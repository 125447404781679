import {
  Block,
  ImageWrapper,
  Link,
} from 'src/__generated__/graphqlTypes';
import { ViewerDevice } from 'constants/enums';
import { ImageAttachmentPlace } from 'types/objectTypes';
import { BUTTON } from 'constants/constants';

export const getCurrentImage = (
  sortedImages: Record<ViewerDevice, ImageWrapper>,
  viewerDevice: ViewerDevice,
) => {
  if (viewerDevice === ViewerDevice.MOBILE) {
    return sortedImages[viewerDevice] || sortedImages[ViewerDevice.TABLET];
  }

  if (viewerDevice === ViewerDevice.TABLET) {
    return sortedImages[viewerDevice] || sortedImages[ViewerDevice.MOBILE];
  }

  return sortedImages[viewerDevice]
    || sortedImages[ViewerDevice.TABLET]
    || sortedImages[ViewerDevice.MOBILE];
};

export const searchImagePlaceCallback = (place: ImageAttachmentPlace) => place === 'top' || place === 'bottom';

export const getExtraComponents = (blocks: Array<Link | Block>) => blocks
  .reduce((store, item) => {
    if (item.__typename === 'Link' && item.slug === BUTTON) {
      store.extraButtons.push(item);
    } else {
      store.extraBlocks.push(item as Block);
    }

    return store;
  }, { extraBlocks: [] as Array<Block>, extraButtons: [] as Array<Link> });
