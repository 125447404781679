import { FC } from 'react';
import cn from 'classnames';

import styles from 'components/Sections/RelatedBlogPosts/RelatedBlogPosts.module.scss';
import { DataLink, WebpImage } from 'components/Shared/SharedComponents';

import { RelatedBlogPostApiInterface } from './declarations';

const RelatedBlogPostApi: FC<RelatedBlogPostApiInterface> = ({
  content,
  link,
  image,
  entryName,
  richTitle,
  readLink,
}) => {
  if (!richTitle) {
    return null;
  }

  const htmlString = `${content}</div>`;
  const plainString = htmlString.replace(/<[^>]+>/g, '');

  return (
    <article className={styles.blogCard}>
      <section className={styles.blogCardBody}>
        <a
          href={link}
          className={cn(styles.blogCardBodyButton, styles.blogCardBodyButtonImage)}
        >
          {image && (
            <WebpImage
              image="external"
              src={image}
              className={styles.blogCardBodyButtonImage}
            />
          )}
        </a>
        <header className={styles.blogCardHeader}>
          <h3 className={styles.blogCardHeaderTitle}>{richTitle}</h3>
        </header>
        <p className={styles.blogCardBodyParagraph}>
          { plainString }
        </p>
        <DataLink
          href={link!}
          link={readLink}
          key={entryName}
          className={cn(styles.blogCardBodyButton, styles.blogCardBodyButtonText)}
        >
          {readLink && readLink.title?.trim()}
        </DataLink>
      </section>
    </article>
  );
};

export default RelatedBlogPostApi;
