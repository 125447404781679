import { FC } from 'react';

import classes from 'src/styles/_commonClasses.module.scss';
import { useCustomRouter } from 'src/shared/lib/history/hooks';

import styles from './AppointmentCalendar.module.scss';

import { nth, parseTextItems } from '../lib';
import type { ICalendarHeader } from '../config/declarations';

const CalendarHeader: FC<ICalendarHeader> = ({
  selected,
  block,
}) => {
  const router = useCustomRouter();
  const { date, callbacks } = parseTextItems(block?.contentTypesCollection?.items as any);
  const selectedDate = selected.date;
  let availabilityStatus = callbacks?.text;

  const showHours = () => {
    if (!selected?.forCalls?.length) {
      availabilityStatus = callbacks?.fullText;

      return null;
    }

    const options: Intl.DateTimeFormatOptions = { hour: 'numeric', hour12: true, minute: 'numeric' };
    const locale = router.locale || 'en-US';

    return (
      <div className={styles.headerAppointmentWrapper}>
        <strong className={classes.textBold700}>{`${date?.fullText}:`}</strong>
        <span className={styles.headerAppointment}>
          {selected?.forCalls.map(({ start, end }) => (
            <p key={start.getTime()}>
              {`${start.toLocaleString(locale, options)} - ${end.toLocaleString(locale, options)}`}
            </p>
          ))}
        </span>
      </div>
    );
  };

  return (
    <div className={styles.header}>
      <p>
        <strong className={classes.textBold700}>{date?.text}</strong>
        :
        {' '}
        {selectedDate.toLocaleDateString(router.locale, { weekday: 'long' })}
        ,
        {' '}
        {selectedDate.toLocaleDateString(router.locale, { month: 'long' })}
        {' '}
        {selectedDate.toLocaleDateString(router.locale, { day: 'numeric' })}
        {nth(selectedDate.getDate())}
        ,
        {' '}
        {selectedDate.toLocaleDateString(router.locale, { year: 'numeric' })}
      </p>
      {showHours()}
      <span className={styles.callbacks}>{availabilityStatus}</span>
    </div>
  );
};

export default CalendarHeader;
