import { FC, Fragment } from 'react';
import cn from 'classnames';

import { DataButton, DataLink } from 'components/Shared/SharedComponents';
import styles from 'components/Footer/Copyright/Copyright.module.scss';
import classes from 'styles/_commonClasses.module.scss';
import {
  FooterCopyrightInterface,
  CopyrightSlug as Slug,
  CopyrightLinksInterface,
  CopyrightLinkInterface,
} from 'components/Footer/Copyright/declarations';
import { capitalizeFirstLetter } from 'lib/text.service';
import { FooterType } from 'constants/enums';

/* Nested component */
const CopyrightLink: FC<CopyrightLinkInterface> = ({ link }) => {
  if (link.slug === Slug.PERSONAL_INFO) {
    const openInNewWindow = () => {
      const width = 730;
      const height = 610;
      const left = ((window.innerWidth - width) / 2) + window.screenX;
      const top = ((window.innerHeight - height) / 2) + window.screenY;
      window.open(link.src || '/',
        'chromeWindow',
        `width=${width
        },height=${height
        },top=${top
        },left=${left}`);
    };

    return (
      <DataButton
        link={link}
        onClick={openInNewWindow}
        className={cn(styles.contentLinksButton, classes.textBold)}
      >
        {link.title}
      </DataButton>
    );
  }

  const isShouldOpenInNewTab = link.src?.includes('#') && (link.src as string).startsWith('/');
  const target = isShouldOpenInNewTab ? '_blank' : undefined;

  return (
    <DataLink
      href={link.src!}
      link={link}
      className={cn(styles.contentLinksLink, classes.textBold)}
      target={target}
    >
      {link.title?.trim()}
    </DataLink>
  );
};

/* Nested component */
const CopyrightLinks: FC<CopyrightLinksInterface> = ({ copyrightLinks }) => {
  const length = copyrightLinks?.length;

  if (length > 0) {
    return (
      <>
        {copyrightLinks.map((link, i) => (
          <Fragment key={link.entryName}>
            <CopyrightLink link={link} />
            {i + 1 !== length && <span className={styles.contentLinksDivider} />}
          </Fragment>
        ))}
      </>
    );
  }

  return null;
};

/* Main component */
const Copyright: FC<FooterCopyrightInterface> = ({
  copyrightDescription,
  copyrightLinks,
  footerType,
  isMobileView,
  desktopSiteRequest,
  mobileSiteRequest,
  isDesktopRequested,
  requestAnotherSiteView,
}) => {
  const switchViewButtonText = (isDesktopRequested)
    ? mobileSiteRequest
    : desktopSiteRequest;
  const isVisibleSwitchViewButton = process.env.NEXT_PUBLIC_KEEP_REQUEST_DESKTOP === 'true' && (isMobileView !== isDesktopRequested)
  && (footerType !== FooterType.LIGHT);

  return (
    <div className={cn(styles.copyright, styles[`copyright${capitalizeFirstLetter(footerType)}`])}>
      {isVisibleSwitchViewButton && (
        <button
          type="button"
          className={styles.button}
          onClick={requestAnotherSiteView}
          onKeyPress={requestAnotherSiteView}
        >
          {switchViewButtonText}
        </button>
      )}
      <div className={styles.content}>
        {
          isMobileView ? (
            <>
              <div className={styles.contentLinks}>
                <CopyrightLinks copyrightLinks={copyrightLinks} />
              </div>
              <span className={styles.contentDescription}>
                {copyrightDescription}
              </span>
            </>
          ) : (
            <>
              <span className={styles.contentDescription}>
                {copyrightDescription}
              </span>
              <div className={styles.contentLinks}>
                <CopyrightLinks copyrightLinks={copyrightLinks} />
              </div>
            </>
          )
        }
      </div>
    </div>
  );
};

export default Copyright;
