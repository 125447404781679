import {
  FC,
  memo,
  CSSProperties,
} from 'react';
import { BLOCKS, MARKS } from '@contentful/rich-text-types';
import cn from 'classnames';
import { useSelector } from 'react-redux';

import type { Block, Psychic } from 'src/__generated__/graphqlTypes';
import type { Store } from 'app-redux/types/storeTypes';
import {
  RichTextParsersConfig,
  RightPsychic,
  SectionExtraDataType,
} from 'types/objectTypes';
import styles from 'components/Sections/ExponeaPsychicsList/ExponeaPsychicsList.module.scss';
import classes from 'src/styles/_commonClasses.module.scss';
import { CommonRichText, DataLink } from 'components/Shared/SharedComponents';
import {
  CommonSize,
  ElementAlign,
  ItemsLayout,
  PsychicCardAppearance,
} from 'constants/enums';
import { capitalizeFirstLetter } from 'lib/text.service';
import Divider from 'components/Sections/Divider';
import { getItemPositionForGa } from 'lib/sharedMethods.service';
import {
  ExponeaPsychicsListInterface,
  ExponeaPsychicsListPsychicsInterface as PsychicsInterface,
  ExponeaLoadableItems,
} from 'components/Sections/ExponeaPsychicsList/declarations';
import { Loader } from 'components/Loader';
import { CommonRichTextInterface } from 'components/Shared/declarations';
import { PsychicCardSimple } from 'features/sections/PsychicCardSimple';
import { PsychicCardList } from 'features/sections/PsychicCardList';
import { PsychicCardTile } from 'features/sections/PsychicCardTile';

const getParsersConfig = ({ titleAlign, pAlign }: SectionExtraDataType): RichTextParsersConfig => {
  const titleAlignClass = classes[`titleAlign${capitalizeFirstLetter(titleAlign)}`];
  const pAlignClass = classes[`pAlign${capitalizeFirstLetter(pAlign || titleAlign)}`];
  const getHeadingClasses = (headingClass: string) => cn(
    styles.psychicTitle,
    headingClass,
    titleAlignClass,
  );

  return ({
    [BLOCKS.PARAGRAPH]: {
      classNames: cn(styles.psychicParagraph, pAlignClass),
    },
    [BLOCKS.HEADING_1]: {
      classNames: getHeadingClasses(classes.titleMainH1),
    },
    [BLOCKS.HEADING_2]: {
      classNames: getHeadingClasses(classes.titleMainH2),
    },
    [BLOCKS.HEADING_3]: {
      classNames: getHeadingClasses(classes.titleMainH3),
    },
    [MARKS.BOLD]: {
      classNames: classes.textBold,
    },
  });
};

/* Nested component */
const Psychics: FC<PsychicsInterface> = ({
  array,
  bootStatus,
  itemsLayout,
  childBlocks,
  psychicCardAppearance,
}) => {
  const user = useSelector((store: Store) => store.server.auth.user);

  if (!childBlocks) {
    return null;
  }

  const childBlock = childBlocks.find(({ __typename }) => __typename === 'Psychic');

  if (!childBlock || !array?.length) {
    return null;
  }

  // eslint-disable-next-line no-undef
  let components: Array<JSX.Element> | null = null;

  if (psychicCardAppearance === PsychicCardAppearance.SIMPLE_ADDITIONAL
    || psychicCardAppearance === PsychicCardAppearance.SIMPLE) {
    components = array.map((psychic, i) => (
      <PsychicCardSimple
        key={psychic.extId}
        bootStatus={bootStatus}
        user={user}
        psychicSkeleton={childBlock}
        positionInArray={getItemPositionForGa<RightPsychic>(array, i)}
        psychic={psychic}
      />
    ));
  }

  if (psychicCardAppearance === PsychicCardAppearance.TILE) {
    components = array.map((psychic, i) => (
      <PsychicCardTile
        key={psychic.extId}
        bootStatus={bootStatus}
        user={user}
        psychicSkeleton={childBlock}
        positionInArray={getItemPositionForGa<RightPsychic>(array, i)}
        psychic={psychic}
      />
    ));
  }

  if (psychicCardAppearance === PsychicCardAppearance.LIST) {
    components = array.map((psychic, i) => (
      <PsychicCardList
        key={psychic.extId}
        bootStatus={bootStatus}
        user={user}
        psychicSkeleton={childBlock}
        positionInArray={getItemPositionForGa<RightPsychic>(array, i)}
        psychic={psychic}
      />
    ));
  }

  const getStyle = (): CSSProperties | undefined => {
    if (itemsLayout !== ItemsLayout.GRID || psychicCardAppearance === PsychicCardAppearance.LIST) {
      return;
    }

    if ((psychicCardAppearance === PsychicCardAppearance.SIMPLE && array.length < 5)
    || (psychicCardAppearance === PsychicCardAppearance.TILE && array.length < 3)) {
      return { gridTemplateColumns: new Array(array.length).fill('1fr').join(' ') };
    }
  };

  const getItemsLayoutClass = () => {
    if (psychicCardAppearance === PsychicCardAppearance.LIST) {
      return capitalizeFirstLetter(ItemsLayout.COLUMN);
    }

    return capitalizeFirstLetter(itemsLayout);
  };
  const itemsLayoutName = getItemsLayoutClass();
  const getPsychicCardAppearanceName = () => {
    if (psychicCardAppearance === PsychicCardAppearance.SIMPLE
      || psychicCardAppearance === PsychicCardAppearance.SIMPLE_ADDITIONAL) {
      return 'Simple';
    }

    if (psychicCardAppearance === PsychicCardAppearance.TILE) {
      return 'Tile';
    }
  };
  const psychicCardAppearanceName = getPsychicCardAppearanceName();

  return (
    <ul
      style={getStyle()}
      className={cn(
        styles.psychicContent,
        styles[`psychicContent${psychicCardAppearanceName}${itemsLayoutName}`],
      )}
    >
      {components}
    </ul>
  );
};

/* Nested component */
const ExponeaWrapper: FC<PsychicsInterface> = ({
  array,
  bootStatus,
  itemsLayout,
  childBlocks,
  viewerDevice,
  psychicCardAppearance,
}) => {
  if (!childBlocks) {
    return null;
  }

  return (
    <Psychics
      psychicCardAppearance={psychicCardAppearance}
      itemsLayout={itemsLayout}
      childBlocks={childBlocks}
      bootStatus={bootStatus}
      array={array}
      viewerDevice={viewerDevice}
    />
  );
};

/* Nested component */
const LoadableItems: FC<ExponeaLoadableItems> = ({
  loader,
  psychics,
  isLoading,
  bootStatus,
  itemsLayout,
  childBlocks,
  viewerDevice,
  psychicCardAppearance,
}) => {
  if (isLoading) {
    return (
      <div>
        <Loader
          image={loader!}
          isVisible={isLoading}
        />
      </div>
    );
  }

  return (
    <ExponeaWrapper
      psychicCardAppearance={psychicCardAppearance}
      itemsLayout={itemsLayout}
      childBlocks={childBlocks}
      bootStatus={bootStatus}
      array={psychics}
      viewerDevice={viewerDevice}
    />
  );
};

/* Nested Component */
const Content: FC<CommonRichTextInterface> = ({ content, parsersConfig }) => {
  if (!content) {
    return null;
  }

  return (
    <CommonRichText
      content={content}
      parsersConfig={parsersConfig}
    />
  );
};

/* Nested Component */
const Title = Content;

/* Main component */
const ExponeaPsychicsList: FC<ExponeaPsychicsListInterface> = ({
  bgColor,
  content: block,
  isLoading,
  loader,
  psychics,
  extraData,
  bootStatus,
  sectionRef,
  topDivider,
  viewerDevice,
  bottomDivider,
  commonPageMaxWidth,
}) => {
  const {
    titleAlign = ElementAlign.LEFT,
    pAlign,
    verticalPadding = CommonSize.MEDIUM,
    psychicCardAppearance = PsychicCardAppearance.TILE,
    itemsLayout = ItemsLayout.ROW,
  } = extraData || {};
  const {
    richTitle,
    content,
    contentTypesCollection,
    link,
    entryName,
  } = block;
  const children = contentTypesCollection?.items as Array<Psychic> | undefined;
  const parsersConfig = getParsersConfig({ titleAlign, pAlign });

  return (
    <section
      ref={sectionRef}
      className={cn(styles.wrapper, classes[`sharedWrapperPaddingVertical${capitalizeFirstLetter(verticalPadding)}`])}
      style={{ background: bgColor }}
    >
      <Divider
        block={topDivider as Block}
        maxWidth={`${commonPageMaxWidth}px`}
      />
      <div className={styles.psychic} key={entryName}>
        <Title
          content={richTitle!}
          parsersConfig={parsersConfig}
        />
        <Content
          content={content!}
          parsersConfig={parsersConfig}
        />
        <LoadableItems
          bootStatus={bootStatus}
          loader={loader!}
          isLoading={isLoading}
          psychicCardAppearance={psychicCardAppearance}
          itemsLayout={itemsLayout}
          childBlocks={children}
          psychics={psychics}
          viewerDevice={viewerDevice}
        />
        {link && (
          <DataLink
            link={link}
            href={link.src!}
            className={styles.psychicButton}
          >
            {link.title}
          </DataLink>
        )}
      </div>
      <Divider
        block={bottomDivider as Block}
        maxWidth={`${commonPageMaxWidth}px`}
      />
    </section>
  );
};

export default memo(ExponeaPsychicsList);
