import { createContext } from 'react';

import type { IPsychicsContext, IPsychicsContextDispatch } from '../config/declarations';

export const psychicListContext: IPsychicsContext = {
  psychics: [],
};

export const PsychicsContext = createContext<IPsychicsContext>(psychicListContext);

export const PsychicsContextDispatch = createContext<IPsychicsContextDispatch>(() => {});
