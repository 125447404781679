import { FC, Fragment } from 'react';
import { BLOCKS } from '@contentful/rich-text-types';
import dynamic from 'next/dynamic';
import { useSelector } from 'react-redux';

import type { Store } from 'app-redux/types/storeTypes';
import { CommonRichText } from 'components/Shared/SharedComponents';
import { RichTextParsersConfig } from 'types/objectTypes';
import { mapTopBarPhone } from 'lib/richTextMappers';
import { getModuleNotLoadedErrorHandler } from 'src/shared/lib/modules';

import type { IHeaderPhones } from './declarations';
import styles from './styles.module.scss';

const parsersConfig: RichTextParsersConfig = {
  [BLOCKS.PARAGRAPH]: {
    classNames: styles.phonesHelpText,
    mapper: mapTopBarPhone,
  },
};

const TopBarPhone = dynamic(() => import('./TopBarPhone')
  .then((res) => res)
  .catch(() => import('./TopBarPhone')
    .then((res) => res)
    .catch(getModuleNotLoadedErrorHandler(
      'Some modules weren\'t loaded. It can affect the page and some essential elements can be not visible. Do you want to reload the page to try to load modules again?',
      'TopBarPhone wasn\'t loaded',
    ))),
{
  ssr: false,
});

const Phones: FC<IHeaderPhones> = ({ phoneNumbers }) => {
  const ctmNumber = useSelector((store: Store) => store.client.app.ctmNumber);

  if (!phoneNumbers?.length) {
    return null;
  }

  return (
    <>
      {phoneNumbers.map((phone, i) => {
        const { content, title, link } = phone;

        if (!content) {
          return null;
        }

        const isLastElem = phoneNumbers.length - 1 === i;

        return (
          <Fragment key={link?.entryName}>
            <div className={styles.phonesHelp}>
              <CommonRichText content={content} parsersConfig={parsersConfig} />
              <TopBarPhone
                title={title}
                link={link}
                isLastElem={isLastElem}
                ctmNumber={ctmNumber}
              />
            </div>
            {!isLastElem && <span className={styles.phonesHelpDivider} />}
          </Fragment>
        );
      })}
    </>
  );
};

export default Phones;
