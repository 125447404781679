import { FC } from 'react';
import { useSelector } from 'react-redux';

import { Block } from 'src/__generated__/graphqlTypes';
import { SectionComponentInterface } from 'types/componentTypes';
import { RelatedBlogPosts } from 'components/Sections/RelatedBlogPosts';
import { ContentfulSlugsNoArrows } from 'types/objectTypes';
import { useCommonContentfulBlocks } from 'lib/shared.hook';
import { getZodiacSign } from 'lib/sharedMethods.service';
import { Store } from 'app-redux/types/storeTypes';

const RelatedBlogPostsContainer: FC<SectionComponentInterface> = ({
  blocks,
  bgColor,
  extraData,
}) => {
  const slug = useSelector((store: Store) => store.server.page.slug);
  const zodiacSign = getZodiacSign(slug);
  const {
    content,
    topDivider,
    bottomDivider,
    commonPageMaxWidth,
  } = useCommonContentfulBlocks<ContentfulSlugsNoArrows, Block>(blocks);

  return (
    <RelatedBlogPosts
      bgColor={bgColor}
      content={content}
      extraData={extraData}
      zodiacSign={zodiacSign}
      topDivider={topDivider}
      bottomDivider={bottomDivider}
      commonPageMaxWidth={commonPageMaxWidth}
    />
  );
};

export default RelatedBlogPostsContainer;
