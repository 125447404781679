import { BLOCKS } from '@contentful/rich-text-types';
import { FC } from 'react';
import cn from 'classnames';

import { RichTextParsersConfig } from 'types/objectTypes';
import { CommonRichText, DataLink } from 'components/Shared/SharedComponents';
import { SectionComponentInterface } from 'types/componentTypes';
import { Block } from 'src/__generated__/graphqlTypes';

import styles from './DualColured.module.scss';

import { MainInterface, DualContainerSlug as Slug } from '../config/declarations';

const getParsersConfig = (): RichTextParsersConfig => ({
  [BLOCKS.HEADING_2]: {
    classNames: styles.dualColuredTitle,
  },
  [BLOCKS.HEADING_3]: {
    classNames: styles.dualColuredTitle,
  },
});

/* Nested component */
const MainContent: FC<MainInterface> = ({ mainBlock }) => {
  const { content, richTitle } = mainBlock;

  if (!richTitle && !content) {
    return null;
  }

  return (
    <>
      <div className={styles.dualColuredContainerMain}>
        {richTitle
        && (
          <CommonRichText
            content={richTitle}
            parsersConfig={getParsersConfig()}
          />
        )}
        <div className={styles.dualColuredContainerMainContent}>
          {content
          && (
            <CommonRichText
              content={content}
              parsersConfig={getParsersConfig()}
            />
          )}
        </div>
      </div>
    </>
  );
};

/* Nested component */
const SecondaryContent: FC<MainInterface> = ({ mainBlock }) => {
  const { content, richTitle, link, entryName } = mainBlock;

  if (!richTitle && !content) {
    return null;
  }

  return (
    <div className={styles.dualColuredContainerSecondary}>
      {richTitle
        && (
          <CommonRichText
            content={richTitle}
            parsersConfig={getParsersConfig()}
          />
        )}
      <div className={styles.dualColuredContent}>
        {content
          && (
            <CommonRichText
              content={content}
              parsersConfig={getParsersConfig()}
            />
          )}
        {link && (
          <DataLink
            link={link}
            key={entryName}
            className={cn(styles.dualColuredContentButton, styles.dualColuredContentButtonText)}
          >
            {link?.title}
          </DataLink>
        )}
      </div>
    </div>
  );
};

/* Main component */
const DualColured: FC<SectionComponentInterface> = ({
  blocks,
  bgColor,
}) => {
  const [block] = blocks;

  const main = block?.contentTypesCollection?.items
    ?.find((item) => (item as Block)?.slug === Slug.MAIN) as Block;

  const secondary = block?.contentTypesCollection?.items
    ?.find((item) => (item as Block)?.slug === Slug.SECONDARY) as Block;

  return (
    <section
      className={styles.dualColured}
      style={{ background: bgColor }}
    >
      <div className={styles.dualColuredContainer}>
        <MainContent
          mainBlock={main!}
        />
        <SecondaryContent
          mainBlock={secondary!}
        />
      </div>
    </section>
  );
};
export default DualColured;
