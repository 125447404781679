import { useSelector } from 'react-redux';

import {
  callReserve,
  fetchSiteInfo,
  callConnect,
  updatePhoneNumber,
} from 'src/api/customerApi';
import type { Store } from 'app-redux/types/storeTypes';

import { useMessageButtonContext, useMessageDispatch } from './message';

import { IEcCallback, IECUpdateNumber } from '../config/declarations';

const getPhoneType = (isPhoneVerified: boolean) => (isPhoneVerified ? 1 : 2);

export const useAddNumberFunction = () => {
  const user = useSelector((store: Store) => store.server.auth.user);
  const dispatch = useMessageDispatch();
  const store = useMessageButtonContext();

  const addNewNumber = async (
    selectedCountry: IECUpdateNumber,
    newNumber: string,
    isPhoneVerified: boolean,
    primary?: Number,
  ) => {
    const res = await updatePhoneNumber(
      {
        PhoneCountry: selectedCountry.countryCode,
        PhoneNumber: newNumber,
        PhoneType: getPhoneType(isPhoneVerified).toString(),
        SaleLocation: 'Mobile',
        SetPrimaryNumber: primary,
        isVerified: true,
      },
      user!,
    );

    if (res !== true) {
      return;
    }

    const telephoneObject = {
      countryCallingCode: selectedCountry.countryCallingCode,
      countryCode: selectedCountry.countryCode,
      isVerified: true,
      phoneNumber: newNumber,
      phoneType: getPhoneType(isPhoneVerified),
    };
    dispatch({
      type: 'telephones',
      payload: [telephoneObject, ...(store.telephones || [])],
    });

    return res;
  };

  return addNewNumber;
};

export const useCallbackReserveFunction = () => {
  const user = useSelector((store: Store) => store.server.auth.user);

  const talkReserve = async (
    custId: string,
    extId: Number,
  ) => {
    const res = await callReserve(
      {
        callbackInfo: {
          CustID: custId,
          ExtId: extId,
          statusOnly: true,
          SaleLocation: 'Mobile',
        },
      },
      user!,
    );

    return res;
  };

  return { talkReserve };
};

export const useSiteInfoFunction = () => {
  const user = useSelector((store: Store) => store.server.auth.user);

  const getSiteInfo = async (
    currentUrl: string,
    referrerUrl: string,
    windowWidth: Number,
  ) => {
    const res = await fetchSiteInfo(
      {
        CurrentUrl: currentUrl,
        ReferrerUrl: referrerUrl,
        WindowWidth: windowWidth,
      },
      user!,
    );

    return res;
  };

  return { getSiteInfo };
};

export const useCallbackFunction = () => {
  const user = useSelector((store: Store) => store.server.auth.user);

  const callback = async (
    details: IEcCallback,
  ) => {
    const res = await callConnect(
      {
        CustId: details.customerId,
        ExtId: details.extensionId,
        PhoneNumber: details.phone,
        CountryCode: details.countryCode,
        PhoneType: details.phoneType,
        Duration: details.duration,
        SaleLocation: details.saleLocation,
        ClientID: details.clientID,
        ForceCallback: details.forceCallback,
        OverrideForceCallback: details.overrideForceCallback,
      },
      user!,
    );

    return res;
  };

  return { callback };
};
