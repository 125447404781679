import { FC } from 'react';
import { useSelector } from 'react-redux';
import cn from 'classnames';

import { SectionComponentInterface } from 'types/componentTypes';
import type { Store } from 'app-redux/types/storeTypes';

import styles from './styles.module.scss';
import Offers from './Offers/Offers';
import Greeting from './Greeting/Greeting';

import { getBlocks } from '../lib';

const Component: FC<SectionComponentInterface> = ({
  blocks,
  bgColor,
}) => {
  const user = useSelector((store: Store) => store.server.auth.user);

  if (!user) {
    return user;
  }

  const { greeting, offers } = getBlocks(blocks);

  return (
    <section>
      <div
        className={cn(
          styles.wrapper,
        )}
        style={{ background: bgColor }}
      >
        <Greeting greeting={greeting} />
        <Offers offers={offers} />
      </div>
    </section>
  );
};

export default Component;
