import { useContext } from 'react';

import { PsychicsContext, PsychicsContextDispatch } from './PsychicsContext';

export const usePsychicsDispatch = () => {
  const dispatch = useContext(PsychicsContextDispatch);

  return dispatch;
};

export const usePsychicsContext = () => {
  const store = useContext(PsychicsContext);

  return store;
};
