import { FC } from 'react';
import { MARKS, BLOCKS } from '@contentful/rich-text-types';

import type { RichTextParsersConfig } from 'types/objectTypes';
import { PsychicCardImage } from 'entities/PsychicCardImage';
import { CommonRichText } from 'components/Shared/SharedComponents';
import { mapMarksBoldWithReplacement } from 'lib/richTextMappers';

import styles from './styles.module.scss';

import type { IECPsychicPhoto } from '../../config/declarations';

const getParsersConfig = (
  psychicName?: string,
): RichTextParsersConfig => {
  const replacementObject = { psychicName };

  return ({
    [BLOCKS.PARAGRAPH]: {
      classNames: styles.title,
    },
    [MARKS.BOLD]: {
      classNames: styles.title,
      mapper: mapMarksBoldWithReplacement(replacementObject),
    },
  });
};

const ECTalkPsychicPhoto: FC<IECPsychicPhoto> = ({
  psychic,
  block,
  psychicFrame,
}) => {
  if (!block) {
    return null;
  }

  return (
    <div className={styles.photo}>
      <PsychicCardImage
        psychic={psychicFrame!}
        className={styles.image}
        image="external"
        src={psychic.psychicImageUrl}
      />
      <div className={styles.content}>
        <CommonRichText content={block.content} parsersConfig={getParsersConfig(`Psychic ${psychic.lineName}`)} />
      </div>
    </div>
  );
};

export default ECTalkPsychicPhoto;
