import { FC } from 'react';
import { BLOCKS, MARKS } from '@contentful/rich-text-types';
import cn from 'classnames';

import styles from 'components/Sections/HoroscopesSignsHoroscopesAndPredictions/CompatibilityCheck/CompatibilityCheck.module.scss';
import classes from 'src/styles/_commonClasses.module.scss';
import { CompatibilitySelect } from 'components/Sections/HoroscopesSignsHoroscopesAndPredictions/CompatibilityCheck';
import { CommonRichText, WebpImage } from 'components/Shared/SharedComponents';
import { RichTextParsersConfig, WebpWidthLimitType } from 'types/objectTypes';
import type { Block } from 'src/__generated__/graphqlTypes';
import { CompatibilityCheckInterface } from 'components/Sections/HoroscopesSignsHoroscopesAndPredictions/CompatibilityCheck/declarations';

const parsersConfig: RichTextParsersConfig = {
  [BLOCKS.PARAGRAPH]: {
    classNames: cn(styles.compatibilityCheckParagraph, styles.compatibilityCheckParagraphCommon),
  },
  [MARKS.BOLD]: {
    classNames: cn(styles.compatibilityCheckParagraph, styles.compatibilityCheckParagraphBold),
  },
};

const CompatibilityCheck: FC<CompatibilityCheckInterface> = ({
  filledSelects,
  compatibilityCheck,
  mobileViewMaxWidth,
  horoscopeTabletMaxWidth,
  horoscopeMobileImageBackgroundWidth,
  horoscopeCompatibilitySelectImageSize,
  horoscopeDesktopRightSection,
  horoscopeDesktopLeftSection,
  setUsersSignSelectValue,
  setPartnersSignSelectValue,
}) => {
  if (!compatibilityCheck) return null;

  const {
    image,
    content,
    contentTypesCollection,
  } = compatibilityCheck;
  const finalImage = image;
  const constructImageSimpleWidthLimit = (
    width: number,
    media: number,
    isMinWidth: boolean = false,
  ) => ({
    width,
    isMinWidth,
    media: `${media}px`,
  });
  const dropdownBlocks = (contentTypesCollection?.items || []) as Array<Block>;
  const [yourSign, partnersSign] = dropdownBlocks;

  const imageWidthLimit: Array<WebpWidthLimitType> = [
    constructImageSimpleWidthLimit(horoscopeMobileImageBackgroundWidth, mobileViewMaxWidth),
    constructImageSimpleWidthLimit(horoscopeDesktopLeftSection, horoscopeTabletMaxWidth),
    constructImageSimpleWidthLimit(horoscopeDesktopRightSection, horoscopeTabletMaxWidth, true),
  ];

  const setCompatibilitySelectSharedOptions = (
    sign: Block,
    setCurrentSelectValue: (value: Block) => void,
  ) => ({
    link: sign.link!,
    title: sign.title!,
    image: sign.image!,
    options: sign.contentTypesCollection?.items as Array<Block>,
    horoscopeCompatibilitySelectImageSize,
    setCurrentSelectValue,
  });

  return (
    <div className={cn(styles.wrapper, classes.horoscopeSignSectionBottomItemOffset)}>
      <div className={styles.compatibilityCheck}>
        {content && (
          <CommonRichText
            content={content}
            parsersConfig={parsersConfig}
          />
        )}
        <div className={styles.compatibilityCheckDropdowns}>
          {yourSign && (
            <CompatibilitySelect
              {...setCompatibilitySelectSharedOptions(yourSign, setUsersSignSelectValue)}
              selectedItem={filledSelects.usersSign}
              className={styles.compatibilityCheckDropdownYour}
            />
          )}
          {partnersSign && (
            <CompatibilitySelect
              {...setCompatibilitySelectSharedOptions(partnersSign, setPartnersSignSelectValue)}
              selectedItem={filledSelects.partnersSign}
            />
          )}
        </div>
      </div>
      {finalImage && (
        <WebpImage
          image={finalImage}
          className={styles.compatibilityCheckBackground}
          widthLimit={imageWidthLimit}
        />
      )}
    </div>
  );
};

export default CompatibilityCheck;
