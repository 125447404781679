import { FC } from 'react';
import cn from 'classnames';

import styles from './PsychicBioInformation.module.scss';

import type { IProperties } from '../config/declarations';

const Properties: FC<IProperties> = ({ psychic, frame, isCollapsed }) => (
  <ul className={cn(
    styles.list,
    { [styles.hidden]: isCollapsed },
  )}
  >
    <li className={cn(styles.item, styles.combined)}>
      <div>
        <strong>{frame?.totalReadings}</strong>
        <span>{`${psychic.totalReadings} ${frame?.since} ${psychic.serviceStartYear}`}</span>
      </div>
      <div className={styles.bottomWrapper}>
        <strong>{frame?.extension}</strong>
        <span>{psychic.extId}</span>
      </div>
    </li>

    <li className={styles.item}>
      <strong>{frame?.abilities}</strong>
      <div>
        {psychic.skills
          .split(',')
          .map((ability) => <span key={ability}>{ability}</span>)}
      </div>
    </li>

    <li className={styles.item}>
      <strong>{frame?.topics}</strong>
      <div>
        {psychic.specialities
          .map((topic) => <span key={topic}>{topic}</span>)}
      </div>
    </li>

    <li className={styles.item}>
      <strong>{frame?.style}</strong>
      <span>{psychic.style}</span>
    </li>

    <li className={styles.item}>
      <strong>{frame?.tools}</strong>
      <div>
        {psychic.tools
          .map((tool) => <span key={tool}>{tool}</span>)}
      </div>
    </li>
  </ul>
);

export default Properties;
