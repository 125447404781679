import { FC } from 'react';
import {
  BLOCKS,
  MARKS,
  INLINES,
} from '@contentful/rich-text-types';
import cn from 'classnames';

import classes from 'src/styles/_commonClasses.module.scss';
import {
  CommonRichText as Content,
  Icon,
  WebpImage,
} from 'components/Shared/SharedComponents';

import styles from './PredefinedReviews.module.scss';

import { PredefinedReviewInterface } from '../config/declarations';

const getHeadingClasses = (headingClass: string) => cn(
  styles.reviewCardTitle,
  headingClass,
);
const parsersConfig = {
  [BLOCKS.HEADING_3]: {
    classNames: getHeadingClasses(styles.reviewCardTitleH3),
  },
  [BLOCKS.HEADING_4]: {
    classNames: getHeadingClasses(styles.reviewCardTitleH4),
  },
  [BLOCKS.PARAGRAPH]: {
    classNames: styles.reviewCardParagraph,
  },
  [INLINES.HYPERLINK]: {
    classNames: cn(styles.reviewCardBodyParagraph, styles.reviewCardBodyParagraphLink),
  },
  [MARKS.BOLD]: {
    classNames: classes.textBold,
  },
};

/* Nested component */
const Title = Content;

const PredefinedReview: FC<PredefinedReviewInterface> = ({ block, chatImage, starImage }) => {
  const {
    content,
    richTitle,
  } = block;

  return (
    <div className={styles.reviewCard}>
      {chatImage && (
        <div className={styles.reviewCardChat}>
          <WebpImage
            image={chatImage}
            className={styles.reviewCardChatImage}
          />
        </div>
      )}
      <div className={styles.reviewCardContent}>
        {starImage && (
          <div className={styles.reviewCardStar}>
            <Icon
              image={starImage}
              className={styles.reviewCardStarImage}
            />
          </div>
        )}
        <Title
          content={richTitle}
          parsersConfig={parsersConfig}
        />
        <Content
          content={content}
          parsersConfig={parsersConfig}
        />
      </div>
    </div>
  );
};

export default PredefinedReview;
