import { FC, useState } from 'react';
import cn from 'classnames';

import OffersButtons from './OffersButtons';

import styles from '../styles.module.scss';
import type { IOffers } from '../../config/declarations';

const Offers: FC<IOffers> = ({ offers }) => {
  const [isSingleOffer, setIsSingleOffer] = useState<boolean>(false);

  if (!offers) {
    return null;
  }

  return (
    <div className={styles.offerRoot}>
      <p className={styles.offerTitle}>
        {offers.title}
      </p>
      <div className={cn(styles.box, styles.offers, {
        [styles.offersSingle]: isSingleOffer,
      })}
      >
        <OffersButtons
          link={offers.link}
          setIsSingleOffer={setIsSingleOffer}
          content={offers.content}
        />
      </div>
    </div>
  );
};

export default Offers;
