import { ParsedUrlQuery } from 'querystring';

import { FC, Fragment } from 'react';
import {
  BLOCKS,
  MARKS,
  INLINES,
} from '@contentful/rich-text-types';
import cn from 'classnames';

import type { Block } from 'src/__generated__/graphqlTypes';
import styles from 'src/components/Sections/AboutHeroImage/AboutHeroImage.module.scss';
import classes from 'src/styles/_commonClasses.module.scss';
import type { RichTextParsersConfig } from 'types/objectTypes';
import type { SectionComponentInterface } from 'types/componentTypes';
import {
  mapBlocksHeroImage,
  mapBlocksParagraphWithReplacement,
  mapMarksBoldWithReplacement,
} from 'lib/richTextMappers';
import { capitalizeFirstLetter } from 'lib/text.service';
import { useCustomRouter } from 'src/shared/lib/history/hooks';
import { CommonRichText as Content, DataLink } from 'components/Shared/SharedComponents';

const getParsersConfig = (query: ParsedUrlQuery): RichTextParsersConfig => ({
  [BLOCKS.EMBEDDED_ASSET]: {
    classNames: styles.image,
    mapper: mapBlocksHeroImage,
  },
  [BLOCKS.PARAGRAPH]: {
    classNames: styles.paragraph,
    mapper: mapBlocksParagraphWithReplacement({ search: query.search as string }),
  },
  [BLOCKS.HEADING_2]: {
    classNames: cn(styles.title),
  },
  [BLOCKS.HEADING_1]: {
    classNames: cn(styles.title),
  },
  [MARKS.BOLD]: {
    classNames: cn(classes.textBold, styles.bold),
    mapper: mapMarksBoldWithReplacement({ search: query.search as string }),
  },
  [INLINES.HYPERLINK]: {
    classNames: styles.hyperlink,
  },
});

/* Nested component */
const Title = Content;

/* Main component */
const AboutHeroImage: FC<SectionComponentInterface> = ({
  blocks,
  bgColor,
  extraData,
}) => {
  const router = useCustomRouter();

  const { maxWidth = 'local', verticalPadding, sectionStyle } = extraData || {};

  const buildComponents = () => blocks
    .map((block: Block) => {
      const { content, link, entryName, richTitle } = block;

      if (!content) {
        return null;
      }

      return (
        <Fragment key={entryName}>
          <Title
            content={richTitle}
            parsersConfig={getParsersConfig(router.query)}
          />
          <Content
            content={content}
            parsersConfig={getParsersConfig(router.query)}
          />
          {link && (
            <DataLink
              link={link}
              href={link.src!}
              className={styles.heroButton}
            >
              {link.title}
            </DataLink>
          )}
        </Fragment>
      );
    });

  if (!router.query.search && router.pathname === '/psychic-not-found') {
    return null;
  }

  return (
    <section
      className={cn(
        styles.wrapper,
        sectionStyle
          ? styles[`${sectionStyle}`]
          : styles.common,
        { [classes[`sharedWrapperPaddingVertical${capitalizeFirstLetter(verticalPadding)}`]]: Boolean(verticalPadding) },
      )}
      style={{ background: bgColor }}
    >
      <div className={cn(
        styles.hero,
        styles[`width${capitalizeFirstLetter(maxWidth)}`],
      )}
      >
        {buildComponents()}
      </div>
    </section>
  );
};

export default AboutHeroImage;
