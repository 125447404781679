import {
  FC,
  useEffect,
  useState,
} from 'react';
import cn from 'classnames';
import dynamic from 'next/dynamic';

import styles from 'components/Sections/HoroscopesSignsHoroscopesAndPredictions/HoroscopesAndPredictions.module.scss';
import classes from 'src/styles/_commonClasses.module.scss';
import Divider from 'components/Sections/HoroscopesSignsHoroscopesAndPredictions/Divider';
import DailyHoroscope from 'components/Sections/HoroscopesSignsHoroscopesAndPredictions/DailyHoroscope';
import MoreHoroscopes from 'components/Sections/HoroscopesSignsHoroscopesAndPredictions/MoreHoroscopes';
import HoroscopeNewsletters from 'components/Sections/HoroscopeNewsletters';
import CompatibilityCheck from 'components/Sections/HoroscopesSignsHoroscopesAndPredictions/CompatibilityCheck';
import { capitalizeFirstLetter } from 'lib/text.service';
import { HoroscopesAndPredictionsInterface, ViewHoroscopesAndPredictionsInterface } from 'components/Sections/HoroscopesSignsHoroscopesAndPredictions/declarations';
import { ViewerDevice } from 'constants/enums';
import { IS_CLIENT_SIDE } from 'constants/constants';

const TopPsychics = dynamic(async () => {
  const { PsychicsWithTestimonialsContainer } = await import('features/PsychicsWithTestimonials');

  return PsychicsWithTestimonialsContainer;
}, { ssr: false });

/* Nested component */
const DesktopView: FC<ViewHoroscopesAndPredictionsInterface> = ({
  dailyHoroscope,
  moreHoroscopes,
  freeHoroscope,
  topPsychics,
  zodiacSign,
  compatibilityCheck,
  slug,
}) => {
  const commonProperties = {
    slug,
    zodiacSign,
  };

  return (
    <div className={styles.horoscopesAndPredictionsContent}>
      <div className={styles.horoscopesAndPredictionsContentFirst}>
        {dailyHoroscope && (
          <DailyHoroscope block={dailyHoroscope} {...commonProperties} />
        )}
        {moreHoroscopes && (
          <MoreHoroscopes block={moreHoroscopes} {...commonProperties} />
        )}
        {topPsychics && (
          <TopPsychics block={topPsychics} {...commonProperties} />
        )}
      </div>
      <div className={styles.horoscopesAndPredictionsContentSecond}>
        {freeHoroscope && (
          <HoroscopeNewsletters block={freeHoroscope} {...commonProperties} />
        )}
        {compatibilityCheck && (
          <CompatibilityCheck block={compatibilityCheck} {...commonProperties} />
        )}
      </div>
    </div>
  );
};

/* Nested component */
const MobileView: FC<ViewHoroscopesAndPredictionsInterface> = ({
  dailyHoroscope,
  moreHoroscopes,
  freeHoroscope,
  topPsychics,
  zodiacSign,
  compatibilityCheck,
  slug,
}) => {
  const commonProperties = {
    slug,
    zodiacSign,
  };

  return (
    <div className={styles.horoscopesAndPredictionsContent}>
      <div className={styles.horoscopesAndPredictionsContentFirst}>
        {dailyHoroscope && (
          <DailyHoroscope block={dailyHoroscope} {...commonProperties} />
        )}
        {moreHoroscopes && (
          <MoreHoroscopes block={moreHoroscopes} {...commonProperties} />
        )}
        {freeHoroscope && (
          <HoroscopeNewsletters block={freeHoroscope} {...commonProperties} />
        )}
      </div>
      <div className={styles.horoscopesAndPredictionsContentSecond}>
        {topPsychics && (
          <TopPsychics block={topPsychics} {...commonProperties} />
        )}
        {compatibilityCheck && (
          <CompatibilityCheck block={compatibilityCheck} {...commonProperties} />
        )}
      </div>
    </div>
  );
};

/* Nested component */
const View: FC<HoroscopesAndPredictionsInterface> = ({
  topDivider,
  dailyHoroscope,
  moreHoroscopes,
  freeHoroscope,
  topPsychics,
  compatibilityCheck,
  horoscopeTabletMaxWidth,
  bottomDivider,
  viewerDevice,
  zodiacSign,
  bgColor,
  slug,
}) => {
  const checkIsDesktop = () => {
    if (IS_CLIENT_SIDE) {
      return viewerDevice === ViewerDevice.DESKTOP && +horoscopeTabletMaxWidth < window.innerWidth;
    }

    return viewerDevice === ViewerDevice.DESKTOP;
  };
  const [isDesktop, setViewDesktopState] = useState<boolean>(checkIsDesktop());

  useEffect(() => {
    const isDesktop = viewerDevice === ViewerDevice.DESKTOP
      && +horoscopeTabletMaxWidth < window.innerWidth;
    setViewDesktopState(isDesktop);
  }, [viewerDevice, horoscopeTabletMaxWidth]);
  const Component = isDesktop ? DesktopView : MobileView;

  return (
    <Component
      zodiacSign={zodiacSign}
      bgColor={bgColor}
      topDivider={topDivider}
      dailyHoroscope={dailyHoroscope}
      moreHoroscopes={moreHoroscopes}
      topPsychics={topPsychics}
      freeHoroscope={freeHoroscope}
      compatibilityCheck={compatibilityCheck}
      bottomDivider={bottomDivider}
      viewerDevice={viewerDevice}
      slug={slug}
    />
  );
};

/* Main component */
const HoroscopesAndPredictions: FC<HoroscopesAndPredictionsInterface> = ({
  topDivider,
  dailyHoroscope,
  moreHoroscopes,
  freeHoroscope,
  topPsychics,
  compatibilityCheck,
  horoscopeTabletMaxWidth,
  bottomDivider,
  viewerDevice,
  bgColor,
  zodiacSign,
  slug,
}) => (
  <section
    style={{ background: bgColor }}
    className={cn(
      styles.wrapper,
      slug && styles[`wrapper${capitalizeFirstLetter(slug)}`],
    )}
  >
    <div className={styles.horoscopesAndPredictions}>
      {topDivider && (
        <Divider
          block={topDivider}
          zodiacSign={zodiacSign}
          className={classes.displayNone}
        />
      )}
      <View
        bgColor={bgColor}
        topDivider={topDivider}
        dailyHoroscope={dailyHoroscope}
        moreHoroscopes={moreHoroscopes}
        topPsychics={topPsychics}
        freeHoroscope={freeHoroscope}
        horoscopeTabletMaxWidth={horoscopeTabletMaxWidth}
        compatibilityCheck={compatibilityCheck}
        bottomDivider={bottomDivider}
        viewerDevice={viewerDevice}
        zodiacSign={zodiacSign}
        slug={slug}
      />
    </div>
  </section>
);

export default HoroscopesAndPredictions;
