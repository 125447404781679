import React from 'react';
import { BLOCKS, MARKS } from '@contentful/rich-text-types';
import cn from 'classnames';

import { Tick } from 'src/shared/ui/Tick';
import styles from 'components/Sections/HoroscopesSignsAllAboutSigns/RulingPlanetAndSignElement/RulingPlanetAndSignElement.module.scss';
import { SignFeatureContainer } from 'components/Sections/HoroscopesSignsAllAboutSigns/Shared';
import {
  CommonRichText,
  DataLink,
  WebpImage,
} from 'components/Shared/SharedComponents';
import { Block } from 'src/__generated__/graphqlTypes';
import { RichTextParsersConfig } from 'types/objectTypes';
import { mapBlocksSpan } from 'lib/richTextMappers';
import { getImagesWithLimitedSize } from 'lib/image.service';
import { ImageFitMode } from 'constants/enums';
import { RulingPlanetAndSignElementInterface, ImageSideSizeEnum as ImageSideSize } from 'components/Sections/HoroscopesSignsAllAboutSigns/RulingPlanetAndSignElement/declarations';

const createParsersConfig = (parentClass: string): RichTextParsersConfig => ({
  [BLOCKS.HEADING_3]: {
    classNames: styles[`${parentClass}ParagraphTitle`],
    mapper: mapBlocksSpan,
  },
  [BLOCKS.PARAGRAPH]: {
    classNames: styles[`${parentClass}ParagraphDescription`],
  },
  [MARKS.BOLD]: {
    classNames: cn(styles[`${parentClass}ParagraphTitle`], styles[`${parentClass}ParagraphTitleBold`]),
  },
});

const RulingPlanetAndSignElement: React.FC<RulingPlanetAndSignElementInterface> = ({
  rulingPlanet,
  signElement,
  mobileViewMaxWidth,
  horoscopeTabletMaxWidth,
}) => {
  const limitForImages = getImagesWithLimitedSize([
    {
      width: ImageSideSize.MOBILE,
      height: ImageSideSize.MOBILE,
      media: `${mobileViewMaxWidth}px`,
    },
    {
      width: ImageSideSize.TABLET,
      height: ImageSideSize.TABLET,
      media: `${horoscopeTabletMaxWidth}px`,
    },
    {
      width: ImageSideSize.DESKTOP,
      height: ImageSideSize.DESKTOP,
      isMinWidth: true,
      media: `${horoscopeTabletMaxWidth}px`,
    },
  ],
  { fit: ImageFitMode.SCALE });

  const applyElement = (element: Block, parentClass: string) => {
    if (!element) {
      return <SignFeatureContainer containerClassName={styles[parentClass]} />;
    }

    const { link, image, content } = element;
    const { image: arrow } = link || {};

    return (
      <SignFeatureContainer containerClassName={styles[parentClass]}>
        {image && (
          <WebpImage
            image={image}
            widthLimit={limitForImages}
            className={styles[`${parentClass}Icon`]}
          />
        )}
        {content && (
          <div className={styles[`${parentClass}Paragraph`]}>
            <CommonRichText
              content={content}
              parsersConfig={createParsersConfig(parentClass)}
            />
            {arrow && (
              <DataLink
                href={link!.src!}
                link={link!}
                className={styles[`${parentClass}Link`]}
              >
                <span>{link?.title}</span>
                <Tick
                  className={styles.selectNumbersIcon}
                  borderWidth={2}
                  color="#BB5204"
                  direction="right"
                />
              </DataLink>
            )}
          </div>
        )}
      </SignFeatureContainer>
    );
  };

  return (
    <div className={styles.wrapper}>
      {applyElement(rulingPlanet, 'rulingPlanet')}
      {applyElement(signElement, 'signElement')}
    </div>
  );
};

export default RulingPlanetAndSignElement;
