import cn from 'classnames';

import classes from 'styles/_commonClasses.module.scss';
import { SectionExtraDataType } from 'types/objectTypes';

export const useSectionsUiMerge = (
  extraData?: SectionExtraDataType,
  styles: Record<string, string> = {},
) => {
  if (!extraData) {
    return {};
  }

  const applyClassName = (name: string) => cn(styles[name], classes[name]);

  const {
    mergeTop = false,
    mergeBottom = false,
  } = extraData;

  return {
    [applyClassName('mergeNo')]: !mergeTop && !mergeBottom,
    [applyClassName('mergeBottom')]: !mergeTop && mergeBottom,
    [applyClassName('mergeTop')]: mergeTop && !mergeBottom,
    [applyClassName('mergeAll')]: mergeTop && mergeBottom,
  };
};
