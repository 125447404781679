/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import cn from 'classnames';
import { useSelect } from 'downshift';

import { Tick } from 'src/shared/ui/Tick';
import classes from 'src/styles/_commonClasses.module.scss';
import { LazyWebpImage, WebpImage } from 'components/Shared/SharedComponents';
import { ImageFitMode } from 'constants/enums';
import { OtherSignsListSelectInterface } from 'components/Sections/HoroscopesSignsAllAboutSigns/OtherSignsList/declarations';

import styles from './OtherSignsList.module.scss';

const OtherSignsListSelect: React.FC<OtherSignsListSelectInterface> = ({
  chevron,
  className,
  options,
  previewItem,
  signImageSize,
  setSelectedItem,
}) => {
  const {
    selectedItem,
    isOpen,
    getMenuProps,
    getToggleButtonProps,
    getItemProps,
  } = useSelect({ items: options });
  const additionalImageProps = {
    h: signImageSize,
    w: signImageSize,
    fit: ImageFitMode.SCALE,
  };

  const applySelectedItem = () => {
    if (!previewItem) {
      return null;
    }

    const currentItem = selectedItem || previewItem;
    const { image, title } = currentItem;

    return (
      <div className={styles.otherSignsDropdownButtonSelected}>
        {image && (
          <WebpImage
            image={image}
            height={`${signImageSize}px`}
            width={`${signImageSize}px`}
            additionalProps={additionalImageProps}
            pictureClassName={styles.otherSignsDropdownButtonSelectedPicture}
            className={styles.otherSignsDropdownButtonSelectedImage}
          />
        )}
        <span className={styles.otherSignsDropdownButtonSelectedTitle}>
          {title}
        </span>
      </div>
    );
  };

  const setChevronIcon = () => {
    if (!chevron) {
      return null;
    }

    return (
      <Tick
        sideSize={12}
        direction="bottom"
        color="#BB5204"
      />
    );
  };

  return (
    <div className={cn(styles.otherSignsDropdown, className)}>
      <button
        type="button"
        className={styles.otherSignsDropdownButton}
        {...getToggleButtonProps()}
      >
        {applySelectedItem()}
        {setChevronIcon()}
      </button>
      <ul
        {...getMenuProps()}
        className={cn(
          styles.otherSignsDropdownMenu,
          !isOpen && cn(classes.visibilityHidden, classes.zeroHeight),
        )}
      >
        {options?.map((link, index) => {
          const { entryName, title = '', image } = link;
          const itemProps = getItemProps({ item: link, index });

          return (
            <li
              key={entryName!}
              value={title!}
              {...itemProps}
              onClick={(e) => {
                itemProps.onClick?.(e);
                setSelectedItem(link);
              }}
              className={styles.otherSignsDropdownMenuOption}
            >
              {image && (
                <LazyWebpImage
                  image={image}
                  height={`${signImageSize}px`}
                  width={`${signImageSize}px`}
                  additionalProps={additionalImageProps}
                  pictureClassName={styles.otherSignsDropdownMenuOptionPicture}
                  className={styles.otherSignsDropdownMenuOptionImage}
                />
              )}
              <span className={styles.otherSignsDropdownMenuOptionTitle}>
                {title}
              </span>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default OtherSignsListSelect;
