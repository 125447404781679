import { FC } from 'react';

import { WebpImage } from 'components/Shared/SharedComponents';

import styles from './styles.module.scss';

import type { IEcCModal } from '../../config/declarations';

const Calling: FC<IEcCModal> = ({
  telephone,
  image,
  callingNumber,
}) => {
  if (!telephone) {
    return null;
  }

  const { countryCallingCode } = telephone;

  if (!countryCallingCode) {
    return null;
  }

  return (
    <div className={styles.callingSection}>
      <span className={styles.phone}>{`+${countryCallingCode} ${callingNumber}`}</span>
      <div className={styles.connect}>
        <WebpImage
          image="external"
          src={image}
        />
        <span className={styles.call}>Calling...</span>
      </div>
    </div>
  );
};
export default Calling;
