import {
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cookie from 'js-cookie';

import type { Store } from 'app-redux/types/storeTypes';
import { getCustomerDetails, getRecentPhoneNumbers } from 'src/api/customerApi';
import { getCommonCountriesList } from 'src/api/countriesApi';
import { setLoadingState } from 'app-redux/actions/appActions';

import { MessageContext, MessageContextDispatch } from './messageContext';

import type { IMessageContextDispatch } from '../config/declarations';

export const isSufficientFunds = (psychicPriceString: string, details: Record<string, any>) => {
  const psychicPrice = Number(psychicPriceString.substring(1));
  const dmCost = psychicPrice * details.dmBillableMinutes;
  const isDmAffordable = dmCost <= details.dollarBalance
    || (
      details.recurringPaymentInfo
      && details.recurringPaymentInfo !== null
      && details.recurringPaymentInfo.isEnabled
    );

  if (isDmAffordable) {
    return true;
  }

  cookie.set('dmBillableMinutes', details.dmBillableMinutes);

  return false;
};

export const getBalanceToAdd = (psychicPriceString: string, details: Record<string, any>) => {
  const psychicPrice = Number(psychicPriceString.substring(1));
  const dmCost = psychicPrice * details.dmBillableMinutes;

  return dmCost - details.dollarBalance;
};

export const useMessageDispatch = () => {
  const dispatch = useContext(MessageContextDispatch);

  return dispatch;
};

export const useMessageButtonContext = () => {
  const store = useContext(MessageContext);

  return store;
};

export const useMessageButtonApis = (
  dispatchLocal: IMessageContextDispatch,
  shouldRequest: boolean,
) => {
  const dispatch = useDispatch();
  const user = useSelector((store: Store) => store.server.auth.user);
  const wasRequested = useRef<boolean>(false);
  const [shouldBeShown, setShownState] = useState<boolean>(false);

  useEffect(() => {
    if (!user || !shouldRequest || wasRequested.current) {
      return;
    }

    (async () => {
      try {
        dispatch(setLoadingState(true));
        const query = { includeCC: 1, includeRecurringPaymentInfo: 1 };
        const [telephones, details, countryList] = await Promise.all([
          getRecentPhoneNumbers(user.custId),
          getCustomerDetails(user.custId, query),
          getCommonCountriesList(),
        ]);
        wasRequested.current = true;
        dispatchLocal({ type: 'telephones', payload: telephones });

        if (!telephones.length) {
          dispatchLocal({ type: 'isButtonDisabled', payload: true });
        }

        dispatchLocal({ type: 'customerDetails', payload: details });
        dispatchLocal({ type: 'countryList', payload: countryList });
        setShownState(true);
      } catch (e) {
        console.error(e);
      } finally {
        dispatch(setLoadingState(false));
      }
    })();
  }, [user, shouldRequest]);

  return { shouldBeShown };
};

export const formatPhoneNumber = (rawNumber: string) => {
  try {
    const cleaned = (`${rawNumber}`).replace(/\D/g, '');

    if (cleaned.length === 10) {
      return `(${cleaned.slice(0, 3)}) ${cleaned.slice(3, 6)}-${cleaned.slice(6)}`;
    }

    return cleaned;
  } catch (error) {
    console.log(error);
  }
};
