import { FC } from 'react';

import type { Block } from 'src/__generated__/graphqlTypes';
import type { SectionComponentInterface } from 'types/componentTypes';

import Topic from './Topic';
import styles from './TopicsLinks.module.scss';

const TopicsLinks: FC<SectionComponentInterface> = ({
  blocks,
  bgColor,
}) => {
  const [block] = blocks;

  if (!block) {
    return null;
  }

  const topics = block.contentTypesCollection
    ?.items
    .filter((block) => block?.__typename === 'Block') as Array<Block>;

  return (
    <section
      className={styles.wrapper}
      style={{ background: bgColor }}
    >
      {topics.map((block, index) => (
        <Topic
          key={`${block._id}${index.toString()}`}
          block={block}
        />
      ))}
    </section>
  );
};

export default TopicsLinks;
