import { FC } from 'react';
import { useSelector } from 'react-redux';
import cn from 'classnames';

import type { Store } from 'app-redux/types/storeTypes';
import { DataLink } from 'components/Shared/SharedComponents';

import styles from '../styles.module.scss';
import type { IKarma } from '../../config/declarations';

const Karma: FC<IKarma> = ({ joinKarma, karmaHub }) => {
  const user = useSelector((store: Store) => store.server.auth.user);

  if (!user || (!joinKarma && !karmaHub)) {
    return null;
  }

  if (user.isKarmaMember && karmaHub) {
    return (
      <DataLink link={karmaHub} className={styles.karma}>
        <p className={styles.textColor}>{karmaHub.title}</p>
        <p>
          <strong>{user.karmaPoints}</strong>
          {' '}
          <span className={styles.karmaPts}>{karmaHub.alt}</span>
        </p>
      </DataLink>
    );
  }

  if (!joinKarma) {
    return null;
  }

  return (
    <DataLink link={joinKarma} className={cn(styles.karma, styles.join)}>
      <p className={styles.textColor}>{joinKarma.title}</p>
    </DataLink>
  );
};

export default Karma;
