import { FC } from 'react';
import Modal from 'react-modal';
import cn from 'classnames';
import { BLOCKS } from '@contentful/rich-text-types';

import classes from 'styles/_commonClasses.module.scss';
import { CommonRichText, DataButton } from 'components/Shared/SharedComponents';
import { RichTextParsersConfig } from 'types/objectTypes';

import styles from './TalkButton/styles.module.scss';

import type { IEcQModal } from '../config/declarations';

const parsersConfig: RichTextParsersConfig = {
  [BLOCKS.PARAGRAPH]: {
    classNames: styles.errorMessage,
  },
  [BLOCKS.HEADING_2]: {
    classNames: styles.errorHeading,
  },
};

const QueueModal: FC<IEcQModal> = ({
  isOpened,
  closeModal,
  block,
}) => {
  if (!block) {
    return null;
  }

  const { content, richTitle, link } = block;

  return (
    <Modal
      isOpen={isOpened}
      className={cn(
        styles.messageModal,
        classes.modal,
        styles.queue,
      )}
      overlayClassName={classes.modalBackground}
      shouldFocusAfterRender={false}
    >
      <CommonRichText content={richTitle} parsersConfig={parsersConfig} />
      <CommonRichText content={content} parsersConfig={parsersConfig} />
      <DataButton
        className={styles.closeButton}
        onClick={() => {
          closeModal();
        }}
      >
        {link?.title}
      </DataButton>
    </Modal>
  );
};

export default QueueModal;
