import { CSSProperties, FC } from 'react';
import { useSelector } from 'react-redux';
import cn from 'classnames';

import LocalLoader from 'src/shared/ui/LocalLoader/LocalLoader';
import type { Store } from 'app-redux/types/storeTypes';
import { ItemsLayout, PsychicCardAppearance } from 'constants/enums';
import { PsychicsSetMediumSizeImagesClonePsychicsInterface as PsychicsInterface } from 'components/Sections/PsychicsSetMediumSizeImagesClone/declarations';
import styles from 'components/Sections/PsychicsSetMediumSizeImagesClone/PsychicsSetMediumSizeImages.module.scss';
import { PsychicCardSimple } from 'features/sections/PsychicCardSimple';
import { PsychicCardTile } from 'features/sections/PsychicCardTile';
import { PsychicCardList } from 'features/sections/PsychicCardList';
import { capitalizeFirstLetter } from 'lib/text.service';
import { getItemPositionForGa } from 'lib/sharedMethods.service';
import { RightPsychic } from 'types/objectTypes';

import BannerWrapper from './BannerWrapper';

const PsychicsRenderer: FC<PsychicsInterface> = ({
  array,
  banner,
  bootStatus,
  itemsLayout,
  psychicFrame,
  psychicCardAppearance,
}) => {
  const user = useSelector((store: Store) => store.server.auth.user);

  if (!array?.length) {
    return (
      <LocalLoader className={styles.loaderWrapper} />
    );
  }

  if (!psychicFrame) {
    return null;
  }

  // eslint-disable-next-line no-undef
  let components: Array<JSX.Element> | null = null;

  if (psychicCardAppearance === PsychicCardAppearance.SIMPLE_WITH_DESCRIPTION
    || psychicCardAppearance === PsychicCardAppearance.SIMPLE_ADDITIONAL
    || psychicCardAppearance === PsychicCardAppearance.SIMPLE) {
    components = array.map((psychic, i) => {
      // eslint-disable-next-line prefer-destructuring, no-param-reassign
      psychic.psychicImageUrl = psychic.images[2];

      return (
        <PsychicCardSimple
          key={psychic.extId}
          user={user}
          bootStatus={bootStatus}
          psychicSkeleton={psychicFrame}
          positionInArray={getItemPositionForGa<RightPsychic>(array, i)}
          psychic={psychic}
        />
      );
    });
  }

  if (psychicCardAppearance === PsychicCardAppearance.TILE) {
    components = array.map((psychic, i) => (
      <BannerWrapper
        key={psychic.extId}
        /* This condition doesn't have any sense but it's the
        easies way how to render banner only once */
        shouldRenderBanner={i === 1}
        banner={banner}
        itemsLayout={itemsLayout}
        psychicCardAppearance={psychicCardAppearance}
      >
        <PsychicCardTile
          bootStatus={bootStatus}
          user={user}
          psychicSkeleton={psychicFrame}
          positionInArray={getItemPositionForGa<RightPsychic>(array, i)}
          psychic={psychic}
        />
      </BannerWrapper>
    ));
  }

  if (psychicCardAppearance === PsychicCardAppearance.LIST) {
    components = array.map((psychic, i) => (
      <BannerWrapper
        key={psychic.extId}
        /* This condition doesn't have any sense but it's the
        easies way how to render banner only once */
        shouldRenderBanner={i === 1}
        banner={banner}
        itemsLayout={itemsLayout}
        psychicCardAppearance={psychicCardAppearance}
      >
        <PsychicCardList
          bootStatus={bootStatus}
          user={user}
          psychicSkeleton={psychicFrame}
          positionInArray={getItemPositionForGa<RightPsychic>(array, i)}
          psychic={psychic}
        />
      </BannerWrapper>
    ));
  }

  const getStyle = (): CSSProperties | undefined => {
    if (itemsLayout !== ItemsLayout.GRID || psychicCardAppearance === PsychicCardAppearance.LIST) {
      return;
    }

    if (psychicCardAppearance === PsychicCardAppearance.SIMPLE && array.length < 5) {
      return { gridTemplateColumns: new Array(array.length).fill('auto').join(' ') };
    }
  };

  const getItemsLayoutClass = () => {
    if (psychicCardAppearance === PsychicCardAppearance.LIST) {
      return capitalizeFirstLetter(ItemsLayout.COLUMN);
    }

    return capitalizeFirstLetter(itemsLayout);
  };
  const itemsLayoutName = getItemsLayoutClass();
  const getPsychicCardAppearanceName = () => {
    if (psychicCardAppearance === PsychicCardAppearance.SIMPLE
      || psychicCardAppearance === PsychicCardAppearance.SIMPLE_ADDITIONAL
      || psychicCardAppearance === PsychicCardAppearance.SIMPLE_WITH_DESCRIPTION) {
      return 'Simple';
    }

    if (psychicCardAppearance === PsychicCardAppearance.TILE) {
      return 'Tile';
    }

    if (psychicCardAppearance === PsychicCardAppearance.LIST) {
      return 'List';
    }
  };
  const psychicCardAppearanceName = getPsychicCardAppearanceName();

  return (
    <ul
      style={getStyle()}
      className={cn(
        styles.psychicContent,
        styles[`psychicContent${psychicCardAppearanceName}${itemsLayoutName}`],
      )}
    >
      {components}
    </ul>
  );
};

export default PsychicsRenderer;
