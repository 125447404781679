/* eslint-disable react/jsx-props-no-spreading */
import { FC } from 'react';
import { useSelector } from 'react-redux';
import cn from 'classnames';

import {
  DataButton,
  PsychicRate,
  ValidationMark,
} from 'components/Shared/SharedComponents';
import { PriceFilterSectionListProps } from 'components/Sections/PsychicsSetMediumSizeImagesClone/declarations';
import {
  CommonSize,
  CustomerType,
  GAReplacementValue,
} from 'constants/enums';
import type { Store } from 'app-redux/types/storeTypes';

import styles from '../PsychicFilter.module.scss';

const PriceFilterSectionList: FC<PriceFilterSectionListProps> = ({
  item,
  className,
  classNameSelected,
  priceTitle,
  changeFilter,
  checkIfFilterPresent,
}) => {
  const user = useSelector((store: Store) => store.server.auth.user);
  const isEc = user && !user.isLeadCustomer;
  const filterDetail = { type: 'Price', value: `${item?.psyGroup}` };
  const isSelected = checkIfFilterPresent?.(filterDetail);
  const psychicRateClassObject = {
    wrapper: styles.price,
    commonWithDiscount: styles.commonWithDiscount,
    discount: '',
    common: '',
  };

  const customerPrice = `$${item?.rate.toFixed(2)}`;
  const discountPrice = item?.newCustFlatRate ? `$${item?.newCustFlatRate.toFixed(2)}` : customerPrice;

  const gaData = {
    [GAReplacementValue.PRICE]: discountPrice,
    [GAReplacementValue.USER]: user
      ? CustomerType.EC.toUpperCase()
      : CustomerType.NC.toUpperCase(),
  };
  const sharedData = {
    gaData,
    link: priceTitle,
    onClick: () => changeFilter(filterDetail),
  };
  const rateComponent = (
    <PsychicRate
      pricePerMinute={customerPrice}
      priceWithDiscountPerMinute={isEc ? customerPrice : discountPrice}
      classNames={psychicRateClassObject}
      time={`${priceTitle?.alt}`}
    />
  );

  if (isSelected) {
    return (
      <li className={cn(className, classNameSelected)}>
        <DataButton {...sharedData}>
          <ValidationMark isValid size={CommonSize.SMALL} />
          {rateComponent}
        </DataButton>
      </li>
    );
  }

  return (
    <li className={className}>
      <DataButton {...sharedData}>
        {rateComponent}
      </DataButton>
    </li>
  );
};

export default PriceFilterSectionList;
