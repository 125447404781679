import { FC } from 'react';
import cn from 'classnames';

import NavigationButton from './NavigationButton';

import styles from '../AppointmentCalendar.module.scss';
import { useNavigationDate } from '../../lib/hooks';
import type { INavigation } from '../../config/declarations';

const Navigation: FC<INavigation> = ({
  dispatch,
  className,
  date,
  link,
  gaData,
  dateFormat = 'long',
}) => {
  const dateText = useNavigationDate(date, dateFormat);

  return (
    <div className={cn(styles.nav, className)}>
      <NavigationButton
        link={link}
        dispatch={dispatch}
        gaData={gaData}
        date={date}
        isLeft
      />
      <span className={cn(
        styles.navText,
        (dateFormat === 'long') ? styles.navTextSmall : styles.navTextMed,
      )}
      >
        {dateText}
      </span>
      <NavigationButton
        link={link}
        dispatch={dispatch}
        date={date}
        gaData={gaData}
      />
    </div>
  );
};

export default Navigation;
