import { FC } from 'react';

import variables from 'src/styles/_commonVariables.module.scss';

import { useStylesForPage } from '../lib/hooks';

const CommonStyles: FC = () => {
  const styles = useStylesForPage();

  const defaultStyles = `
          .wrap {
            min-height: 30vh;
            position: relative;
            overflow-x: hidden;
            display: flex;
            flex-direction: column;
          }

           ${styles.desktopNoSidebar}

          @media (max-width: ${variables.mobileViewMaxWidth}px) {
            .wrap {
              overflow-x: unset;
            }

            ${styles.mobileNoSidebar}
          }
    `;

  return (
    <style key={1} jsx global>
      {defaultStyles}
    </style>
  );
};

export default CommonStyles;
