import {
  FC,
  useEffect,
  useState,
} from 'react';
import cn from 'classnames';

import { useStableDateGetter } from 'src/shared/lib/date/hooks';

import TopNavigationButton from './TopNavigationButton';

import styles from '../AppointmentCalendar.module.scss';
import { useNavigationDate } from '../../lib/hooks';
import type { INavigation, TopNavigationButtonVisible } from '../../config/declarations';
import { MAX_DAYS_FOR_APPOINTMENTS } from '../../config/constants';

const TopNavigation: FC<INavigation> = ({
  dispatch,
  className,
  date,
  link,
  dateFormat = 'long',
}) => {
  const [savedDate, setSavedDate] = useState<Date>(date);
  const dateText = useNavigationDate(savedDate, dateFormat);
  const [visibleButton, setVisibleButton] = useState<TopNavigationButtonVisible | null>(null);
  const getStableDate = useStableDateGetter();

  useEffect(() => {
    const stableNow = getStableDate(new Date());
    const possibleNextMonth = new Date(stableNow.valueOf());
    possibleNextMonth.setDate(possibleNextMonth.getDate() + MAX_DAYS_FOR_APPOINTMENTS);

    if (stableNow.getMonth() === possibleNextMonth.getMonth()) {
      return setSavedDate(date);
    }

    if (date.getMonth() === stableNow.getMonth()) {
      setSavedDate(stableNow);
      setVisibleButton('right');
    }

    if (date.getMonth() > stableNow.getMonth()) {
      setSavedDate(date);
      setVisibleButton('left');
    }
  }, [date]);

  return (
    <div className={cn(styles.nav, className)}>
      <TopNavigationButton
        link={link}
        dispatch={dispatch}
        isVisible={visibleButton === 'left'}
        setVisibleButton={(value) => {
          setSavedDate(getStableDate(new Date()));
          setVisibleButton(value);
        }}
        isLeft
      />
      <span className={cn(
        styles.navText,
        (dateFormat === 'long') ? styles.navTextSmall : styles.navTextMed,
      )}
      >
        {dateText}
      </span>
      <TopNavigationButton
        link={link}
        isVisible={visibleButton === 'right'}
        setVisibleButton={(value: 'left' | 'right') => {
          const localDate = getStableDate(new Date());
          localDate.setMonth(localDate.getMonth() + 1);
          setSavedDate(localDate);
          setVisibleButton(value);
        }}
        dispatch={dispatch}
      />
    </div>
  );
};

export default TopNavigation;
