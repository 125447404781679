import React from 'react';
import { useSelector } from 'react-redux';

import HoroscopesAndPredictions from 'components/Sections/HoroscopesSignsHoroscopesAndPredictions/HoroscopesAndPredictions';
import { Store } from 'app-redux/types/storeTypes';
import { SectionComponentInterface } from 'types/componentTypes';
import { Block, HoroscopesPredictions } from 'src/__generated__/graphqlTypes';
import { getZodiacSign, sortBlocksAndLinks } from 'lib/sharedMethods.service';
import { PageWithHoroscope } from 'types/objectTypes';

const HoroscopesAndPredictionsContainer: React.FC<SectionComponentInterface> = ({
  blocks,
  bgColor,
}) => {
  const slug = useSelector((store: Store) => store.server.page.slug);
  const zodiacSign = getZodiacSign(slug);
  const viewerDevice = useSelector((store: Store) => store.server.app.viewerDevice);
  const { horoscopes } = useSelector((store: Store) => store
    .server.page.pages[slug]) as PageWithHoroscope;
  const { horoscopeTabletMaxWidth } = useSelector((store: Store) => store.server.app.scssVariables);
  const [horoscopesPredictions] = blocks;

  if (!horoscopesPredictions) {
    return null;
  }

  const {
    topDivider,
    dailyHoroscope,
    moreHoroscopes,
    topPsychicsCollection,
    freeHoroscope,
    compatibilityCheck,
    bottomDivider,
    ...rest
  } = horoscopesPredictions as HoroscopesPredictions;

  if (dailyHoroscope && !(dailyHoroscope as any).tips) {
    (dailyHoroscope as { tips: Array<string> } & Block).tips = horoscopes || [];
  }

  const topPsychics = sortBlocksAndLinks(topPsychicsCollection?.items as Array<Block>);

  return (
    <HoroscopesAndPredictions
      bgColor={bgColor}
      topDivider={topDivider}
      dailyHoroscope={dailyHoroscope}
      moreHoroscopes={moreHoroscopes}
      topPsychics={topPsychics}
      freeHoroscope={freeHoroscope}
      compatibilityCheck={compatibilityCheck}
      bottomDivider={bottomDivider}
      horoscopeTabletMaxWidth={horoscopeTabletMaxWidth}
      viewerDevice={viewerDevice}
      slug={slug}
      zodiacSign={zodiacSign}
      {...rest as HoroscopesPredictions}
    />
  );
};

export default HoroscopesAndPredictionsContainer;
