import { FC, useRef } from 'react';
import cn from 'classnames';
import { useSelector } from 'react-redux';

import { DataButton, ValidationMark } from 'components/Shared/SharedComponents';
import {
  CategoryFilterSectionProps,
  FilterListPanelInterface,
  PriceFilterSectionProps,
} from 'components/Sections/PsychicsSetMediumSizeImagesClone/declarations';
import {
  CommonSize,
  CustomerType,
  GAReplacementValue,
} from 'constants/enums';
import type { Store } from 'app-redux/types/storeTypes';
import { useClickOutsideMultiple } from 'lib/shared.hook';
import { useLoadFiltersOptions } from 'components/Sections/PsychicsSetMediumSizeImagesClone/lib/hooks';
import LocalLoader from 'src/shared/ui/LocalLoader/LocalLoader';
import { capitalizeFirstLetter } from 'lib/text.service';

import PriceFilterSectionList from './FilterPriceItem';
import FilterActionButtons from './FilterActionButtons';

import styles from '../PsychicFilter.module.scss';

const PriceFilterSection: FC<PriceFilterSectionProps> = ({
  priceTitle,
  priceOptions,
  changeFilter,
  checkIfFilterPresent,
}) => (
  <div className={styles.category}>
    <div className={styles.categoryHeader}>Price</div>
    {changeFilter && priceOptions && priceOptions.map((item) => (
      <PriceFilterSectionList
        key={item.rate}
        className={styles.categoryItem}
        classNameSelected={styles.selected}
        changeFilter={changeFilter}
        checkIfFilterPresent={checkIfFilterPresent}
        item={item}
        priceTitle={priceTitle}
      />
    ))}
  </div>
);

const CategoryFilterSection: FC<CategoryFilterSectionProps> = ({
  filtersByCategories,
  doneButton,
  clearFilterButton,
  totalResultsLocal,
  viewTitle,
  resultsTitle,
  isFetchingFilteredCount,
  doneFilter,
  changeFilter,
  removeFilter,
  checkIfFilterPresent,
}) => {
  const user = useSelector((store: Store) => store.server.auth.user);

  if (!filtersByCategories?.length) {
    return null;
  }

  return (
    <>
      {filtersByCategories.map((category, index) => (
        <div
          className={styles.category}
          key={category.majorCategoryDescription}
        >
          <span className={styles.categoryHeader}>
            {category.richText}
          </span>
          {category.descriptions.map((categoryDesc) => {
            const modifiedText = categoryDesc.includes('/')
              ? categoryDesc
                .split('/')
                .map((str: string) => str
                  .split(' ')
                  .map(capitalizeFirstLetter)
                  .join(' '))
                .join(' & ')
              : categoryDesc
                .split(' ')
                .map(capitalizeFirstLetter)
                .join(' ');
            const filterDetail = {
              type: category.majorCategoryDescription,
              value: categoryDesc,
            };
            const isSelected = checkIfFilterPresent(filterDetail);
            const majorCategoryGa = category.majorCategoryDescription;
            const gaData: Partial<Record<GAReplacementValue, string>> = {
              [GAReplacementValue.MAJOR_CATEGORY]: majorCategoryGa,
              [GAReplacementValue.CATEGORY]: modifiedText,
              [GAReplacementValue.USER]: user
                ? CustomerType.EC.toUpperCase()
                : CustomerType.NC.toUpperCase(),
            };
            const sharedButtonProps = {
              key: modifiedText,
              link: resultsTitle,
              onClick: () => changeFilter(filterDetail),
              gaData,
            };

            if (isSelected) {
              return (
                <li key={modifiedText} className={cn(styles.categoryItem, styles.selected)}>
                  <DataButton {...sharedButtonProps}>
                    <ValidationMark isValid size={CommonSize.SMALL} />
                    {modifiedText}
                  </DataButton>
                </li>
              );
            }

            return (
              <li key={modifiedText} className={styles.categoryItem}>
                <DataButton {...sharedButtonProps}>
                  {modifiedText}
                </DataButton>
              </li>
            );
          })}
          {filtersByCategories.length - 1 === index && (
            <FilterActionButtons
              doneButton={doneButton}
              disabledFilterButton={!totalResultsLocal || isFetchingFilteredCount}
              doneButtonText={`${viewTitle?.title} ${totalResultsLocal} ${resultsTitle?.title}`}
              clearButton={clearFilterButton}
              clearFilters={removeFilter}
              updateFilters={doneFilter}
              styles={styles}
            />
          )}
        </div>
      ))}
    </>
  );
};

const FilterListPanel: FC<FilterListPanelInterface> = ({
  totalResultsLocal,
  doneButton,
  clearFilterButton,
  showFilters,
  priceTitle,
  viewTitle,
  resultsTitle,
  filterByTitleRef,
  isFetchingFilteredCount,
  changeFilter,
  checkIfFilterPresent,
  doneFilter,
  removeFilter,
  closeFilter,
}) => {
  const filterPanelRef = useRef<HTMLDivElement>(null);
  const { options, isLoading } = useLoadFiltersOptions(Boolean(showFilters));
  useClickOutsideMultiple([filterByTitleRef, filterPanelRef], () => closeFilter?.(), false);

  if (!showFilters) {
    return null;
  }

  if (isLoading) {
    return (
      <div
        ref={filterPanelRef}
        className={cn(styles.panel, styles.panelLoading)}
      >
        <LocalLoader />
      </div>
    );
  }

  return (
    <div ref={filterPanelRef} className={styles.panel}>
      <PriceFilterSection
        priceOptions={options.price}
        changeFilter={changeFilter}
        checkIfFilterPresent={checkIfFilterPresent}
        priceTitle={priceTitle}
      />
      {changeFilter && options.category && (
        <CategoryFilterSection
          filtersByCategories={options.category}
          changeFilter={changeFilter}
          checkIfFilterPresent={checkIfFilterPresent}
          doneButton={doneButton}
          clearFilterButton={clearFilterButton}
          doneFilter={doneFilter}
          removeFilter={removeFilter}
          totalResultsLocal={totalResultsLocal}
          viewTitle={viewTitle}
          resultsTitle={resultsTitle}
          isFetchingFilteredCount={isFetchingFilteredCount}
        />
      )}
    </div>
  );
};

export default FilterListPanel;
