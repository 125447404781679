import { Block, Link } from 'src/__generated__/graphqlTypes';
import { OptionalBgColorInterface } from 'types/componentTypes';

export interface DualColuredInterface extends OptionalBgColorInterface {
  mainBlock: Block;
  secondaryBlock: Block;
}

export interface MainInterface {
  mainBlock: Block;
}

export interface SecondaryInterface {
  title?: Block['richTitle'];
  content?: Block['content'];
  id: Block['blockId'];
  link: Link;
}

export enum DualContainerSlug {
  MAIN ='dual-main',
  SECONDARY ='dual-secondary',
}
