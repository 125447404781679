import { FC } from 'react';
import cn from 'classnames';

import { DataButton } from 'components/Shared/SharedComponents';

import styles from './PlainCalendar.module.scss';

import { getCellFlags } from '../lib';
import type { ITBodyCell } from '../config/declarations';

const TBodyCell: FC<ITBodyCell> = ({
  now,
  cell,
  selected,
  dateButton,
  dateHandler,
  onCellSelect,
}) => {
  const { isCurrent, isTooEarly, isYesterdays } = getCellFlags(now, cell);
  const isSelected = selected?.getTime() === cell.getTime() && !isTooEarly;

  return (
    <td
      key={cell.getTime()}
      className={cn(
        styles.td,
        {
          [dateHandler?.className || '']: !isYesterdays,
          [styles.current]: isCurrent && !isSelected && !dateHandler?.className,
          [styles.tooEarly]: isTooEarly,
        },
      )}
    >
      <DataButton
        link={dateButton}
        disabled={Boolean(isYesterdays)}
        // @ts-ignore
        gaData={{ action: cell.getDate(), category: 'calendar' }}
        onClick={() => {
          if (isTooEarly) {
            return;
          }

          return onCellSelect(cell);
        }}
      >
        {cell.getDate()}
      </DataButton>
    </td>
  );
};

export default TBodyCell;
