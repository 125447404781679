import { FC } from 'react';
import cn from 'classnames';

import { getStatus } from 'lib/psychic.service';
import { PsychicStatusIndicator, PsychicStatusText } from 'entities/PsychicStatus';
import { RatePerMinuetType } from 'types/objectTypes';
import { PsychicRate } from 'components/Shared/SharedComponents';
import { PsychicRatingLine } from 'entities/PsychicRatingLine';
import { PsychicClickableName } from 'entities/PsychicClickableName';
import { Status } from 'constants/enums';

import styles from './PsychicCardList.module.scss';

import { IPriceComponent, PsychicDescriptionInfoInterface } from '../config/declarations';

/* Nested Component */
const PriceComponent: FC<IPriceComponent> = ({ psychic, ratePerMinuteText }) => {
  const priceStyles = {
    wrapper: styles.price,
    common: styles.priceCommon,
    commonWithDiscount: styles.priceCrossed,
    discount: styles.priceDiscount,
  };

  const ratePerMinute: RatePerMinuetType = {
    discount: psychic.customerPrice,
    price: psychic.basePrice,
  };

  const minText = `/${ratePerMinuteText}`;

  return (
    <PsychicRate
      pricePerMinute={ratePerMinute.price}
      priceWithDiscountPerMinute={ratePerMinute.discount}
      classNames={priceStyles}
      time={minText}
    />
  );
};

/* Main component */
const DescriptionInfo: FC<PsychicDescriptionInfoInterface> = ({
  psychic,
  psychicFrame,
  positionInArray,
}) => {
  const status = getStatus({
    chatStatus: psychic.chatStatus,
    phoneStatus: psychic.phoneStatus,
  });

  return (
    <div className={styles.info}>
      <PsychicClickableName
        psychic={psychic}
        psychicFrame={psychicFrame}
        positionInArray={positionInArray}
      />
      <div className={styles.priceStatus}>
        <PriceComponent
          psychic={psychic}
          ratePerMinuteText={psychicFrame.ratePerMinute!}
        />
        <div className={cn(
          styles.status,
          { [styles.fullWidth]: status === Status.ON_BREAK },
        )}
        >
          <PsychicStatusIndicator
            status={status}
            className={styles.statusIndicator}
          />
          <PsychicStatusText
            status={status}
            psychic={psychic}
            psychicFrame={psychicFrame}
            className={styles.statusText}
            smallTextClass={styles.small}
            wrapperClass={styles.statusTextCombined}
          />
        </div>
        <PsychicRatingLine
          overallScore={psychic.overallScore}
          psychicFrame={psychicFrame}
          responses={psychic.responses}
          styles={styles}
        />
      </div>
    </div>
  );
};

export default DescriptionInfo;
