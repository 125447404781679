import { useSelector } from 'react-redux';
import cn from 'classnames';
import capitalize from 'lodash/capitalize';

import type { ImageWrapper } from 'src/__generated__/graphqlTypes';
import type { Store } from 'app-redux/types/storeTypes';
import { ElementAlign, ViewerDevice } from 'constants/enums';
import type { SectionExtraDataType } from 'types/objectTypes';
import { setItemInObjectBySlug } from 'lib/sharedMethods.service';

import styles from '../ui/TextAndSideAsset.module.scss';
import { imageSlug } from '../config/objects';
import type { ImageStylesHookProps } from '../config/declarations';

import { searchImagePlaceCallback } from '.';

export const useAssetAlign = (viewerDevice: ViewerDevice, extraData?: SectionExtraDataType) => {
  if (!extraData) {
    return ElementAlign.LEFT;
  }

  const {
    assetAlign,
    assetAlignMobile,
    imageAttachmentMobile,
  } = extraData;

  const isMobileOrTablet = viewerDevice === ViewerDevice.TABLET
    || viewerDevice === ViewerDevice.MOBILE;

  /* It will change mobile/tablet view to show image at right place */
  if (isMobileOrTablet) {
    if (!assetAlignMobile) {
      const attachmentPreferredPlace = imageAttachmentMobile?.find(searchImagePlaceCallback);

      if (attachmentPreferredPlace === 'bottom') {
        return ElementAlign.RIGHT;
      }

      if (attachmentPreferredPlace === 'top') {
        return ElementAlign.LEFT;
      }
    } else {
      if (assetAlignMobile === 'bottom') {
        return ElementAlign.RIGHT;
      }

      if (assetAlignMobile === 'top') {
        return ElementAlign.LEFT;
      }
    }
  }

  return assetAlign || ElementAlign.LEFT;
};

export const useImageStyles = ({
  isImages,
  extraData,
  viewerDevice,
}: ImageStylesHookProps) => {
  const assetAlign = useAssetAlign(viewerDevice, extraData);
  const alignmentClasses = {
    [styles[`${assetAlign}Asset`]]: !isImages,
    [styles[`${assetAlign}AssetImage`]]: true,
  };

  const {
    imageAttachment,
    imageAttachmentMobile,
  } = extraData || {};

  if (viewerDevice === ViewerDevice.DESKTOP) {
    const classes = imageAttachment
      ?.map((position) => styles[`negativeImageMargin${capitalize(position)}`]) || [];
    const pictureClasses = imageAttachment
      ?.map((position) => styles[`negativeImageMargin${capitalize(position)}Picture`]) || [];

    return {
      img: cn(
        ...((classes?.length)
          ? [...classes, styles.imageAttached]
          : [alignmentClasses]),
      ),
      pic: cn(...pictureClasses || ''),
    };
  }

  const classes = imageAttachmentMobile
    ?.map((position) => styles[`negativeImageMargin${capitalize(position)}`]) || [];
  const pictureClasses = imageAttachmentMobile
    ?.map((position) => styles[`negativeImageMargin${capitalize(position)}Picture`]) || [];

  return {
    img: cn(
      alignmentClasses,
      ...((classes?.length)
        ? [...classes, styles.imageAttached]
        : []),
    ),
    pic: cn(...pictureClasses || ''),
  };
};

export const useAttachedImageHeight = (
  images: Array<ImageWrapper>,
  viewerDevice: ViewerDevice,
  extraData?: SectionExtraDataType,
) => {
  if (!images?.length) {
    return null;
  }

  const { imageAttachment, imageAttachmentMobile } = extraData || {};
  const reducer = setItemInObjectBySlug(imageSlug);
  const sortedImages = images.reduce(reducer, {} as Record<ViewerDevice, ImageWrapper>);

  if (viewerDevice === ViewerDevice.DESKTOP && imageAttachment?.length) {
    const isAttachedHorizontally = Boolean(imageAttachment.find(searchImagePlaceCallback));

    if (!isAttachedHorizontally) {
      return null;
    }

    const image = sortedImages[ViewerDevice.DESKTOP];

    return image?.height || null;
  }

  const isSmallScreen = viewerDevice === ViewerDevice.MOBILE;
  const isMediumScreen = viewerDevice === ViewerDevice.TABLET;

  if ((isSmallScreen || isMediumScreen) && imageAttachmentMobile?.length) {
    const isAttachedHorizontally = Boolean(imageAttachmentMobile.find(searchImagePlaceCallback));

    if (!isAttachedHorizontally) {
      return null;
    }

    if (isMediumScreen) {
      const image = sortedImages[ViewerDevice.TABLET] || sortedImages[ViewerDevice.MOBILE];

      return image?.height || null;
    }

    const image = sortedImages[ViewerDevice.MOBILE] || sortedImages[ViewerDevice.TABLET];

    return image?.height || null;
  }

  return null;
};

export const useCurrentWidth = () => {
  const clientWidth = useSelector((store: Store) => store.client.app.clientWidth);
  const { mobileViewMaxWidth } = useSelector((store: Store) => store.server.app.scssVariables);
  const middleScreen = +styles.mediumScreenWidth;
  const smallScreen = +mobileViewMaxWidth;

  if (clientWidth < smallScreen) {
    return ViewerDevice.MOBILE;
  }

  if (clientWidth < middleScreen && clientWidth >= smallScreen) {
    return ViewerDevice.TABLET;
  }

  return ViewerDevice.DESKTOP;
};

export const useSectionClasses = (
  attachedImageHeight: number | null,
  extraData?: SectionExtraDataType,
) => {
  if (!extraData) {
    return {};
  }

  const {
    mergeTop = false,
    mergeBottom = false,

  } = extraData;

  return ({
    [styles.mergeNo]: !mergeTop && !mergeBottom,
    [styles.mergeBottom]: !mergeTop && mergeBottom,
    [styles.mergeTop]: mergeTop && !mergeBottom,
    [styles.mergeAll]: mergeTop && mergeBottom,
    [styles.paddingCommon]: !attachedImageHeight && (!mergeTop || !mergeBottom),
    [styles.paddingAuto]: attachedImageHeight && (!mergeTop || !mergeBottom),
  });
};
