import {
  forwardRef,
  useEffect,
  useState,
  RefObject,
  SyntheticEvent,
  useRef,
  memo,
} from 'react';
import cn from 'classnames';

import classes from 'src/styles/_commonClasses.module.scss';
import { LazyWebpImage, WebpImage } from 'components/Shared/SharedComponents';

import { ImageInterface } from '../config/declarations';
import { setDefaultImgOnError } from '../lib';

const Image = forwardRef<HTMLImageElement, ImageInterface>(({
  psychic,
  isLazy = true,
  ...rest
}, ref) => {
  const localRef = useRef<HTMLImageElement>(null);
  const imageRef = (ref || localRef) as RefObject<HTMLImageElement>;
  const [isImageLoaded, setImageLoadedState] = useState<boolean>(false);

  const setImageLoadingStateLocal = (e: SyntheticEvent<HTMLImageElement, Event>) => {
    rest.onLoad?.(e);
    setImageLoadedState(true);
  };

  const handleImgLoadingError = (e: SyntheticEvent<HTMLImageElement, Event>) => {
    rest.onError?.(e);
    setDefaultImgOnError(psychic, imageRef);
  };

  useEffect(() => {
    if (imageRef?.current?.complete) {
      setImageLoadedState(true);
    }
  }, [imageRef]);

  const ImageComponent = isLazy ? LazyWebpImage : WebpImage;

  return (
    <ImageComponent
      {...rest}
      className={cn(
        rest.className,
        { [classes.loadImagesAnimation]: !isImageLoaded },
      )}
      ref={imageRef}
      onLoad={setImageLoadingStateLocal}
      onError={handleImgLoadingError}
    />
  );
});

export default memo(Image);
