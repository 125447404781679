import { FC } from 'react';

import { DataLink } from 'components/Shared/SharedComponents';
import { Tick } from 'src/shared/ui/Tick';
import { addCommaForThousands } from 'lib/text.service';

import styles from './PsychicCardTile.module.scss';

import type { ISecondCard } from '../config/declarations';
import { useProfileLink } from '../lib/hooks';
import { getCardClickHandler } from '../lib';

const SecondCard: FC<ISecondCard> = ({
  psychic,
  psychicFrame,
  positionInArray,
  setCardStep,
}) => {
  const {
    profileLink,
    gaData,
    profileUrl,
  } = useProfileLink(psychicFrame, psychic, positionInArray);
  const specialties = psychic.specialities?.filter(Boolean).slice(0, 3) || [];

  return (
    <div
      role="presentation"
      onClick={getCardClickHandler(() => setCardStep('first'))}
      className={styles.secondaryInfo}
    >
      <p className={styles.readings}>
        {`${psychicFrame.totalReadings}: ${addCommaForThousands(psychic.totalReadings || 0)}`}
      </p>
      <p className={styles.since}>
        {`${psychicFrame.since} ${psychic.serviceStartYear}`}
      </p>
      {specialties.length > 0 && (
        <ul className={styles.specialties}>
          {specialties.map((spec) => <li key={spec} className={styles.item}>{spec}</li>)}
        </ul>
      )}
      {profileLink && (
        <DataLink
          link={profileLink}
          href={profileUrl}
          className={styles.profileLink}
          gaData={gaData}
        >
          {profileLink?.title}
          <Tick
            sideSize={8}
            color="#F18536"
            direction="right"
            className={styles.tick}
          />
        </DataLink>
      )}
    </div>
  );
};

export default SecondCard;
