import React from 'react';
import { useSelector } from 'react-redux';

import { Store } from 'app-redux/types/storeTypes';
import { HoroscopesAndPredictionsChildInterface } from 'types/componentTypes';
import { MoreHoroscopes } from 'components/Sections/HoroscopesSignsHoroscopesAndPredictions/MoreHoroscopes';

const MoreHoroscopesContainer: React.FC<HoroscopesAndPredictionsChildInterface> = ({
  block: moreHoroscopes,
}) => {
  const { mobileViewMaxWidth } = useSelector((store: Store) => store.server.app.scssVariables);
  const isMobileViewWidth = useSelector((store: Store) => store.server.app.isMobileViewWidth);

  return (
    <MoreHoroscopes
      moreHoroscopes={moreHoroscopes}
      mobileViewMaxWidth={+mobileViewMaxWidth}
      isMobileViewWidth={isMobileViewWidth}
    />
  );
};

export default MoreHoroscopesContainer;
