import { FC } from 'react';

import { ItemsLayout } from 'constants/enums';
import { ProfileViewInterface } from 'components/Sections/PsychicsSetMediumSizeImagesClone/declarations';
import { useCommonDataButtonsProps } from 'lib/shared.hook';
import CardsViewButton from 'src/shared/ui/CardsViewButton/CardsViewButton';

import styles from '../PsychicFilter.module.scss';

const ProfileView: FC<ProfileViewInterface> = ({
  gaData,
  gridViewLink,
  listViewLink,
  selectedLayout,
  displayGridView,
  displayListView,
}) => {
  const {
    parsedGaProperties: parsedGaPropertiesGrid,
  } = useCommonDataButtonsProps(gaData, gridViewLink);
  const {
    parsedGaProperties: parsedGaPropertiesList,
  } = useCommonDataButtonsProps(gaData, listViewLink);

  return (
    <div className={styles.profileView}>
      <CardsViewButton
        shape="square"
        isSelected={selectedLayout === ItemsLayout.GRID}
        onClick={displayGridView}
        gaData={parsedGaPropertiesGrid.gaData}
      />
      <CardsViewButton
        shape="rectangle"
        isSelected={selectedLayout === ItemsLayout.COLUMN}
        onClick={displayListView}
        gaData={parsedGaPropertiesList.gaData}
      />
    </div>
  );
};

export default ProfileView;
