import { useDispatch, useSelector } from 'react-redux';
import {
  Dispatch,
  useRef,
  useState,
  SetStateAction,
} from 'react';
import { useRouter } from 'next/router';

import { Logger } from 'lib/logger';
import { setLoadingState } from 'app-redux/actions/appActions';
import { addToFavoriteProxy, removeFromFavorite } from 'src/api/customerApi';
import { HeartButtonObjectType, RightPsychic } from 'types/objectTypes';
import { Store } from 'app-redux/types/storeTypes';
import { FAVORITE_PSYCHIC_HINT_HIDING, MY_FAVORITE_PSYCHICS } from 'constants/constants';
import { usePsychicsContext, usePsychicsDispatch } from 'entities/PsychicListContext';

const getHintTimeout = (setAddToFavoriteHintState: Dispatch<SetStateAction<boolean>>) => setTimeout(
  () => setAddToFavoriteHintState(false),
  FAVORITE_PSYCHIC_HINT_HIDING,
);

export const useHeartImageData = (psychic: RightPsychic): HeartButtonObjectType => {
  const [isAddToFavoriteHintActive, setAddToFavoriteHintState] = useState<boolean>(false);
  const timeoutRef = useRef<any>(null);
  const user = useSelector((store: Store) => store.server.auth.user);
  const isAuthenticated = useSelector((store: Store) => store.server.auth.isAuthenticated);
  const router = useRouter();
  const slug = useSelector((store: Store) => store.server.page.slug);
  const psychicsDispatch = usePsychicsDispatch();
  const { psychics } = usePsychicsContext();

  const dispatch = useDispatch();
  const setLoadingStateStore = (state: boolean) => dispatch(setLoadingState(state));
  const { extId, isFavorite } = psychic;

  const sendRequestToToggleFavoritePsychic = async () => {
    try {
      setLoadingStateStore(true);
      const psychicId = extId.toString();
      const { custId = '', authToken } = user || {};

      const response = isFavorite
        ? await removeFromFavorite(custId, psychicId, authToken)
        : await addToFavoriteProxy(custId, psychicId);

      return response;
    } catch (e) {
      Logger.error(e);
    } finally {
      setLoadingStateStore(false);
    }

    return {};
  };

  const onHeartIconClick = async () => {
    const isEcWantRemoveFromFavorites = !isAuthenticated && user && isFavorite;

    if (isEcWantRemoveFromFavorites) {
      return router.replace('/sign-in');
    }

    const isNc = !isAuthenticated && !user;

    if (isNc) {
      setAddToFavoriteHintState(true);
      timeoutRef.current = getHintTimeout(setAddToFavoriteHintState);

      if (isAddToFavoriteHintActive) {
        setAddToFavoriteHintState(false);
      }
    } else {
      if (isAddToFavoriteHintActive) {
        setAddToFavoriteHintState(false);
        clearTimeout(timeoutRef.current);
      }

      const response = await sendRequestToToggleFavoritePsychic();

      if (response.isSuccess) {
      // eslint-disable-next-line no-param-reassign
        psychic.isFavorite = !isFavorite;
        setAddToFavoriteHintState(true);
        clearTimeout(timeoutRef.current);
        timeoutRef.current = getHintTimeout(setAddToFavoriteHintState);

        if (slug === MY_FAVORITE_PSYCHICS && isFavorite) {
          psychicsDispatch({
            type: 'psychics',
            payload: psychics.filter((currentPsychic) => currentPsychic.extId
            !== psychic.extId),
          });
        }
      }
    }
  };

  return { onClick: onHeartIconClick, isAddToFavoriteHintActive, isAuthenticated, isFavorite };
};
