import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
} from 'react';

import { useCustomRouter } from 'src/shared/lib/history/hooks';
import { getDaysInMonth } from 'lib/date.service';
import { useStableDateGetter } from 'src/shared/lib/date/hooks';

import { CELLS_AMOUNT, WEEKDAYS_AMOUNT } from '../config/constants';
import type { CalendarState, DispatchType } from '../config/declarations';

import {
  checkIsYesterdays,
  compareDates,
  getDatesFromMonthEnd,
  getDatesFromMonthStart,
  getDayByIndex,
} from '.';

export const useWeekdays = () => {
  const monday = new Date(2024, 7, 12);
  const getStableDate = useStableDateGetter();
  const date = getStableDate(monday);
  const router = useCustomRouter();
  const weekdays: Array<string> = [];

  for (let i = 0; i < 7; i++) {
    weekdays.push(date.toLocaleDateString(router.locale, { weekday: 'narrow' }));
    date.setDate(date.getDate() + 1);
  }

  return weekdays;
};

export const useDatesArray = (selected: Date | null) => {
  const currentDate = selected || new Date();
  const getStableDate = useStableDateGetter();
  const stableDate = getStableDate(new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    currentDate.getDate(),
  ));
  const daysAmount = getDaysInMonth(stableDate);
  const firstDay = getDayByIndex(stableDate);
  const pendingDates = CELLS_AMOUNT - daysAmount;

  const pendingDatesStart = (firstDay.getDay() === 0)
    ? WEEKDAYS_AMOUNT
    : firstDay.getDay();

  const pendingDatesEnd = pendingDates - pendingDatesStart;

  const startDates = getDatesFromMonthEnd(
    stableDate.getMonth() - 1,
    pendingDatesStart,
    stableDate,
  );
  const endDates = getDatesFromMonthStart(
    stableDate.getMonth() + 1,
    pendingDatesEnd,
    stableDate,
  );
  const currentMonthDates: Array<Date> = [];

  for (let i = 1; i <= daysAmount; i++) {
    const day = new Date(
      stableDate.getFullYear(),
      stableDate.getMonth(),
      i,
    );
    currentMonthDates.push(day);
  }

  const dates = startDates
    .concat(currentMonthDates)
    .concat(endDates);

  return dates;
};

export const useOutsideStateControl = (
  dates: Array<Date>,
  selected: Date | null,
  setSelected: Dispatch<SetStateAction<Date | null>>,
  setPseudoSelected: Dispatch<SetStateAction<Date | null>>,
  getState?: (state: CalendarState) => void,
) => {
  const getStableDate = useStableDateGetter();
  const stableDateNow = getStableDate(new Date());

  const dispatch = useCallback((value: DispatchType) => {
    if (!value?.action) {
      return;
    }

    if (!selected) {
      const currentDate = dates.find((date) => compareDates(date, stableDateNow));

      if (!currentDate) {
        return;
      }

      return setSelected(currentDate);
    }

    switch (value.action) {
      case 'next': {
        const currentIndex = dates.findIndex((date) => compareDates(date, selected));

        if (currentIndex === CELLS_AMOUNT - 1) {
          const newDate = new Date(
            selected.getFullYear(),
            selected.getMonth(),
            selected.getDate() + 1,
          );

          return setSelected(newDate);
        }

        const date = dates[currentIndex + 1];

        return setSelected(date);
      }
      case 'prev': {
        const currentIndex = dates.findIndex((date) => compareDates(date, selected));

        if (currentIndex === 0) {
          const newDate = new Date(
            selected.getFullYear(),
            selected.getMonth(),
            selected.getDate() - 1,
          );

          return setSelected(newDate);
        }

        const date = dates[currentIndex - 1];
        const isYesterdays = checkIsYesterdays(stableDateNow, date);

        if (isYesterdays) {
          return;
        }

        return setSelected(date);
      }
      case 'next-month': {
        const newDate = new Date(stableDateNow.valueOf());
        newDate.setMonth(stableDateNow.getMonth() + 1);

        return setPseudoSelected(newDate);
      }
      case 'prev-month': {
        return setPseudoSelected(stableDateNow);
      }
      case 'index': {
        if (value.payload! < 0 || value.payload! > CELLS_AMOUNT - 1) {
          return;
        }

        const date = dates[value.payload!];
        const isYesterdays = checkIsYesterdays(stableDateNow, date);

        if (isYesterdays) {
          return;
        }

        return setSelected(dates[value.payload!]);
      }
      default: {
        break;
      }
    }
  }, [selected]);

  useEffect(() => {
    if (!getState) {
      return;
    }

    getState({
      dispatch,
      selected,
    });
  }, [getState, selected]);
};
