import { FC } from 'react';

import { useGridTemplates, useSidebarScreenSpace } from 'src/shared/lib/sidebar/hooks';
import variables from 'src/styles/_commonVariables.module.scss';

import { useStylesForPage } from '../lib/hooks';
import type { IGlobalStyles } from '../config/declarations';

const SidebarStyles: FC<IGlobalStyles> = ({ sidebar }) => {
  const styles = useStylesForPage();
  const asideScreenSpace = useSidebarScreenSpace(sidebar);
  const templates = useGridTemplates(sidebar, asideScreenSpace);
  const classes = `
          .wrap {
            margin: 0 auto;
            min-height: 100vh;
            position: relative;
            overflow-x: hidden;
            display: grid;
            column-gap: 20px;
            grid-template-areas: 
              "${templates!.area}";
            grid-template-columns: ${templates!.columns};
          }

          ${styles.desktop}

          @media (max-width: ${variables.mobileViewMaxWidth}px) {
            .wrap {
              overflow-x: unset;
            }

            ${styles.mobile}
          }
    `;

  return (
    <style key={1} jsx global>
      {classes}
    </style>
  );
};

export default SidebarStyles;
