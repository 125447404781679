import {
  CSSProperties,
  FC,
  useReducer,
} from 'react';
import { useSelector } from 'react-redux';
import cn from 'classnames';

import type { Store } from 'app-redux/types/storeTypes';
import { HeaderGreetingMobile } from 'entities/HeaderGreeting';
import { HeaderNavInteraction } from 'features/HeaderNavInteraction';

import HeaderNav from './HeaderNav';
import styles from './styles.module.scss';
import CloseHamburger from './CloseHamburger';

import type { IHamburger } from '../config/declarations';
import {
  MobileHeaderNavContext,
  MobileHeaderNavDispatch,
  initialContext,
} from '../lib/context';
import { contextReducer } from '../lib/reducers';
import { useClosingSubMenuOnNavigation } from '../lib/hooks';

const Hamburger: FC<IHamburger> = ({ auth, rating, nav, mobileApp }) => {
  const [store, dispatch] = useReducer(
    contextReducer,
    initialContext,
  );
  const isMobileHeaderNavActive = useSelector((store: Store) => store
    .server.app.isMobileHeaderNavActive);

  const contentStyle: CSSProperties = {
    height: store.secondLevelMenuHeight,
    ...(store.secondLevelMenuHeight)
      ? { overflow: 'hidden' }
      : {},
  };

  useClosingSubMenuOnNavigation(dispatch, isMobileHeaderNavActive);

  return (
    <MobileHeaderNavContext.Provider value={store}>
      <MobileHeaderNavDispatch.Provider value={dispatch}>
        <nav className={cn(
          styles.wrapper,
          isMobileHeaderNavActive ? styles.visible : styles.hidden,
        )}
        >
          <div className={styles.menu}>
            {!store.isSubMenuVisible && <CloseHamburger />}
            <div className={styles.menuContent} style={contentStyle}>
              <HeaderGreetingMobile greeting={auth?.greet} />
              <HeaderNav nav={nav} />
            </div>
          </div>
        </nav>
        <HeaderNavInteraction
          mobileApp={mobileApp}
          auth={auth}
          rating={rating}
        />
      </MobileHeaderNavDispatch.Provider>
    </MobileHeaderNavContext.Provider>
  );
};

export default Hamburger;
