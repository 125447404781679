import { FC } from 'react';
import cn from 'classnames';
import { BLOCKS } from '@contentful/rich-text-types';

import { FullBackgroundImage } from 'src/shared/ui/FullBackgroundImage';
import { CommonRichText, DataLink } from 'components/Shared/SharedComponents';
import { ImageWrapper } from 'src/__generated__/graphqlTypes';
import { RichTextInterface } from 'components/Shared/declarations';
import { RichTextParsersConfig } from 'types/objectTypes';

import styles from './styles.module.scss';

import type { IBanner, IButton } from '../config/declarations';

const getParsersConfig = (): RichTextParsersConfig => ({
  [BLOCKS.HEADING_2]: {
    classNames: cn(styles.title, styles.titleH2),
  },
  [BLOCKS.HEADING_3]: {
    classNames: cn(styles.title, styles.titleH3),
  },
});

/* Nested component */
const Button: FC<IButton> = ({ link }) => {
  if (!link?.src) {
    return null;
  }

  return (
    <DataLink
      link={link}
      className={styles.bannerLink}
    >
      {link.title}
    </DataLink>
  );
};

const Content: FC<RichTextInterface> = ({ richText, config }) => {
  if (!richText) {
    return null;
  }

  return (
    <CommonRichText
      content={richText}
      parsersConfig={config}
    />
  );
};

/* Main Component */
const Banner: FC<IBanner> = ({ link, title, className, images, content }) => (
  <div className={cn(styles.banner, className)}>
    <div className={styles.bannerWrapper}>
      {title && <span className={styles.bannerText}>{title}</span>}
      <Content richText={content} config={getParsersConfig()} />
      <Button link={link} />
    </div>
    <FullBackgroundImage
      skipTablet
      backgrounds={images?.items as Array<ImageWrapper>}
      className={styles.bannerBackground}
    />
  </div>
);

export default Banner;
