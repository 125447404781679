import { FC } from 'react';
import cn from 'classnames';

import { addCommaForThousands } from 'lib/text.service';

import styles from './PsychicCardList.module.scss';

import { PsychicWorkDescriptionInterface, PsychicWorkDescriptionLiInterface as LiWithArrayInterface } from '../config/declarations';

/* Nested component */
const LiWithArray: FC<LiWithArrayInterface> = ({ array, topic }) => {
  const joinedArray = (Array.isArray(array))
    ? array?.join(', ')
    : array?.split(',')?.join(', ');

  return (
    <li className={styles.item}>
      <strong className={styles.topic}>{`${topic}:`}</strong>
      {' '}
      <span
        title={joinedArray}
        className={styles.description}
      >
        {joinedArray || '-'}
      </span>
    </li>
  );
};

/* Main component */
const PsychicWorkDescription: FC<PsychicWorkDescriptionInterface> = ({
  topics,
  abilities,
  tools,
  totalReadings,
  startYear,
  contentfulData,
}) => (
  <ul className={styles.workDescription}>
    <li className={styles.item}>
      <strong className={cn(styles.topic, styles.block)}>{`${contentfulData.totalReadings}:`}</strong>
      {' '}
      <span className={styles.description}>
        {addCommaForThousands(totalReadings)}
        {' '}
        -
        {' '}
        {contentfulData.since}
        {' '}
        {startYear}
      </span>
    </li>
    <LiWithArray
      topic={contentfulData.abilities}
      array={abilities}
    />
    <LiWithArray
      topic={contentfulData.topics}
      array={topics}
    />
    <LiWithArray
      topic={contentfulData.tools}
      array={tools}
    />
  </ul>
);

export default PsychicWorkDescription;
