import { FC } from 'react';
import cn from 'classnames';

import { DataButton } from 'components/Shared/SharedComponents';

import styles from '../AppointmentCalendar.module.scss';
import type { ITopNavigationButton } from '../../config/declarations';

const TopNavigationButton: FC<ITopNavigationButton> = ({
  link,
  isVisible,
  isLeft = false,
  setVisibleButton,
  dispatch,
}) => {
  const specificClassName = styles[`arrow${isLeft
    ? 'Left'
    : 'Right'
  }`];
  const action = isLeft
    ? 'prev-month'
    : 'next-month';

  return (
    <DataButton
      link={link}
      className={styles.navButton}
      disabled={!isVisible}
      onClick={() => {
        setVisibleButton?.(isLeft ? 'right' : 'left');

        return dispatch({ action });
      }}
    >
      <i className={cn(styles.arrow, specificClassName)} />
    </DataButton>
  );
};

export default TopNavigationButton;
