import { FC } from 'react';

import { Link } from 'src/__generated__/graphqlTypes';

import Button from './Button';
import MessageButton from './MessageButton';
import { ChatButton } from './ChatButton';
import TalkButton from './TalkButton/TalkButton';

import { ButtonsInterface } from '../config/declarations';

const Buttons: FC<ButtonsInterface> = ({
  shape,
  psychic,
  className,
  bootStatus,
  chatButton,
  talkButton,
  inQueueButton,
  callbackButton,
  psychicFrame,
  messageButton,
}) => {
  const getCommonProps = (buttonName: any) => ({
    shape,
    psychic,
    buttonName,
    'data-button': buttonName.toLowerCase(),
  });

  return (
    <div className={className}>
      <TalkButton
        psychicFrame={psychicFrame}
        button={talkButton}
        {...getCommonProps('Talk')}
      />
      <Button
        button={callbackButton}
        {...getCommonProps('Callback')}
      />
      <Button
        button={inQueueButton}
        {...getCommonProps('InQueue')}
        psychicFrame={psychicFrame}
      />
      <ChatButton
        button={chatButton as Link}
        bootStatus={bootStatus}
        {...getCommonProps('Chat')}
      />
      <MessageButton
        psychicFrame={psychicFrame}
        button={messageButton}
        {...getCommonProps('Message')}
      />
    </div>
  );
};

export default Buttons;
