import React, { FC } from 'react';

import { PsychicsContext, PsychicsContextDispatch } from './PsychicsContext';

import { IPsychicContextProvider } from '../config/declarations';

const PsychicContextProvider: FC<IPsychicContextProvider> = ({
  store,
  localDispatch,
  children,
}) => (
  <PsychicsContext.Provider value={store}>
    <PsychicsContextDispatch.Provider value={localDispatch}>
      {children}
    </PsychicsContextDispatch.Provider>
  </PsychicsContext.Provider>
);

export default PsychicContextProvider;
