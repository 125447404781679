import { FC, memo } from 'react';
import parse from 'html-react-parser';

import { Tick } from 'src/shared/ui/Tick';
import styles from 'components/Sections/HoroscopesSignsHoroscopesAndPredictions/DailyHoroscope/DailyHoroscope.module.scss';
import { DataLink, WebpImage } from 'components/Shared/SharedComponents';
import {
  BR_TAG,
  HOROSCOPE_PAGE_DESKTOP_ICON_SIDE_SIZE,
  HOROSCOPE_PAGE_MOBILE_ICON_SIDE_SIZE,
} from 'constants/constants';
import { ImageFitMode } from 'constants/enums';
import { DailyHoroscopeSineTipInterface } from 'components/Sections/HoroscopesSignsHoroscopesAndPredictions/DailyHoroscope/declarations';

const SignTip: FC<DailyHoroscopeSineTipInterface> = ({
  mockContent,
  block,
  tip,
  mobileViewMaxWidth,
  isMobileViewWidth,
}) => {
  if (!block) {
    return null;
  }

  const { link, image, title } = block;

  const formattedTip = tip?.startsWith(BR_TAG) ? tip?.slice(BR_TAG.length) : tip;
  const imageWidthLimit = [{
    width: HOROSCOPE_PAGE_MOBILE_ICON_SIDE_SIZE,
    media: `${mobileViewMaxWidth}px`,
    fit: ImageFitMode.SCALE,
  }];
  const imageSideSize = `${
    isMobileViewWidth
      ? HOROSCOPE_PAGE_MOBILE_ICON_SIDE_SIZE
      : HOROSCOPE_PAGE_DESKTOP_ICON_SIDE_SIZE}px`;

  return (
    <>
      {image && (
        <WebpImage
          image={image}
          width={imageSideSize}
          height={imageSideSize}
          widthLimit={imageWidthLimit}
          className={styles.dailyTipsImage}
        />
      )}
      <h3 className={styles.dailyTipsTitle}>{title}</h3>
      <p className={styles.dailyTipsParagraph}>
        {formattedTip
          ? parse(formattedTip)
          : <span className={styles.dailyTipsParagraphEmpty}>{mockContent}</span>}
      </p>
      {link && formattedTip && (
        <DataLink
          href={link.src!}
          link={link}
          title={link.alt!}
          className={styles.dailyTipsLink}
        >
          {link.title}
          <Tick
            className={styles.selectNumbersIcon}
            borderWidth={2}
            color="#BB5204"
            direction="right"
          />
        </DataLink>
      )}
    </>
  );
};

export default memo(SignTip);
