import type { ReducerAction } from 'app-redux/types/storeTypes';

import { psychicListContext } from './PsychicsContext';

import type { IPsychicsContext } from '../config/declarations';

export const psychicListReducer = (
  store: IPsychicsContext = psychicListContext,
  action: ReducerAction<keyof IPsychicsContext>,
) => {
  switch (action.type) {
    case 'psychics': {
      return { ...store, psychics: [...action.payload] };
    }
    default: return { psychics: action.payload };
  }
};
