import { FC } from 'react';

import { useSidebarScreenSpace } from 'src/shared/lib/sidebar/hooks';

import SidebarStyles from './SidebarStyles';
import CommonStyles from './CommonStyles';

import type { IGlobalStyles } from '../config/declarations';

const GlobalStyles: FC<IGlobalStyles> = ({ sidebar }) => {
  const asideScreenSpace = useSidebarScreenSpace(sidebar);

  if (!sidebar || !sidebar.contentCollection?.items?.length || !asideScreenSpace) {
    return <CommonStyles />;
  }

  return <SidebarStyles sidebar={sidebar} />;
};

export default GlobalStyles;
