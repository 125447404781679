import { FC } from 'react';
import {
  BLOCKS,
  MARKS,
  INLINES,
} from '@contentful/rich-text-types';
import cn from 'classnames';

import styles from 'components/Sections/RelatedBlogPosts/RelatedBlogPosts.module.scss';
import classes from 'src/styles/_commonClasses.module.scss';
import {
  CommonRichText as Content,
  DataLink,
  WebpImage,
} from 'components/Shared/SharedComponents';
import { CircleImageDescriptionButtonInterface } from 'components/Sections/CircleImageDescriptionButtonClone/declarations';

const getHeadingClasses = (headingClass: string) => cn(
  styles.blogCardHeaderTitle,
  headingClass,
);
const parsersConfig = {
  [BLOCKS.HEADING_3]: {
    classNames: getHeadingClasses(styles.blogCardHeaderTitleH3),
  },
  [BLOCKS.HEADING_4]: {
    classNames: getHeadingClasses(styles.blogCardHeaderTitleH4),
  },
  [BLOCKS.PARAGRAPH]: {
    classNames: styles.blogCardBodyParagraph,
  },
  [INLINES.HYPERLINK]: {
    classNames: cn(styles.blogCardBodyParagraph, styles.blogCardBodyParagraphLink),
  },
  [MARKS.BOLD]: {
    classNames: classes.textBold,
  },
};

/* Nested component */
const Title = Content;

/* Main component */
const CircleImageDescriptionButton: FC<CircleImageDescriptionButtonInterface> = ({ block }) => {
  const {
    content,
    link,
    image,
    entryName,
    richTitle,
  } = block;

  if (!link) {
    return null;
  }

  return (
    <article className={styles.blogCard}>
      <section className={styles.blogCardBody}>
        {image && (
          <DataLink
            link={link}
            key={entryName}
            className={cn(styles.blogCardBodyButton, styles.blogCardBodyButtonImage)}
          >
            <WebpImage
              image={image}
              className={styles.blogCardBodyButtonImage}
            />
          </DataLink>
        )}
        <header className={styles.blogCardHeader}>
          <Title
            content={richTitle}
            parsersConfig={parsersConfig}
          />
        </header>
        <Content
          content={content}
          parsersConfig={parsersConfig}
        />
        <DataLink
          link={link}
          key={entryName}
          className={cn(styles.blogCardBodyButton, styles.blogCardBodyButtonText)}
        >
          {link.title}
        </DataLink>
      </section>
    </article>
  );
};

export default CircleImageDescriptionButton;
