import { FC } from 'react';

import { WebpImage } from 'components/Shared/SharedComponents';
import type { SortByInterface } from 'components/Sections/PsychicsSetMediumSizeImagesClone/declarations';

import SortBySelect from './SortBySelect';

import styles from '../PsychicFilter.module.scss';

const SortBy: FC<SortByInterface> = ({
  sortImage,
  sortTitle,
  sortList,
  viewerDevice,
  changeSort,
}) => (
  <div className={styles.sortBy}>
    <WebpImage
      image="external"
      src={sortImage?.url!}
      className={styles.icon}
    />
    <SortBySelect
      sortTitle={sortTitle as any}
      viewerDevice={viewerDevice}
      options={sortList}
      onChange={changeSort}
    />
  </div>
);

export default SortBy;
