import { FC } from 'react';
import cn from 'classnames';

import { Icon } from 'components/Shared/SharedComponents';

import localStyles from './PsychicRatingLine.module.scss';

import type { IRatingComponent } from '../config/declarations';

const PsychicRatingLine: FC<IRatingComponent> = ({
  overallScore,
  psychicFrame,
  responses,
  styles = {},
}) => {
  const { starImage, extraTextsCollection } = psychicFrame;
  const reviews = extraTextsCollection?.items?.find((text) => text?.slug === 'reviews')?.text || '';

  return (
    <div className={cn(localStyles.rating, styles.rating)}>
      {overallScore}
      {starImage?.url && (
        <Icon
          image={starImage}
          className={cn(localStyles.icon, styles.icon)}
        />
      )}
      {`(${responses}  ${reviews})`}
    </div>
  );
};

export default PsychicRatingLine;
