import { FC } from 'react';
import cn from 'classnames';

import { DataLink, WebpImage } from 'components/Shared/SharedComponents';
import { useProfileLink } from 'src/shared/lib/psychics/hooks';

import localStyles from './PsychicClickableName.module.scss';

import type { IPsychicName } from '../config/declarations';

const Name: FC<IPsychicName> = ({ psychic, psychicFrame, positionInArray, styles = {} }) => {
  const labelIcon = psychicFrame.labelsCollection?.items
    ?.find((item) => item?.slug === 'verification-icon');

  const {
    profileLink,
    profileUrl,
    gaData,
  } = useProfileLink(psychicFrame, psychic, positionInArray);

  return (
    <DataLink
      link={profileLink!}
      href={profileUrl}
      className={cn(localStyles.nameWrapper, styles.nameWrapper)}
      gaData={gaData}
    >
      <span className={cn(localStyles.name, styles.name)}>{psychic.lineName}</span>
      {labelIcon?.image?.url && (
        <WebpImage
          image="external"
          src={labelIcon?.image?.url}
          className={cn(localStyles.icon, styles.icon)}
        />
      )}
    </DataLink>
  );
};

export default Name;
