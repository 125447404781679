import { FC } from 'react';
import cn from 'classnames';

import { PsychicCardImage } from 'entities/PsychicCardImage';
import { PsychisReadingType } from 'constants/enums';
import { PsychicNameWithIcon, StatusIndicatorWithText } from 'entities/JsxParserComponents';

import styles from './styles.module.scss';

import type { IPsychicReadingsPhotoModule } from '../config/declarations';

/* Nested Component */
const CallbackReadingDetail = ({ psychic, psychicSkeleton }) => {
  const { detailsButton } = psychicSkeleton;

  return (
    <div className={styles.details}>
      <p>
        {detailsButton.slug}
        {psychic.estimatedWaitTime}
      </p>
    </div>
  );
};

/* Nested Component */
const ReadingDetails = ({ psychic, psychicSkeleton }) => {
  const { type } = psychic;
  const isCallBack = type === PsychisReadingType.CALLBACK;
  const isAppointment = type === PsychisReadingType.APPOINTMENT;
  const isUpcoming = type === PsychisReadingType.UPCOMING;
  const showColon = isCallBack || isAppointment || isUpcoming;

  if (psychic.considerCtaButtons) {
    return null;
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.textPlaceHolder1}>
        <p className={styles.type}>{showColon ? `${type}:` : type}</p>
      </div>
      {isCallBack && (
        <CallbackReadingDetail
          psychic={psychic}
          psychicSkeleton={psychicSkeleton}
        />
      )}
      {(isAppointment || isUpcoming) && (
        <p className={styles.type}>{psychic.date.replace(',', '').replace('PM', 'pm')}</p>
      )}
    </div>
  );
};

/* Main component */
const PhotoWithReadingDetails: FC<IPsychicReadingsPhotoModule> = ({
  src,
  psychic,
  imageClassName,
  psychicSkeleton,
}) => {
  const labelIcon = psychicSkeleton?.labelsCollection?.items
    ?.find((item) => item?.slug === 'verification-icon');

  return (
    <div className={styles.photoWithReadings}>
      <PsychicCardImage
        psychic={psychicSkeleton}
        image="external"
        src={src}
        alt={psychic.psychicName}
        className={cn(styles.image, imageClassName)}
      />
      <div className={styles.psychicStatusWrapper}>
        <StatusIndicatorWithText
          chatStatus={psychic.chatStatus}
          phoneStatus={psychic.phoneStatus}
          wrapperClass={styles.status}
        />
        <PsychicNameWithIcon
          psychicName={psychic.psychicName}
          image={labelIcon?.image!}
          wrapperClass={cn(styles.psychicName, styles.name)}
          className={styles.psychicIcon}
        />
      </div>
      <ReadingDetails
        psychic={psychic}
        psychicSkeleton={psychicSkeleton}
      />
    </div>
  );
};

export default PhotoWithReadingDetails;
