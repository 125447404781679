/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react/jsx-props-no-spreading */
import { FC } from 'react';
import cn from 'classnames';
import { useSelect } from 'downshift';

import { Tick } from 'src/shared/ui/Tick';
import styles from 'components/Sections/HoroscopesSignsHoroscopesAndPredictions/CompatibilityCheck/CompatibilityCheck.module.scss';
import classes from 'src/styles/_commonClasses.module.scss';
import { DataButton, WebpImage } from 'components/Shared/SharedComponents';
import { CompatibilitySelectInterface } from 'components/Sections/HoroscopesSignsHoroscopesAndPredictions/CompatibilityCheck/declarations';
import type { Block } from 'src/__generated__/graphqlTypes';

const CompatibilitySelect: FC<CompatibilitySelectInterface> = ({
  title,
  link,
  className,
  selectedItem,
  options,
  horoscopeCompatibilitySelectImageSize,
  setCurrentSelectValue,
}) => {
  const {
    isOpen,
    getMenuProps,
    getToggleButtonProps,
    getItemProps,
  } = useSelect<Block>({ items: options! });

  const setSelectedItemOrPlaceholder = () => {
    if (selectedItem) {
      const { image, title } = selectedItem;

      return (
        <div className={styles.compatibilityCheckDropdownSelected}>
          {image && (
            <WebpImage
              image={image}
              height={`${horoscopeCompatibilitySelectImageSize}px`}
              width={`${horoscopeCompatibilitySelectImageSize}px`}
              additionalProps={{ w: horoscopeCompatibilitySelectImageSize, h: horoscopeCompatibilitySelectImageSize, fit: 'scale' }}
              className={styles.compatibilityCheckDropdownSelectedImg}
            />
          )}
          <span className={styles.compatibilityCheckDropdownSelectedTitle}>
            {title}
          </span>
        </div>
      );
    }

    return (
      <span className={styles.compatibilityCheckDropdownPlaceholder}>
        {title}
      </span>
    );
  };

  return (
    <div className={cn(styles.compatibilityCheckDropdown, className)}>
      <DataButton
        link={link!}
        className={styles.compatibilityCheckDropdownButton}
        {...getToggleButtonProps()}
      >
        {setSelectedItemOrPlaceholder()}
        <span className={styles.tickSection}>
          <Tick
            color="#BB5204"
            sideSize={12}
            direction="bottom"
            className={styles.buttonTick}
          />
        </span>
      </DataButton>
      <ul
        {...getMenuProps()}
        className={cn(
          styles.compatibilityCheckDropdownMenu,
          !isOpen && cn(classes.visibilityHidden, classes.zeroHeight),
        )}
      >
        {options?.map((block, index) => {
          const { entryName, title = '', image } = block;
          const itemProps = getItemProps({ item: block, index });

          return (
            <li
              key={entryName!}
              value={title!}
              {...itemProps}
              onClick={(e) => {
                itemProps.onClick?.(e);
                setCurrentSelectValue(block);
              }}
              className={styles.compatibilityCheckDropdownMenuOption}
            >
              {image && (
                <WebpImage
                  image={image}
                  additionalProps={{ w: horoscopeCompatibilitySelectImageSize, h: horoscopeCompatibilitySelectImageSize, fit: 'scale' }}
                  className={styles.compatibilityCheckDropdownMenuOptionImg}
                />
              )}
              <span className={styles.compatibilityCheckDropdownMenuOptionTitle}>
                {title}
              </span>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default CompatibilitySelect;
