import { FC, useState } from 'react';
import cn from 'classnames';
import { BLOCKS, MARKS } from '@contentful/rich-text-types';

import type { Block } from 'src/__generated__/graphqlTypes';
import styles from 'components/Sections/HoroscopesSignsHoroscopesAndPredictions/DailyHoroscope/DailyHoroscope.module.scss';
import SignTip from 'components/Sections/HoroscopesSignsHoroscopesAndPredictions/DailyHoroscope/SignTip';
import { DailyHoroscopeInterface } from 'components/Sections/HoroscopesSignsHoroscopesAndPredictions/DailyHoroscope/declarations';
import classes from 'styles/_commonClasses.module.scss';
import { capitalizeFirstLetter } from 'lib/text.service';
import { getLocalizedFullMonthDYDate } from 'lib/date.service';
import type { RichTextParsersConfig } from 'types/objectTypes';
import { CommonRichText } from 'components/Shared/SharedComponents';

const getParsersConfig = (): RichTextParsersConfig => ({
  [BLOCKS.HEADING_2]: {
    classNames: cn(styles.dailyText, styles.dailyTextTitle),
  },
  [BLOCKS.HEADING_3]: {
    classNames: cn(styles.dailyText, styles.dailyTextTitle),
  },
  [MARKS.BOLD]: {
    classNames: classes.textBold,
  },
});

const getCurrentDate = (index: number) => {
  const dayToMakeYesterdayDate = 1;
  const date = new Date();
  date.setDate(date.getDate() - dayToMakeYesterdayDate + index);

  return date;
};

/* Main component */
const DailyHoroscope: FC<DailyHoroscopeInterface> = ({
  dailyHoroscope,
  locale,
  zodiacSign,
  mobileViewMaxWidth,
  isMobileViewWidth,
}) => {
  const {
    textList = [],
    title,
    richTitle,
    contentTypesCollection,
    tips,
  } = dailyHoroscope;

  const [currentDayIndex, setCurrentDayIndex] = useState<number>(Math.floor(textList!.length / 2));
  const currentDate = getCurrentDate(currentDayIndex);

  const daysList = textList!
    .map((day, i) => {
      if (i === currentDayIndex) {
        return (
          <button
            key={day}
            type="button"
            className={cn(
              styles.dailyDayListButton,
              styles.dailyDayListButtonSelected,
            )}
          >
            {day}
          </button>
        );
      }

      return (
        <button
          key={day}
          type="button"
          onClick={() => setCurrentDayIndex(i)}
          className={styles.dailyDayListButton}
        >
          {day}
        </button>
      );
    });

  const blocks = contentTypesCollection?.items as Array<Block>;

  return (
    <div
      className={cn(
        styles.daily,
        zodiacSign && styles[`dailyBackground${capitalizeFirstLetter(zodiacSign)}`],
      )}
    >
      {richTitle && (
        <CommonRichText
          content={richTitle}
          parsersConfig={getParsersConfig()}
        />
      )}
      <div className={styles.dailyDayList}>
        {daysList}
      </div>
      <p className={cn(styles.dailyText, styles.dailyTextParagraph)}>
        {getLocalizedFullMonthDYDate(locale, currentDate)}
      </p>
      {tips && blocks && (
        <div className={styles.dailyTips}>
          <SignTip
            mockContent={title}
            block={blocks[currentDayIndex]}
            tip={tips[currentDayIndex]}
            mobileViewMaxWidth={mobileViewMaxWidth}
            isMobileViewWidth={isMobileViewWidth}
          />
        </div>
      )}
    </div>
  );
};

export default DailyHoroscope;
