import {
  ElementAlign,
  ItemsLayout,
  MobileItemsLayout,
  ViewerDevice,
} from 'constants/enums';
import {
  Asset,
  Block,
  ImageWrapper,
  Link,
} from 'src/__generated__/graphqlTypes';
import { MediaWidthInterface, SectionComponentInterface } from 'types/componentTypes';
import { SectionExtraDataType } from 'types/objectTypes';

type listLayout =
  | 'row'
  | 'column'
  | 'column-reverse'
  | 'row-reverse';

export interface OrangeAlertRichTextParam {
  titleAlign: ElementAlign;
  pAlign: ElementAlign;
  h1Size: SectionExtraDataType['h1Size'] | '';
  textWeight: SectionExtraDataType['textWeight'];
}

export interface OrangeAlertInterface
  extends SectionComponentInterface,
  MediaWidthInterface {
  viewerDevice: ViewerDevice;
}

export interface OrangeAlertListItemInterface
  extends MediaWidthInterface {
  mobileItemsLayout: MobileItemsLayout;
  itemsLayout?: ItemsLayout;
  link: Block['link'];
}

export interface OrangeAlertBackgroundInterface extends MediaWidthInterface {
  backgrounds?: Array<ImageWrapper>;
  viewerDevice: ViewerDevice;
}

export enum BackgroundFieldsEnum {
  MOBILE = 'mobile',
  DESKTOP = 'desktop',
}

export enum OrangeAlertIconMultiplier {
  DEFAULT = 0.3,
  TABLET = 0.25,
  MOBILE = TABLET,
}

export interface OrangeAlertListItemIconInterface extends MediaWidthInterface {
  image?: Asset;
  mobileItemsLayout: MobileItemsLayout;
}

export interface OrangeAlertRichText<T = OrangeAlertRichTextParam> {
  richText: Block['richTitle'] | Block['content'];
  config: T;
}

export interface OrangeAlertButton {
  button: Block['link'];
}

export interface OrangeAlertOptionalList extends Omit<OrangeAlertListItemInterface, 'link'> {
  links?: Array<Link>;
  listLayout: listLayout;
}
