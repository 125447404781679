import { BLOCKS, MARKS } from '@contentful/rich-text-types';
import {
  FC,
  useEffect,
  useState,
} from 'react';
import cn from 'classnames';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { useSelector } from 'react-redux';

import type { Store } from 'src/redux/types/storeTypes';
import { Block } from 'src/__generated__/graphqlTypes';
import { CircleImageDescriptionButtonsInterface } from 'types/componentTypes';
import {
  BlogResponse,
  RichTextParsersConfig,
  ZodiacSign,
} from 'types/objectTypes';
import styles from 'components/Sections/RelatedBlogPosts/RelatedBlogPosts.module.scss';
import classes from 'src/styles/_commonClasses.module.scss';
import { RelatedBlogPost, RelatedBlogPostsAPI } from 'components/Sections/RelatedBlogPosts';
import { CommonRichText as Content } from 'components/Shared/SharedComponents';
import Divider from 'components/Sections/Divider';
import {
  CommonSize,
  ElementAlign,
  Heading,
} from 'constants/enums';
import { capitalizeFirstLetter } from 'lib/text.service';
import { mapBlocksHeadingWithReplacement } from 'lib/richTextMappers';
import { getSubCategoriesBlog } from 'src/api/customerApi';

const getParsersConfig = (
  titleAlign: ElementAlign,
  zodiacSign: ZodiacSign,
): RichTextParsersConfig => {
  const getHeadingClasses = (headingClass: string) => cn(
    styles.blogTitle,
    classes[`titleAlign${capitalizeFirstLetter(titleAlign)}`],
    headingClass,
  );
  const replacementObject = { sign: capitalizeFirstLetter(zodiacSign) };

  return ({
    [BLOCKS.HEADING_1]: {
      classNames: getHeadingClasses(classes.titleMainH1),
      mapper: mapBlocksHeadingWithReplacement(replacementObject, Heading.FIRST),
    },
    [BLOCKS.HEADING_2]: {
      classNames: getHeadingClasses(classes.titleMainH2),
      mapper: mapBlocksHeadingWithReplacement(replacementObject),
    },
    [BLOCKS.HEADING_3]: {
      classNames: getHeadingClasses(classes.titleMainH3),
      mapper: mapBlocksHeadingWithReplacement(replacementObject, Heading.THIRD),
    },
    [BLOCKS.PARAGRAPH]: {
      classNames: styles.blogParagraph,
    },
    [MARKS.BOLD]: {
      classNames: classes.textBold,
    },
  });
};

/* Nested component */
const Title = Content;

/* Main component */
const RelatedBlogPosts: FC<CircleImageDescriptionButtonsInterface> = ({
  bgColor,
  extraData,
  content: block,
  zodiacSign,
  topDivider,
  bottomDivider,
  commonPageMaxWidth,
}) => {
  const isMobileViewWidth = useSelector((store: Store) => store.server.app.isMobileViewWidth);

  const [blogData, setBlogData] = useState<Array<BlogResponse>>([]);
  const [showBlogData, setShowBlogData] = useState<Boolean>(false);

  const {
    titleAlign = ElementAlign.CENTER,
    verticalPadding = CommonSize.MEDIUM,
  } = extraData || { };
  const {
    contentTypesCollection,
    content,
    richTitle,
    title,
    link,
  } = block || {};

  const blogType = title;
  useEffect(() => {
    (async () => {
      const resp = await getSubCategoriesBlog(blogType || '');
      setBlogData(resp);
      setShowBlogData(true);
    })();
  }, []);

  if (!block) {
    return null;
  }

  const circleImages = contentTypesCollection?.items as Array<Block> || [];

  const getBlogData = () => {
    if (blogData.length > 0) {
      return blogData.map((block) => (
        <RelatedBlogPostsAPI
          key={block.id}
          entryName={block.id}
          content={block.textContent}
          richTitle={block.heading}
          link={block.linkURL}
          image={block.imageURL}
          readLink={link!}
        />
      ));
    }

    if (showBlogData) {
      return circleImages.map((block) => (
        <RelatedBlogPost
          key={block.entryName}
          block={block}
        />
      ));
    }
  };

  return (
    <section
      className={cn(
        styles.wrapper,
        classes[`sharedWrapperPaddingVertical${capitalizeFirstLetter(verticalPadding)}`],
      )}
      style={{ background: bgColor }}
    >
      <div className={styles.blog}>
        <Divider
          block={topDivider}
          className={styles.blogDividerTop}
          maxWidth={`${commonPageMaxWidth}px`}
        />
        <Title
          content={richTitle}
          parsersConfig={getParsersConfig(titleAlign, zodiacSign)}
        />
        <hr className={styles.blogUnderline} />
        <Content
          content={content}
          parsersConfig={getParsersConfig(titleAlign, zodiacSign)}
        />
        {isMobileViewWidth
          ? (
            <Carousel
              swipeable
              showArrows={false}
              className={styles.blogCarousel}
              showStatus={false}
              infiniteLoop
            >
              {getBlogData()}
            </Carousel>
          )
          : getBlogData()}

        <Divider
          block={bottomDivider}
          className={styles.blogDividerBottom}
          maxWidth={`${commonPageMaxWidth}px`}
        />
      </div>
    </section>
  );
};

export default RelatedBlogPosts;
