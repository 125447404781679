import { FC } from 'react';
import cn from 'classnames';

import { DataLink } from 'components/Shared/SharedComponents';

import styles from './TextAndSideAsset.module.scss';

import type { IExtraButtons } from '../config/declarations';

const ExtraButtons: FC<IExtraButtons> = ({ extraButtons, assetAlign, buttonWithContent }) => {
  if (!extraButtons.length) {
    return null;
  }

  return (
    <>
      {extraButtons.map((link) => {
        if (!buttonWithContent || !link) {
          return null;
        }

        return (
          <DataLink
            key={link.entryName}
            link={link}
            className={cn(
              styles[`${assetAlign}ContentButton`],
              styles[`${assetAlign}ContentButtonSecondary`],
            )}
          />
        );
      })}
    </>
  );
};

export default ExtraButtons;
