import {
  FC,
  useMemo,
  useRef,
} from 'react';

import type { SectionComponentInterface } from 'types/componentTypes';

import PsychicsBlock from './PsychicsBlock';
import { Content, Title } from './RichTextComponents';
import PsychicCtaButtons from './PsychicCtaButtons';
import SectionLayout from './SectionLayout';
import styles from './styles.module.scss';

import {
  getBlocksAndStyles,
  getDefaultExtraData,
  getExtraElements,
} from '../service';

const PsychicsSet: FC<SectionComponentInterface> = ({
  blocks,
  bgColor,
  extraData = {},
}) => {
  const sectionRef = useRef<HTMLElement>(null);
  const {
    block,
    styles: extraStyles,
    topDivider,
    bottomDivider,
  } = getBlocksAndStyles(blocks);
  const defaultExtraData = useMemo(() => getDefaultExtraData(extraData), [extraData]);
  const { titleAlign, pAlign, verticalPadding } = defaultExtraData;
  const richTextConfig = { titleAlign, pAlign };
  const { psychicFrame, secondaryButton } = getExtraElements(block);

  return (
    <SectionLayout
      sectionRef={sectionRef}
      verticalPadding={verticalPadding}
      bgColor={bgColor}
      block={block}
      topDivider={topDivider}
      bottomDivider={bottomDivider}
      extraStyles={extraStyles}
      secondaryButton={secondaryButton}
    >
      <div className={styles.wrapSection}>
        <Title
          richText={block?.richTitle}
          config={richTextConfig}
        />
        <Content
          richText={block?.content}
          config={richTextConfig}
        />
        <PsychicsBlock
          sectionRef={sectionRef}
          psychicFrame={psychicFrame}
          extraData={defaultExtraData}
        />
        <PsychicCtaButtons
          primary={block?.link}
          secondary={secondaryButton}
        />
      </div>
    </SectionLayout>
  );
};

export default PsychicsSet;
