import { AxiosError } from 'axios';

import { remoteServerAxios, proxyServerAxios } from 'src/api/axios';
import type {
  RequestPsychicParams,
  RequestPsychicParamsProxy,
  RightPsychic,
} from 'types/objectTypes';

export const getRightPsychicsProxy = (requestParameters?: any) => proxyServerAxios
  .post('/psychics/right', requestParameters)
  .then((resp) => resp.data)
  .catch((e) => e);

export const getRightPsychics = (
  requestParams: RequestPsychicParams,
  customerId?: string,
  cookie?: string,
) => remoteServerAxios
  .post('psychics', requestParams, {
    params: { ...(customerId ? { CustId: customerId } : {}) },
    headers: { ...(cookie ? { cookie } : {}) },
  })
  .then((resp) => resp.data);

export const getPsychicProxy = (id: string) => proxyServerAxios
  .get(`/psychics/${id}`)
  .then((resp) => resp.data)
  .catch((e: AxiosError) => { throw e.response; });

export const getPsychic = (id: string, custId?: string) => remoteServerAxios
  .get(`/psychics/${id}`, { params: { CustId: custId } })
  .then((resp) => resp.data);

export const searchPsychicByCriteriaProxy = (criteria: string) => proxyServerAxios
  .get('/psychics/search', { params: { criteria } })
  .then((resp) => resp.data)
  .catch((e: AxiosError) => { throw e.response; });

export const searchPsychicByCriteria = (criteria: string, custId?: string) => remoteServerAxios
  .post('psychics/search', { SearchText: criteria, CustId: custId })
  .then((resp) => resp.data)
  .catch((e: AxiosError) => { throw e.response; });

export const getTestimonialsByExtIdProxy = (
  requestParams: RequestPsychicParamsProxy,
) => proxyServerAxios
  .post('psychics/testimonials', requestParams)
  .then((resp) => resp.data)
  .catch((e: AxiosError) => { throw e.response; });

export const getTestimonialsByExtId = (
  requestParams: RequestPsychicParams,
  cookie?: string,
) => remoteServerAxios
  .post('psychic/testimonialsbyexid', requestParams, {
    headers: { ...(cookie ? { cookie } : {}) },
  })
  .then((resp) => resp.data)
  .catch((e: AxiosError) => { throw e.response; });

export const getCustOffersProxy = (custId?: string) => proxyServerAxios
  .get(`/custoffers?CustID=${custId}&PromoCode=null&offertype=all`, {
    headers: { accept: 'application/json' },
  })
  .then((resp) => resp.data)
  .catch((e: AxiosError) => e);

export const getSimilarPsychics = (psychic: RightPsychic, custId?: string) => remoteServerAxios
  .post(
    'similarpsychics',
    {
      CustId: custId,
      PageSize: 2,
      PageIndex: 0,
      CustFavorite: false,
      SearchText: '',
      SortBy: '',
      SearchOptions: null,
      CurrentView: 1,
      ResultType: 1,
      AppId: 1002,
      SortByText: '',
      Extid: psychic.extId,
      GroupId: psychic.groupId,
    },
  ).then((resp) => resp.data)
  .catch((e: AxiosError) => { throw e.response; });

export const getPsychicTimeSlots = (
  dates: any,
  extId: number,
  isVipCustomer?: number | boolean,
) => remoteServerAxios
  .post(`psychics/timeslots/${extId}`, {
    StartDate: dates.start,
    EndDate: dates.end,
    TimeZoneId: dates.timezone,
    IsVIPCustomer: isVipCustomer,
  }, {
    headers: { 'content-type': 'application/json' },
  })
  .then((resp) => resp.data)
  .catch((e: AxiosError) => { throw e.response; });

export const getPsychicFilterOptions = () => remoteServerAxios
  .get('/psychic/filteroptions')
  .then((resp) => resp.data)
  .catch((e) => e);

export const getFilteredPsychicsIds = (requestParameters?: any) => proxyServerAxios
  .post('/psychics/filtered/ids', requestParameters)
  .then((resp) => resp.data);

export const getStatusApi = (
  ids: Array<string>,
  params: Record<string, any> = {},
) => remoteServerAxios
  .post(
    '/psychic/getstatus',
    { ExtIds: ids.join(','), ...params },
    { headers: { 'content-type': 'application/json' } },
  )
  .then((resp) => resp.data);
