import { FC, useEffect } from 'react';

import { useCustomRouter } from 'src/shared/lib/history/hooks';
import { GTM } from 'lib/external/gtm';
import { convertToPST } from 'lib/date.service';

import styles from './AppointmentCalendar.module.scss';

import { IAppointmentList } from '../config/declarations';

const options: Intl.DateTimeFormatOptions = {
  hour: 'numeric',
  hour12: true,
  minute: 'numeric',
};

const ID_PREFIX = 'radio-choice-a';

const AppointmentList: FC<IAppointmentList> = ({ selected, select }) => {
  const router = useCustomRouter();
  const formatDate = (date: Date) => date
    .toLocaleString(router.locale, options)
    .replace(/\s/g, '')
    .toLowerCase();

  useEffect(() => {
    select(null);
  }, [selected]);

  return (
    <ul
      role="radiogroup"
      aria-labelledby="radiogroup"
      className={styles.content}
    >
      {selected.forAppointments.map((appointment, i) => {
        const startTime = appointment.start.toLocaleString(router.locale || 'en-US', options);
        const endTime = appointment.end.toLocaleString(router.locale || 'en-US', options);

        return (
          <li key={appointment.start.getTime()} className={styles.li}>
            <input
              id={`${ID_PREFIX}-${appointment.start.getTime()}`}
              className={styles.radio}
              data-start-time={startTime}
              data-end-time={endTime}
              onChange={() => {
                GTM.sendEvent('appointment_time_click', {
                  appointment_time: convertToPST(appointment.start).toISOString(),
                  appointment_time_start: startTime,
                  appointment_time_end: endTime,
                  page_category: 'bio',
                });

                select(i);
              }}
              name="radiogroup"
              type="radio"
            />
            <label className={styles.label} htmlFor={`${ID_PREFIX}-${appointment.start.getTime()}`}>
              {formatDate(appointment.start)}
            </label>
          </li>
        );
      })}
    </ul>
  );
};

export default AppointmentList;
