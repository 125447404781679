import { FC } from 'react';
import cn from 'classnames';

import { getPsychicCtaButtons, getStatus } from 'lib/psychic.service';
import { PsychicRate } from 'components/Shared/SharedComponents';
import { PsychicStatusIndicator, PsychicStatusText } from 'entities/PsychicStatus';
import { PsychicCtaButtons } from 'entities/PsychicCtaButton';
import type { RatePerMinuetType } from 'types/objectTypes';
import { HiatusBar } from 'entities/HiatusBar';
import { PsychicRatingLine } from 'entities/PsychicRatingLine';
import { Status } from 'constants/enums';

import styles from './PsychicCardTile.module.scss';

import { IFirstCard, PriceComponentInterface } from '../config/declarations';
import { getCardClickHandler } from '../lib';

/* Nested Component */
const PriceComponent: FC<PriceComponentInterface> = ({ ratePerMinute, ratePerMinuteText }) => {
  const priceStyles = {
    wrapper: styles.price,
    common: styles.priceCommon,
    commonWithDiscount: styles.priceCrossed,
    discount: styles.priceDiscount,
  };

  const minText = `/${ratePerMinuteText}`;

  return (
    <PsychicRate
      pricePerMinute={ratePerMinute.price}
      priceWithDiscountPerMinute={ratePerMinute.discount}
      classNames={priceStyles}
      time={minText}
    />
  );
};

/* Main Component */
const FirstCard: FC<IFirstCard> = ({
  user,
  psychic,
  psychicFrame,
  bootStatus,
  setCardStep,
}) => {
  const cta = getPsychicCtaButtons(psychic, psychicFrame, { hashedId: user?.hashedId });
  const ratePerMinute: RatePerMinuetType = {
    discount: psychic.customerPrice,
    price: psychic.basePrice,
  };
  const status = getStatus({
    chatStatus: psychic.chatStatus,
    phoneStatus: psychic.phoneStatus,
  });

  return (
    <div
      role="presentation"
      onClick={getCardClickHandler(() => setCardStep('second'))}
      className={styles.mainInfo}
    >
      <div className={cn(styles.priceStatus)}>
        <PriceComponent
          ratePerMinute={ratePerMinute}
          ratePerMinuteText={psychicFrame.ratePerMinute}
        />
        <div className={cn(
          styles.status,
          { [styles.fullWidth]: status === Status.ON_BREAK },
        )}
        >
          <PsychicStatusIndicator
            status={status}
            className={styles.statusIndicator}
          />
          <PsychicStatusText
            status={status}
            psychic={psychic}
            psychicFrame={psychicFrame}
            className={styles.statusText}
            smallTextClass={styles.small}
            wrapperClass={styles.statusTextCombined}
          />
        </div>
      </div>
      <PsychicRatingLine
        overallScore={psychic.overallScore}
        psychicFrame={psychicFrame}
        responses={psychic.responses}
      />
      <i className={styles.usp}>
        {psychic.usp}
      </i>
      {!psychic.onHiatus && (
        <PsychicCtaButtons
          bootStatus={bootStatus}
          className={styles.interaction}
          chatButton={cta.chatButton}
          talkButton={cta.talkButton}
          callbackButton={cta.callbackButton}
          inQueueButton={cta.inQueueButton}
          messageButton={cta.messageButton}
          psychicFrame={psychicFrame}
          psychic={psychic}
          shape="Oval"
        />
      )}
      <HiatusBar
        psychic={psychic}
        contentfulData={psychicFrame}
        className={styles.hiatus}
      />
    </div>
  );
};

export default FirstCard;
