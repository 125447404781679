import { FC } from 'react';
import cn from 'classnames';

import styles from 'components/Sections/OrangeAlert/OrangeAlert.module.scss';
import { DataLink, WebpImage } from 'components/Shared/SharedComponents';
import {
  OrangeAlertListItemIconInterface as IconInterface,
  OrangeAlertListItemInterface,
  OrangeAlertIconMultiplier as IconMultiplier,
} from 'components/Sections/OrangeAlert/declarations';
import { MobileItemsLayout, ViewerDevice } from 'constants/enums';
import {
  EXTERNAL_WEBP_IMAGE,
  SVG_TYPE,
  SVG_XML_TYPE,
} from 'constants/constants';
import type { HeightAndWidth, WebpWidthLimitType } from 'types/objectTypes';
import { SidesCalculator } from 'lib/image.service';
import { capitalizeFirstLetter } from 'lib/text.service';

const multiplyOnValueWithFloor = (val?: number, multiplier: number = IconMultiplier.DEFAULT) => Math
  .floor(val! * multiplier);

const getIconWidthLimit = (
  view: HeightAndWidth<number>,
  media?: string | number,
  rest?: Partial<WebpWidthLimitType>,
) => {
  if (!media) {
    return {} as WebpWidthLimitType;
  }

  return ({
    width: view.width,
    height: view.height,
    media: `${media}px`,
    ...rest,
  });
};

const getSideMultiplier = (
  mobileItemsLayout: MobileItemsLayout,
  device: ViewerDevice = ViewerDevice.DESKTOP,
) => {
  if (mobileItemsLayout === MobileItemsLayout.GRID_2
      || mobileItemsLayout === MobileItemsLayout.GRID_3) {
    if (device === ViewerDevice.DESKTOP) {
      return IconMultiplier.DEFAULT + 0.1;
    }

    if (device === ViewerDevice.TABLET) {
      return IconMultiplier.TABLET + 0.05;
    }
  }

  if (mobileItemsLayout === MobileItemsLayout.GRID_2) {
    return IconMultiplier.MOBILE + 0.2;
  }

  if (mobileItemsLayout === MobileItemsLayout.GRID_3) {
    return IconMultiplier.MOBILE + 0.1;
  }

  if (device === ViewerDevice.DESKTOP) {
    return IconMultiplier.DEFAULT;
  }

  return IconMultiplier.TABLET;
};

/* Nested component */
const Icon: FC<IconInterface> = ({
  image,
  mobileItemsLayout,
  mobileViewMaxWidth,
  commonTabletMaxWidth,
}) => {
  if (!image) {
    return null;
  }

  if (image.url
    && (image.contentType === SVG_XML_TYPE || image.contentType === SVG_TYPE)) {
    return (
      <WebpImage
        height={image.height!}
        width={image.width!}
        image={EXTERNAL_WEBP_IMAGE}
        src={image.url}
        className={styles.image}
      />
    );
  }

  const sideCalculator = new SidesCalculator(image, multiplyOnValueWithFloor);

  const desktopMultiplier = getSideMultiplier(mobileItemsLayout);
  const tabletMultiplier = getSideMultiplier(mobileItemsLayout, ViewerDevice.TABLET);
  const mobileMultiplier = getSideMultiplier(mobileItemsLayout, ViewerDevice.MOBILE);

  const desktopIconSids = sideCalculator.calculate(desktopMultiplier);
  const tabletIconSids = sideCalculator.calculate(tabletMultiplier);
  const mobileIconSids = sideCalculator.calculate(mobileMultiplier);

  const widthLimit: Array<WebpWidthLimitType> = [
    getIconWidthLimit(mobileIconSids, mobileViewMaxWidth),
    getIconWidthLimit(tabletIconSids, commonTabletMaxWidth),
    getIconWidthLimit(desktopIconSids, commonTabletMaxWidth, { isMinWidth: true }),
  ];

  return (
    <WebpImage
      image={image}
      widthLimit={widthLimit}
      className={styles.image}
    />
  );
};

/* Main component */
const OrangeAlertListItem: FC<OrangeAlertListItemInterface> = ({
  link,
  mobileItemsLayout,
  mobileViewMaxWidth,
  commonTabletMaxWidth,
}) => {
  if (!link) {
    return null;
  }

  const { src, title, image, slug } = link;
  const isDivider = slug === 'divider';

  const content = isDivider ? null : (
    <>
      <Icon
        image={image!}
        mobileItemsLayout={mobileItemsLayout}
        mobileViewMaxWidth={mobileViewMaxWidth}
        commonTabletMaxWidth={commonTabletMaxWidth}
      />
      <span
        title={title!}
        className={styles.text}
      >
        {title}
      </span>
    </>
  );

  if (src === '#') {
    return (
      <li className={cn(styles.alertContentLi,
        styles[`alertContentLi${capitalizeFirstLetter(slug || '')}`],
        isDivider ? styles.divider : styles.alertContentLiNoLink)}
      >
        {content}
      </li>
    );
  }

  return (
    <li className={styles.alertContentLi}>
      <DataLink
        link={link}
        className={cn(styles.alertContentLiLink,
          styles[`alertContentLi${capitalizeFirstLetter(slug || '')}`])}
      >
        {content}
      </DataLink>
    </li>
  );
};

export default OrangeAlertListItem;
