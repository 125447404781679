import { FC } from 'react';

import type { ImageWrapper } from 'src/__generated__/graphqlTypes';

import Video from './CustomVideo';
import Image from './CustomImage';

import type { CustomAssetInterface } from '../config/declarations';

const CustomAsset: FC<CustomAssetInterface> = ({
  block,
  extraData,
  viewerDevice,
  videoAutoplay,
}) => {
  const { image, video, imagesCollection } = block;

  const images = imagesCollection?.items as Array<ImageWrapper>;
  const isVideoExists = video && (video.src || video.image?.url);
  const imageComponent = (
    <Image
      image={image}
      images={images}
      extraData={extraData}
      viewerDevice={viewerDevice}
    />
  );

  if (isVideoExists) {
    return (
      <Video
        video={video}
        preview={imageComponent}
        extraData={extraData}
        videoAutoplay={videoAutoplay}
      />
    );
  }

  const isImage = (image || images.length) && !isVideoExists;

  if (isImage) {
    return imageComponent;
  }

  return null;
};

export default CustomAsset;
