import { FC } from 'react';
import { BLOCKS, MARKS } from '@contentful/rich-text-types';
import cn from 'classnames';

import styles from 'components/Sections/OrangeAlert/OrangeAlert.module.scss';
import classes from 'src/styles/_commonClasses.module.scss';
import { OrangeAlertBackground } from 'components/Sections/OrangeAlert';
import { RichTextParsersConfig } from 'types/objectTypes';
import { CommonRichText, DataLink } from 'components/Shared/SharedComponents';
import {
  ImageWrapper,
  Link,
  Text,
} from 'src/__generated__/graphqlTypes';
import {
  ColorTheme,
  ElementAlign,
  ItemsLayout,
  MobileItemsLayout,
} from 'constants/enums';
import { capitalizeFirstLetter } from 'lib/text.service';
import {
  OrangeAlertButton as ButtonInterface,
  OrangeAlertInterface,
  OrangeAlertOptionalList as OptionalListInterface,
  OrangeAlertRichText as RichTextInterface,
  OrangeAlertRichTextParam,
} from 'components/Sections/OrangeAlert/declarations';
import OrangeAlertListItem from 'components/Sections/OrangeAlert/OrangeAlertListItem';
import { useFetchSegmentData } from 'lib/external/segment';
import { getBlockAndStyles } from 'src/shared/lib/blocks';
import { ContentfulStyles } from 'entities/ContentfulStyles';
import { ExtraComponents } from 'src/widgets/OrangeAlertBig/lib/context';

import { constructId, getExtraComponents } from './constants';

const getParsersConfig = ({
  titleAlign,
  h1Size = '',
  pAlign,
  textWeight,
}: OrangeAlertRichTextParam): RichTextParsersConfig => {
  const getHClasses = (specificClass: string) => cn(
    styles.alertTitle,
    specificClass,
    classes[`titleAlign${capitalizeFirstLetter(titleAlign)}`],
  );
  const paragraphAlign = classes[`pAlign${capitalizeFirstLetter(pAlign)}`];

  return ({
    [BLOCKS.HEADING_1]: {
      classNames: getHClasses(classes[`titleMainH1${capitalizeFirstLetter(h1Size)}`]),
    },
    [BLOCKS.HEADING_2]: {
      classNames: getHClasses(classes[`textBold${textWeight}`]),
    },
    [BLOCKS.HEADING_3]: {
      classNames: getHClasses(classes.titleMainH3),
    },
    [BLOCKS.PARAGRAPH]: {
      classNames: cn(styles.alertParagraph, paragraphAlign),
    },
    [MARKS.BOLD]: {
      classNames: classes.textBold,
    },
  });
};

/* Nested component */
const OptionalList: FC<OptionalListInterface> = ({
  links,
  listLayout,
  itemsLayout,
  mobileItemsLayout,
  mobileViewMaxWidth,
  commonTabletMaxWidth,
}) => {
  if (!links?.length) {
    return null;
  }

  return (
    <ul
      className={cn(styles.alertContent, styles[`${mobileItemsLayout}`], styles[`${itemsLayout}`])}
      style={{ flexDirection: listLayout }}
    >
      {links.map((link) => (
        <OrangeAlertListItem
          key={link.sys.id}
          link={link}
          mobileItemsLayout={mobileItemsLayout}
          mobileViewMaxWidth={mobileViewMaxWidth}
          commonTabletMaxWidth={commonTabletMaxWidth}
        />
      ))}
    </ul>
  );
};

/* Nested component */
const Button: FC<ButtonInterface> = ({ button }) => {
  const segmentObj = useFetchSegmentData(button);

  if (!button) {
    return null;
  }

  return (
    <DataLink
      link={button}
      className={styles.alertButton}
      segmentData={segmentObj}
    >
      {button.title}
    </DataLink>
  );
};

/* Nested component */
const Content: FC<RichTextInterface> = ({ richText, config }) => {
  if (!richText) {
    return null;
  }

  return (
    <CommonRichText
      content={richText}
      parsersConfig={getParsersConfig(config)}
    />
  );
};

/* Nested component */
const Title: FC<RichTextInterface> = Content;

/* Main Component */
const OrangeAlert: FC<OrangeAlertInterface> = ({
  blocks,
  bgColor,
  extraData,
  viewerDevice,
  mobileViewMaxWidth,
  commonTabletMaxWidth,
}) => {
  const {
    titleAlign = ElementAlign.CENTER,
    pAlign = ElementAlign.CENTER,
    h1Size,
    listLayout,
    textWeight = 'Imp',
    colorTheme = ColorTheme.WHITE,
    itemsLayout = ItemsLayout.ROW,
    mobileItemsLayout = MobileItemsLayout.FLEX_COLUMN,
  } = extraData || {};
  const richTextConfig = { titleAlign, h1Size, pAlign, textWeight };
  const [{
    content,
    richTitle,
    link,
    contentTypesCollection,
    imagesCollection,
    blockId,
  }] = blocks;
  const links = contentTypesCollection?.items as Array<Link>;

  const { styles: extraStyles } = getBlockAndStyles(blocks);
  const extraComponents = getExtraComponents(contentTypesCollection?.items as Array<Text>);

  return (
    <ExtraComponents.Provider value={extraComponents}>
      <ContentfulStyles styles={extraStyles} />
      <section
        id={constructId(blockId)}
        className={styles.wrapper}
      >
        <div
          className={cn(styles.alert, styles[`${itemsLayout}`], classes[`themeColor${capitalizeFirstLetter(colorTheme)}`])}
          style={{ background: bgColor }}
        >
          <Title richText={richTitle} config={richTextConfig} />
          <Content richText={content} config={richTextConfig} />
          <OptionalList
            links={links}
            listLayout={listLayout}
            itemsLayout={itemsLayout}
            mobileItemsLayout={mobileItemsLayout}
            mobileViewMaxWidth={mobileViewMaxWidth}
            commonTabletMaxWidth={commonTabletMaxWidth}
          />
          <Button button={link} />
        </div>
        <OrangeAlertBackground
          backgrounds={imagesCollection?.items as Array<ImageWrapper>}
          viewerDevice={viewerDevice}
          mobileViewMaxWidth={mobileViewMaxWidth}
          commonTabletMaxWidth={commonTabletMaxWidth}
        />
      </section>
    </ExtraComponents.Provider>
  );
};

export default OrangeAlert;
