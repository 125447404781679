import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import type { Store } from 'app-redux/types/storeTypes';
import { getCustomerOffers } from 'src/api/customerApi';
import { Logger } from 'lib/logger';
import { capitalizeFirstLetter, replaceContentfulVariables } from 'lib/text.service';
import type { Block } from 'src/__generated__/graphqlTypes';

export const useCustomerOffers = (setIsSingleOffer: Function) => {
  const user = useSelector((store: Store) => store.server.auth.user);
  const [offers, setOffers] = useState<Array<Record<string, any>> | null>(null);
  const [error, setError] = useState<any>(null);
  const [isLoading, setLoadingState] = useState<boolean>(false);

  useEffect(() => {
    if (!user) {
      return;
    }

    (async () => {
      try {
        setLoadingState(true);
        const offers = await getCustomerOffers({
          custId: user.custId,
          promoCode: null,
          psychicTier: null,
          np: null,
        });
        setLoadingState(false);

        setOffers(offers.custOffers?.slice(0, 2));
        setIsSingleOffer(offers?.totalCount === 1);
      } catch (e: any) {
        setError(e);
        Logger.error(e);
      }
    })();
  }, [user]);

  return { isLoading, error, offers };
};

export const useGreetingText = (text: Block['title']) => {
  const user = useSelector((store: Store) => store.server.auth.user);

  if (!text) {
    return 'Hello!';
  }

  if (user?.firstName) {
    return replaceContentfulVariables(text!, { name: capitalizeFirstLetter(user.firstName) });
  }

  const trimmedText = text
    .replace(',', '')
    .replace(/\{.*\}/g, '');

  return trimmedText;
};
