import { FC } from 'react';
import cn from 'classnames';

import classes from 'src/styles/_commonClasses.module.scss';
import { capitalizeFirstLetter } from 'lib/text.service';

import { StatusComponentInterface } from '../config/declarations';

const Indicator: FC<StatusComponentInterface> = ({ status, className }) => {
  const indicatorStyles = cn(
    className,
    classes.statusIndicator,
    classes[`statusIndicator${capitalizeFirstLetter(status)}`],
  );

  return (
    <span className={indicatorStyles} />
  );
};

export default Indicator;
