import { FC } from 'react';

import { PsychicCardImage } from 'entities/PsychicCardImage';
import { RatePerMinuetType } from 'types/objectTypes';
import { PsychicRate } from 'components/Shared/SharedComponents';
import { PsychicAvatarWrapperLink } from 'entities/PsychicAvatarWrapperLink';
import { StatusTextWrapper as StatusText, StatusIndicatorWrapper as StatusIndicator } from 'entities/JsxParserComponents';
import { PsychicCardVideo } from 'entities/PsychicCardVideo';
import { HeartImage } from 'entities/PsychicHeartImage';
import { PsychicRatingLine } from 'entities/PsychicRatingLine';
import { PsychicClickableName } from 'entities/PsychicClickableName';

import styles from './CardSimpleSquare.module.scss';

import type { IProfile } from '../config/declarations';

const Profile: FC<IProfile> = ({ psychic, psychicFrame, positionInArray }) => {
  const psychicImageUrl = psychic.images[2] || psychic.psychicImageUrl || psychicFrame?.avatar?.url || '';

  const priceStyles = {
    wrapper: styles.price,
    common: styles.priceCommon,
    commonWithDiscount: styles.priceCrossed,
    discount: styles.priceDiscount,
  };

  const ratePerMinute: RatePerMinuetType = {
    discount: psychic.customerPrice,
    price: psychic.basePrice,
  };

  return (
    <div className={styles.profile}>
      <PsychicAvatarWrapperLink
        className={styles.link}
        positionInArray={positionInArray}
        profileButton={psychicFrame.profileButton}
        psychic={psychic}
      >
        <>
          <PsychicCardImage
            psychic={psychicFrame}
            className={styles.psychicImage}
            image="external"
            src={psychicImageUrl}
            alt={psychic.psychicName}
          />
          {
            psychic?.psychicVideoURL && (
              <PsychicCardVideo
                psychic={psychic}
                className={styles.video}
              />
            )
          }
          <HeartImage
            onClick={(e) => e.preventDefault()}
            contentfulData={psychicFrame}
            psychic={psychic}
            className={styles.heartImage}
            assetClassName={styles.heartImageAsset}
            assetFavoriteClassName={styles.heartImageAssetFavorite}
            hintClassName={styles.heartImageHint}
            hintArrowClassName={styles.heartImageHintArrow}
          />
        </>
      </PsychicAvatarWrapperLink>
      <div className={styles.information}>
        <PsychicClickableName
          psychic={psychic}
          psychicFrame={psychicFrame}
          positionInArray={positionInArray}
          styles={styles}
        />
        <div className={styles.rate}>
          <PsychicRate
            pricePerMinute={ratePerMinute.price}
            priceWithDiscountPerMinute={ratePerMinute.discount}
            classNames={priceStyles}
            time={(psychicFrame.ratePerMinute)
              ? `/${psychicFrame.ratePerMinute}`
              : undefined}
          />
        </div>
        <PsychicRatingLine
          overallScore={psychic.overallScore}
          psychicFrame={psychicFrame}
          responses={psychic.responses}
          styles={styles}
        />
        <div className={styles.status}>
          <StatusIndicator
            chatStatus={psychic.chatStatus}
            phoneStatus={psychic.phoneStatus}
            className={styles.statusIndicator}
          />
          <StatusText
            psychic={psychic}
            psychicFrame={psychicFrame}
            className={styles.statusText}
            smallTextClass={styles.small}
            wrapperClass={styles.statusTextCombined}
          />
        </div>
      </div>
    </div>
  );
};

export default Profile;
