/* eslint-disable jsx-a11y/img-redundant-alt */
import { FC } from 'react';
import cn from 'classnames';

import styles from 'components/Footer/Links/Media/Media.module.scss';
import { FooterMediaIconInterface, SocialMediasInterface } from 'components/Footer/Links/Media/declarations';
import { DataLink, LazyWebpImage } from 'components/Shared/SharedComponents';
import { capitalizeFirstLetter } from 'lib/text.service';
import { EXTERNAL_WEBP_IMAGE } from 'constants/constants';
import { Text } from 'src/__generated__/graphqlTypes';

const cfl = capitalizeFirstLetter;

/* Nested component */
const Icon: FC<FooterMediaIconInterface> = ({ image, footerType }) => {
  if (!image?.url) {
    return null;
  }

  return (
    <LazyWebpImage
      image={EXTERNAL_WEBP_IMAGE}
      src={image.url}
      className={cn(
        styles.socialLinkIcon,
        styles[`socialLinkIcon${cfl(footerType)}`],
      )}
    />
  );
};

/* Main component */
const SocialMedias: FC<SocialMediasInterface> = ({ socialMedias, footerType, additional = [] }) => {
  const stayConnectedTitle = additional[2] as Text;

  return (
    <>
      <p className={styles.mediaTitle}>{stayConnectedTitle?.text}</p>
      <div className={cn(styles.social, styles[`social${cfl(footerType)}`])}>
        {socialMedias.map((link) => (
          <DataLink
            key={link.entryName}
            link={link}
            title={link.title as string}
            className={cn(styles.socialLink, styles[`socialLink${cfl(link.title as string)}`])}
          >
            <Icon image={link.image} footerType={footerType} />
          </DataLink>
        ))}
      </div>
    </>
  );
};

export default SocialMedias;
