import cn from 'classnames';
import {
  FC,
  useRef,
  useState,
} from 'react';
import { BLOCKS } from '@contentful/rich-text-types';

import {
  CommonRichText,
  DataButton,
  ValidationMark,
} from 'components/Shared/SharedComponents';
import { RichTextParsersConfig } from 'types/objectTypes';
import {
  FilterListPanelMobileInterface,
  FilterOptionsInterface,
  FilterTitleInterface,
  FilterTabsInterface,
  PopularFilterDescription,
  SelectedValueChange,
} from 'components/Sections/PsychicsSetMediumSizeImagesClone/declarations';
import { useClickOutsideMultiple } from 'lib/shared.hook';
import { useLoadFiltersOptions } from 'components/Sections/PsychicsSetMediumSizeImagesClone/lib/hooks';
import LocalLoader from 'src/shared/ui/LocalLoader/LocalLoader';
import { capitalizeFirstLetter } from 'lib/text.service';
import { CommonSize } from 'constants/enums';
import { Tick } from 'src/shared/ui/Tick';
import { Cross } from 'src/shared/ui/Cross';

import PriceFilterSectionList from './FilterPriceItem';
import FilterActionButtons from './FilterActionButtons';

import styles from '../PsychicFilter.module.scss';
import mobileStyles from '../PsychicFilterMobile.module.scss';

const parsersConfig: RichTextParsersConfig = {
  [BLOCKS.PARAGRAPH]: {
    classNames: mobileStyles.heading,
  },
};

const FilterTitle: FC<FilterTitleInterface> = ({ filterTitle }) => (
  <CommonRichText content={filterTitle} parsersConfig={parsersConfig} />
);

const FilterTabs: FC<FilterTabsInterface> = ({
  currentVisibleTabInMobile,
  filtersByCategories,
  priceTitle,
  setCurrentVisibleTabInMobile,
}) => {
  const onPriceClick = () => setCurrentVisibleTabInMobile?.('Price');
  const isPriceSelected = currentVisibleTabInMobile === 'Price';
  const tickComponent = (
    <Tick
      color="#fff"
      className={mobileStyles.topicsItemTick}
      direction="right"
    />
  );

  return (
    <ul className={mobileStyles.topics}>
      <li
        className={cn(
          mobileStyles.topicsItem,
          { [mobileStyles.topicsItemSelected]: isPriceSelected },
        )}
        onClick={onPriceClick}
        onKeyPress={onPriceClick}
        role="presentation"
      >
        {priceTitle?.title}
        {isPriceSelected && tickComponent}
      </li>
      {filtersByCategories?.map((category) => {
        const categoryName = category.majorCategoryDescription;

        if (!categoryName) {
          return null;
        }

        const isSelected = currentVisibleTabInMobile === categoryName;
        const onCategoryClick = () => setCurrentVisibleTabInMobile?.(categoryName);

        return (
          <li
            key={category.majorCategoryDescription}
            className={cn(
              mobileStyles.topicsItem,
              { [mobileStyles.topicsItemSelected]: isSelected },
            )}
            onClick={onCategoryClick}
            onKeyPress={onCategoryClick}
            role="presentation"
          >
            {category.richText}
            {isSelected && tickComponent}
          </li>
        );
      })}
    </ul>
  );
};

const FilterOptions: FC<FilterOptionsInterface> = ({
  currentVisibleTabInMobile,
  priceOptions,
  filtersByCategories,
  priceTitle,
  popularTitle,
  checkIfFilterPresent,
  changeFilter,
}) => (
  <ul className={mobileStyles.values}>
    {currentVisibleTabInMobile === 'Price'
      ? priceOptions
        ?.map((item) => (
          <PriceFilterSectionList
            key={item.rate}
            className={mobileStyles.valuesItem}
            classNameSelected={mobileStyles.valuesItemSelected}
            changeFilter={changeFilter}
            checkIfFilterPresent={checkIfFilterPresent}
            item={item}
            priceTitle={priceTitle}
          />
        ))
      : filtersByCategories
        ?.filter((category) => category.majorCategoryDescription === currentVisibleTabInMobile)
        ?.map((category) => category.descriptions
          ?.map((categoryText?: string | PopularFilterDescription) => {
            if (!categoryText) {
              return null;
            }

            const isPopularPsychicFilter = currentVisibleTabInMobile === popularTitle?.title;
            // @ts-ignore
            const filterDetail: SelectedValueChange = {
              type: category.majorCategoryDescription!,
            };
            let displayVal: string;

            if (isPopularPsychicFilter) {
              const popularCategoryText = categoryText as PopularFilterDescription;
              filterDetail.value = popularCategoryText.val;
              displayVal = popularCategoryText.displayVal;
            } else {
              const text = categoryText as string;
              filterDetail.value = text;

              if (text.includes('/')) {
                displayVal = text
                  .split('/')
                  .map((str: string) => str
                    .split(' ')
                    .map(capitalizeFirstLetter)
                    .join(' '))
                  .join(' & ');
              } else {
                displayVal = text
                  .split(' ')
                  .map(capitalizeFirstLetter)
                  .join(' ');
              }
            }

            const isSelected = checkIfFilterPresent?.(filterDetail);
            const sharedData = {
              key: displayVal,
              onClick: () => changeFilter(filterDetail),
              onKeyPress: () => changeFilter(filterDetail),
            };

            if (isSelected) {
              return (
                <li className={cn(mobileStyles.valuesItem, mobileStyles.valuesItemSelected)}>
                  <DataButton {...sharedData}>
                    <ValidationMark isValid size={CommonSize.SMALL} />
                    {displayVal}
                  </DataButton>
                </li>
              );
            }

            return (
              <li className={mobileStyles.valuesItem}>
                <DataButton {...sharedData}>
                  {displayVal}
                </DataButton>
              </li>
            );
          }))}
  </ul>
);

const FilterListPanelMobile: FC<FilterListPanelMobileInterface> = ({
  filterTitle,
  showFilters,
  totalResultsLocal,
  clearFilterButton,
  priceTitle,
  viewTitle,
  resultsTitle,
  doneButton,
  filterByTitleRef,
  popularFilters,
  popularTitle,
  isFetchingFilteredCount,
  changeFilter,
  doneFilter,
  removeFilter,
  closeFilter,
  checkIfFilterPresent,
}) => {
  const [currentVisibleTabInMobile, setCurrentVisibleTabInMobile] = useState<string>('Price');
  const filterPanelRef = useRef<HTMLDivElement>(null);
  const { options, isLoading } = useLoadFiltersOptions(Boolean(showFilters), popularFilters);
  useClickOutsideMultiple([filterByTitleRef, filterPanelRef], () => closeFilter?.(), false);

  if (!showFilters) {
    return null;
  }

  if (isLoading) {
    return (
      <div
        ref={filterPanelRef}
        className={cn(styles.panelLoading, mobileStyles.filter)}
      >
        <LocalLoader />
      </div>
    );
  }

  return (
    <div ref={filterPanelRef} className={mobileStyles.filter}>
      <button
        type="button"
        className={mobileStyles.cross}
        onClick={closeFilter}
      >
        <Cross color="#1C573D" />
      </button>
      <FilterTitle filterTitle={filterTitle} />
      <div className={mobileStyles.lists}>
        <FilterTabs
          currentVisibleTabInMobile={currentVisibleTabInMobile}
          setCurrentVisibleTabInMobile={setCurrentVisibleTabInMobile}
          filtersByCategories={options.category}
          priceTitle={priceTitle}
        />
        <FilterOptions
          currentVisibleTabInMobile={currentVisibleTabInMobile}
          priceOptions={options.price}
          filtersByCategories={options.category}
          checkIfFilterPresent={checkIfFilterPresent}
          changeFilter={changeFilter}
          priceTitle={priceTitle}
          popularTitle={popularTitle}
        />
      </div>
      <FilterActionButtons
        updateFilters={doneFilter}
        clearFilters={removeFilter}
        clearButton={clearFilterButton}
        doneButton={doneButton}
        styles={mobileStyles}
        disabledFilterButton={!totalResultsLocal || isFetchingFilteredCount}
        doneButtonText={`${viewTitle?.title} ${totalResultsLocal} ${resultsTitle?.title}`}
      />
    </div>
  );
};

export default FilterListPanelMobile;
