import { FC } from 'react';
import { useSelector } from 'react-redux';
import { useRouter } from 'next/router';

import { Store } from 'app-redux/types/storeTypes';
import { HoroscopesAndPredictionsChildInterface } from 'types/componentTypes';
import { DailyHoroscope } from 'components/Sections/HoroscopesSignsHoroscopesAndPredictions/DailyHoroscope';

const DailyHoroscopeContainer: FC<HoroscopesAndPredictionsChildInterface> = ({
  block: dailyHoroscope,
  zodiacSign,
}) => {
  const { mobileViewMaxWidth } = useSelector((store: Store) => store.server.app.scssVariables);
  const isMobileViewWidth = useSelector((store: Store) => store.server.app.isMobileViewWidth);
  const { locale } = useRouter();

  return (
    <DailyHoroscope
      dailyHoroscope={dailyHoroscope as any}
      mobileViewMaxWidth={+mobileViewMaxWidth}
      isMobileViewWidth={isMobileViewWidth}
      zodiacSign={zodiacSign}
      locale={locale}
    />
  );
};

export default DailyHoroscopeContainer;
