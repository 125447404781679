import { FC } from 'react';
import cn from 'classnames';

import { Position, PsychicCardAppearance } from 'constants/enums';
import { PsychicCardImage } from 'entities/PsychicCardImage';
import { HeartImage } from 'entities/PsychicHeartImage';
import { PsychicCardVideo } from 'entities/PsychicCardVideo';
import { DataLink } from 'components/Shared/SharedComponents';
import { useProfileLink } from 'src/shared/lib/psychics/hooks';

import styles from './PsychicCardClickableImage.module.scss';

import type { IPsychicImage } from '../config/declarations';

const applyStyles = (
  external: Record<string, string>,
  styles: Record<string, string>,
  className: string,
) => cn(styles[className], external[className]);

const MainImage: FC<IPsychicImage> = ({
  styles: externalStyles = {},
  psychic,
  psychicFrame,
  positionInArray,
}) => {
  const {
    profileLink,
    gaData,
    profileUrl,
  } = useProfileLink(psychicFrame, psychic, positionInArray);
  const imageUrl = psychic.psychicImageUrl || psychicFrame.avatar?.url || '';

  return (
    <DataLink
      link={profileLink!}
      href={profileUrl}
      gaData={gaData}
      className={applyStyles(styles, externalStyles, 'imageWrapper')}
    >
      <PsychicCardImage
        loading={positionInArray === Position.RIGHT ? 'lazy' : 'eager'}
        psychic={psychicFrame}
        image="external"
        src={imageUrl}
        alt={psychic.psychicName}
        className={applyStyles(styles, externalStyles, 'image')}
      />
      <HeartImage
        psychic={psychic}
        contentfulData={psychicFrame}
        className={applyStyles(styles, externalStyles, 'heart')}
        assetClassName={applyStyles(styles, externalStyles, 'heartAsset')}
        assetFavoriteClassName={applyStyles(styles, externalStyles, 'heartAssetFavorite')}
        hintClassName={applyStyles(styles, externalStyles, 'heartHint')}
        hintArrowClassName={applyStyles(styles, externalStyles, 'heartHintArrow')}
        onClick={(e) => {
          const element = e.target as Element;
          const parentLink = element.closest('a');
          const tagName = element.tagName.toLowerCase();

          if (tagName === 'u' && parentLink) {
            return;
          }

          e.preventDefault();
          e.stopPropagation();
        }}
      />
      {psychic?.psychicVideoURL && (
        <PsychicCardVideo
          psychic={psychic}
          view={PsychicCardAppearance.TILE}
          className={applyStyles(styles, externalStyles, 'video')}
        />
      )}
    </DataLink>
  );
};

export default MainImage;
