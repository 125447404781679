import React from 'react';
import { BLOCKS, MARKS } from '@contentful/rich-text-types';
import cn from 'classnames';

import RulingPlanetAndSignElement from 'components/Sections/HoroscopesSignsAllAboutSigns/RulingPlanetAndSignElement';
import BestDayAndLuckyNumbers from 'components/Sections/HoroscopesSignsAllAboutSigns/BestDayAndLuckyNumbers';
import CareersAndCelebrity from 'components/Sections/HoroscopesSignsAllAboutSigns/CareersAndCelebrity';
import Traits from 'components/Sections/HoroscopesSignsAllAboutSigns/Traits';
import LuckyColor from 'components/Sections/HoroscopesSignsAllAboutSigns/LuckyColor';
import Gemstones from 'components/Sections/HoroscopesSignsAllAboutSigns/Gemstones';
import Flowers from 'components/Sections/HoroscopesSignsAllAboutSigns/Flowers';
import SelfCareArticles from 'components/Sections/HoroscopesSignsAllAboutSigns/SelfCareArticles';
import OtherSignsList from 'components/Sections/HoroscopesSignsAllAboutSigns/OtherSignsList';
import styles from 'components/Sections/HoroscopesSignsAllAboutSigns/HoroscopesSignsAllAboutSigns.module.scss';
import { CommonRichText } from 'components/Shared/SharedComponents';
import { RichTextParsersConfig } from 'types/objectTypes';
import { mapBlocksSecondHeadingWithId } from 'lib/richTextMappers';
import SocialMedias from 'components/SocialMediaSharing';
import { Block } from 'src/__generated__/graphqlTypes';
import { AllAboutSignsInterface } from 'components/Sections/HoroscopesSignsAllAboutSigns/declarations';

const HoroscopesSignsAllAboutSigns: React.FC<AllAboutSignsInterface> = ({
  title,
  url,
  socialSharing,
  titleId,
  features,
  otherSigns,
  isMobileViewWidth,
}) => {
  const createParsersConfig = (): RichTextParsersConfig => ({
    [BLOCKS.HEADING_2]: {
      classNames: styles.allAboutTitle,
      mapper: mapBlocksSecondHeadingWithId(titleId),
    },
    [MARKS.BOLD]: {
      classNames: cn(styles.allAboutTitle, styles.allAboutTitleBold),
    },
  });

  const applyViewForFlowersAndLuckyColor = (
    luckyColor: Block,
    flowers: Block,
    traits?: Array<Block>,
  ) => {
    if (isMobileViewWidth) {
      return (
        <>
          <div className={styles.allAboutContentFlowersAndColor}>
            <LuckyColor element={luckyColor!} />
            <Flowers element={flowers!} />
          </div>
          <Traits elements={traits || []} />
        </>
      );
    }

    return (
      <>
        <Flowers element={flowers!} />
        <Traits elements={traits as Array<Block> || []} />
        <LuckyColor element={luckyColor!} />
      </>
    );
  };

  if (!features) {
    return null;
  }

  const {
    luckyNumbers,
    bestDay,
    rulingPlanet,
    signElement,
    flowers,
    luckyColor,
    gemstones,
    traitsCollection,
    careers,
    celebrity,
    selfCare,
  } = features;

  return (
    <section className={styles.allAbout}>
      <div className={styles.allAboutSocialMedia}>
        <CommonRichText
          content={title}
          parsersConfig={createParsersConfig()}
        />
        <SocialMedias
          tag={socialSharing.title!}
          dataProps={socialSharing.properties}
          replacementValues={{ anchor: url }}
        />
      </div>
      <div className={styles.allAboutContent}>
        <RulingPlanetAndSignElement
          rulingPlanet={rulingPlanet!}
          signElement={signElement!}
        />
        {applyViewForFlowersAndLuckyColor(
          luckyColor!,
          flowers!,
          traitsCollection?.items as Array<Block>,
        )}
        <Gemstones element={gemstones!} />
        <div className={styles.allAboutContentCareAndCareers}>
          <SelfCareArticles element={selfCare!} />
        </div>
        <BestDayAndLuckyNumbers
          luckyNumbers={luckyNumbers!}
          bestDay={bestDay!}
        />
        <CareersAndCelebrity
          careers={careers!}
          celebrity={celebrity!}
        />
      </div>
      <OtherSignsList element={otherSigns!} />
    </section>
  );
};

export default HoroscopesSignsAllAboutSigns;
