import { FC } from 'react';
import cn from 'classnames';

import styles from './AppointmentCalendar.module.scss';
import ExistingAppointment from './ExistingAppointment';
import Navigation from './Navigation/Navigation';
import AppointmentHeader from './AppointmentHeader';

import type { IAppointment } from '../config/declarations';

const Appointment: FC<IAppointment> = ({
  selected,
  menu,
  button,
  modals,
  navigationButton,
  isCollapsed,
  dispatch,
}) => {
  if (selected.forAppointments.length) {
    return (
      <ExistingAppointment
        isCollapsed={isCollapsed}
        selected={selected}
        menu={menu}
        modals={modals}
        button={button}
        navigationButton={navigationButton}
        dispatch={dispatch}
      />
    );
  }

  if (!menu?.title) {
    return null;
  }

  return (
    <div className={styles.noappointments}>
      <AppointmentHeader block={menu} appointmentsAmount={selected.forAppointments.length} />
      <div className={cn(styles.widget, styles.body)}>
        <Navigation
          dispatch={dispatch}
          date={selected.date}
          link={navigationButton}
          gaData={{ action: 'appointment' }}
          className={styles.noappointmentsNav}
        />
      </div>
      <div
        className={cn(styles.appointments,
          { [styles.hidden]: isCollapsed },
          styles.noappointmentsText)}
      >
        <strong className={styles.headerHeading}>{menu.title}</strong>
      </div>
    </div>
  );
};

export default Appointment;
