import {
  FC,
  useCallback,
  useEffect,
  useReducer,
  useRef,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cn from 'classnames';
import isDeepEqual from 'lodash/isEqual';

import type { Block } from 'src/__generated__/graphqlTypes';
import { getRightPsychicsProxy } from 'src/api/psychicApi';
import type { Store } from 'app-redux/types/storeTypes';
import { setFirebaseRequiredState } from 'app-redux/actions/appActions';
import PsychicsSetMediumSizeImages from 'components/Sections/PsychicsSetMediumSizeImagesClone/PsychicsSetMediumSizeImages';
import type { SectionComponentInterface } from 'types/componentTypes';
import { PaginationInfo, RightPsychic } from 'types/objectTypes';
import {
  CommonContentfulSlug,
  ItemsLayout,
  LoadingMode,
  PaginationType,
  PsychicCardAppearance,
} from 'constants/enums';
import { IntersectionObserverInit, IntersectionObserverWrapper } from 'lib/intersectionObserver';
import { useCommonContentfulBlocks } from 'lib/shared.hook';
import { Logger } from 'lib/logger';
import { useBootChatSolution } from 'entities/PsychicCtaButton';
import { HOMEPAGE_SLUG, MY_FAVORITE_PSYCHICS } from 'constants/constants';
import { updatePsychicListOptionCookie } from 'src/shared/lib/cookie';
import { GTM } from 'lib/external/gtm';
import { useCustomRouter } from 'src/shared/lib/history/hooks';
import { useSectionsUiMerge } from 'src/shared/lib/styles/hooks';
import {
  PsychicContextProvider,
  psychicListReducer,
  psychicListContext,
} from 'entities/PsychicListContext';

import styles from './PsychicsSetMediumSizeImages.module.scss';
import { requestBody } from './lib/constants';
import {
  setFilterToolbarSelectionsFromExtraData,
  useAppropriatePsychicsStatuses,
  useFilteredExtIdsState,
  useFilterSelections,
  useFirebaseForSSR,
  useSortIdQueryParam,
  useTestPsychicsSwitcher,
  useZeroResultPanel,
} from './lib/hooks';
import {
  getSortIdCookie,
  setSortIdCookie,
  psychicFilterBlockReducer,
  setCurrentViewCookie,
  getCurrentViewCookie,
} from './lib';
import PsychicFilter from './ui/PsychicFilter/PsychicFilter';
import { LocalBlock, SelectedSort } from './declarations';

const PsychicsSetMediumSizeImagesContainer: FC<SectionComponentInterface> = ({
  blocks,
  bgColor,
  extraData,
}) => {
  const { scrollsAmountBeforeButton, psychicCategory } = extraData || {};
  const [block] = blocks as Array<LocalBlock>;
  const { filteringHeader } = psychicFilterBlockReducer(blocks);
  const {
    filterToolbarSelections,
    setFilterToolbarSelections,
  } = useFilterSelections(block, filteringHeader, extraData);
  const [store, localDispatch] = useReducer(psychicListReducer, psychicListContext);
  const { psychics } = store;
  const [paginationInfo, setPaginationInfo] = useState<PaginationInfo>({
    currentPageIndex: 0,
    totalPages: 0,
    hasNextPage: true,
    isNextPageEnabled: false,
    isPreviousPageEnabled: false,
    psychics: [],
  });

  const router = useCustomRouter();
  const dispatch = useDispatch();
  const sectionRef = useRef<HTMLElement>(null);
  const idList: Array<number> = psychics?.flatMap((psychic) => psychic.extId) || [];
  const isAuthenticated = useSelector((store: Store) => store.server.auth.isAuthenticated);
  const viewerDevice = useSelector((store: Store) => store.server.app.viewerDevice);
  const slug = useSelector((store: Store) => store.server.page.slug);
  const prevIsAuthenticated = useRef<boolean | null>(null);
  const [loadingScroll, setLoadingScroll] = useState<boolean>(false);
  const observerRef = useRef(null);
  const [pageIndex, setPageIndex] = useState<number>(block.psychics ? 1 : 0);
  const [totalPsychicsCount, setTotalPsychics] = useState<number>(0);
  const shouldUseTestPsychics = useTestPsychicsSwitcher();

  const mergingStyles = useSectionsUiMerge(extraData, styles);
  const apiCallsAmountBeforePaginationButton = (extraData?.scrollsAmountBeforeButton || 0) + 1;
  const [
    maxScrollPageIndex,
    setMaxScrollPageIndex,
  ] = useState(apiCallsAmountBeforePaginationButton);
  const isMobile = useSelector((store: Store) => store.server.app.isMobileViewWidth);
  const [filteredExtIds, getFilteredExtIds] = useFilteredExtIdsState(block);
  const {
    content,
    banner,
    topDivider,
    bottomDivider,
    commonPageMaxWidth,
  } = useCommonContentfulBlocks<CommonContentfulSlug | 'banner', Block>(blocks);
  const {
    zeroResultPanelVisibility,
    setZeroResultPanelVisibility,
  } = useZeroResultPanel(block, filteringHeader);
  const bootStatus = useBootChatSolution();
  const sortid = useSortIdQueryParam(slug);

  const {
    itemsAmount,
    loadingMode = LoadingMode.LAZY,
    maxPaginationPageAmount = 0,
    paginationType,
    apiData,
    filter = false,
    itemsAmountMobile,
  } = extraData || {};

  const setPsychics = (
    payload: Array<RightPsychic> | ((prev: Array<RightPsychic>) => Array<RightPsychic>),
  ) => {
    localDispatch({
      type: 'psychics',
      payload: typeof payload === 'function' ? payload(store.psychics) : payload,
    });
  };

  useFirebaseForSSR(extraData, block.psychics);
  useAppropriatePsychicsStatuses(idList, setPsychics, Boolean(filteringHeader));

  const setPsychicsFromRemote = useCallback(async (pageIndex = 0) => {
    const currentViewCookieStr = getCurrentViewCookie();
    let viewObj = {};

    if (filteringHeader && currentViewCookieStr) {
      const currentViewCookie = JSON.parse(currentViewCookieStr);

      if (currentViewCookie) {
        viewObj = { selectedLayout: ItemsLayout.COLUMN,
          psychicCardAppearance: PsychicCardAppearance.LIST };
      } else {
        viewObj = { selectedLayout: ItemsLayout.GRID,
          psychicCardAppearance: PsychicCardAppearance.TILE };
      }
    } else if (extraData?.itemsLayout) {
      viewObj = { selectedLayout: extraData?.itemsLayout };
    }

    let currentRequestBody = {
      ...requestBody,
      PageIndex: pageIndex,
      IncludeTestPsychics: shouldUseTestPsychics(router.query),
    };

    if (itemsAmount) {
      if (maxPaginationPageAmount) {
        currentRequestBody.PageSize = maxPaginationPageAmount * itemsAmount;
      } else {
        currentRequestBody.PageSize = itemsAmount;
      }
    }

    if (apiData) {
      const filteredApiData = Object.fromEntries(Object.entries(apiData).filter(
        ([, value]) => value !== null,
      ));

      currentRequestBody = { ...currentRequestBody, ...filteredApiData };
    }

    const sortingIdCookie = getSortIdCookie();

    if (sortid || sortingIdCookie) {
      currentRequestBody.SortId = sortid || sortingIdCookie;
    }

    if (router.query.search) {
      currentRequestBody.SearchText = router.query.search;
    }

    if (filteringHeader) {
      currentRequestBody = {
        ...currentRequestBody,
        ...filterToolbarSelections?.selectedFilters,
        ...filterToolbarSelections?.selectedSortItem,
      };
    }

    if (isMobile && itemsAmountMobile) {
      currentRequestBody.PageSize = itemsAmountMobile;
    }

    setLoadingScroll(true);

    const {
      psychics = [],
      currentPageIndex,
      hasPrevPage,
      hasNextPage,
      totalCount,
      sortResult,
      filterPsychicsAvailable,
      filterPsychicsOnCall,
    } = await getRightPsychicsProxy(currentRequestBody);

    if (filteringHeader) {
      getFilteredExtIds(currentRequestBody, totalCount);
    }

    setTotalPsychics(totalCount);
    setLoadingScroll(false);

    if (totalCount === 1) {
      if (psychics[0]) {
        GTM.sendEvent('OACPPsychicStatus', {
          Status: psychics[0].lineStatus,
          pageURL: window.location.href,
          psychicRate: `$${parseFloat(psychics[0].newCustFlatRate || '0').toFixed(2)}/min`,
        });
      } else {
        const errorContext = {
          errorMessage: 'Expected psychic data is missing or undefined for OACPPsychicStatus event.',
          timestamp: new Date().toISOString(),
          psychicsArrayLength: psychics?.length || 0,
          requestBody,
        };
        Logger.error(errorContext);
      }
    }

    if (filteringHeader) {
      setZeroResultPanelVisibility(!totalCount);
    }

    const isHomepage = slug === HOMEPAGE_SLUG;
    const shouldModifySortingCookie = isHomepage && sortResult && sortid !== sortingIdCookie;

    if (shouldModifySortingCookie) {
      setSortIdCookie(sortid);
    }

    if (pageIndex !== 0) {
      setPsychics((prvPsychics) => {
        if (prvPsychics) {
          return [...prvPsychics, ...psychics];
        }

        return [...psychics];
      });
    } else {
      setPsychics(psychics);
    }

    if (filteringHeader) {
      setFilterToolbarSelections((prev) => ({
        ...prev,
        totalResults: totalCount,
        totalAvailablePsychics: filterPsychicsAvailable,
        totalBusyPsychics: filterPsychicsOnCall,
        ...viewObj,
      }));
    }

    const deviceSensitiveItemsAmount = isMobile
      ? itemsAmountMobile
      : itemsAmount;
    const totalPagesIndex = Math.ceil(totalCount / (deviceSensitiveItemsAmount || 3));

    setMaxScrollPageIndex(totalPagesIndex);
    setPageIndex(pageIndex + 1);

    const startIdx = currentPageIndex * (itemsAmount || 0);
    const endIndx = startIdx + (itemsAmount || 0);
    const isPageInRange = currentPageIndex < maxPaginationPageAmount - 1;
    const isEndIndexWithinAvailablePsychics = endIndx < psychics.length;
    const isNextPageEnabled = isPageInRange && isEndIndexWithinAvailablePsychics;
    setPaginationInfo({
      currentPageIndex,
      totalPages: Math.ceil(psychics.length / (itemsAmount || psychics.length || 1)),
      isNextPageEnabled,
      hasNextPage,
      isPreviousPageEnabled: hasPrevPage,
      psychics: psychics.slice(startIdx, endIndx),
    });
    const shouldUseFirebase = extraData
      && extraData.psychicCardAppearance !== PsychicCardAppearance.SIMPLE
      && extraData.psychicCardAppearance !== PsychicCardAppearance.SIMPLE_ADDITIONAL;

    if (shouldUseFirebase) {
      dispatch(setFirebaseRequiredState(true));
    }
  }, [filterToolbarSelections, extraData]);

  const isScrollMode = scrollsAmountBeforeButton !== undefined && scrollsAmountBeforeButton > 0;
  const isShowLoadMoreButton = (isMobile)
    ? !isScrollMode
      && Boolean((extraData?.itemsAmountMobile || 5) <= psychics?.length)
      && Boolean(paginationInfo?.hasNextPage)
    : pageIndex < maxScrollPageIndex
      && totalPsychicsCount !== psychics?.length
      && Boolean(extraData?.itemsAmount && extraData?.itemsAmount <= psychics?.length);

  const changePage = useCallback(async (paginationStep: number) => {
    const { currentPageIndex = 0 } = paginationInfo || {};
    const newPageIndex = currentPageIndex + paginationStep;
    const startIdx = newPageIndex * (itemsAmount || 0);
    const endIndx = startIdx + (itemsAmount || 0);
    const isPageInRange = newPageIndex < maxPaginationPageAmount - 1;
    const isEndIndexWithinAvailablePsychics = endIndx < psychics.length;
    const isNextPageEnabled = isPageInRange && isEndIndexWithinAvailablePsychics;

    if (paginationType === PaginationType.NUMBERS) {
      setPaginationInfo({
        currentPageIndex: newPageIndex,
        totalPages: Math.ceil(psychics.length / (itemsAmount || psychics.length || 1)),
        isNextPageEnabled,
        isPreviousPageEnabled: newPageIndex > 0,
        psychics: psychics.slice(startIdx, endIndx),
      });
    } else {
      setPaginationInfo({
        currentPageIndex: newPageIndex,
        isNextPageEnabled,
        psychics: [...paginationInfo.psychics, ...psychics.slice(startIdx, endIndx)],
      });
    }
  }, [
    itemsAmount,
    maxPaginationPageAmount,
    paginationType,
    psychics,
    paginationInfo,
  ]);

  useEffect(() => {
    (async () => {
      if ((!psychics || psychics.length === 0) && isAuthenticated !== prevIsAuthenticated.current) {
        await setPsychicsFromRemote();
        prevIsAuthenticated.current = isAuthenticated;
      }
    })();
  }, [isAuthenticated, psychics, setPsychicsFromRemote, sortid]);

  useEffect(() => {
    if (slug === MY_FAVORITE_PSYCHICS && psychics?.length === 0 && paginationInfo?.totalPages) {
      setZeroResultPanelVisibility(true);
    }
  }, [psychics, paginationInfo, setZeroResultPanelVisibility, slug]);

  useEffect(() => {
    (async () => {
      setPageIndex((prev) => {
        /* prev === 1 because it set on init */
        if (block.psychics && prev === 1) {
          return 1;
        }

        return 0;
      });

      const defaultData = setFilterToolbarSelectionsFromExtraData(extraData || {}, filteringHeader);

      /* We are using isDeepEqual for a deep comparison of objects because a simple !== check
      only compares references. ensuring that every nested property in selectedSortItem is
      identical between filterToolbarSelections and defaultData. */

      if (!isDeepEqual(filterToolbarSelections?.selectedSortItem, defaultData.selectedSortItem)
         || !isDeepEqual(filterToolbarSelections?.selectedFilters, defaultData.selectedFilters)) {
        await setPsychicsFromRemote();
      }
    })();
  }, [filterToolbarSelections?.selectedSortItem, filterToolbarSelections?.selectedFilters, sortid]);

  useEffect(() => {
    if (paginationType === PaginationType.SCROLL) {
      return;
    }

    if (loadingMode === LoadingMode.EAGER) {
      if (itemsAmount !== psychics?.length) {
        (async () => {
          try {
            await setPsychicsFromRemote();
          } catch (e) {
            Logger.error(e);
          }
        })();
      }

      return;
    }

    if (!sectionRef.current) {
      (async () => {
        try {
          await setPsychicsFromRemote();
          dispatch(setFirebaseRequiredState(true));
        } catch (e) {
          Logger.error(e);
        }
      })();

      return;
    }

    const intersectionHandler = async (
      entries: Array<IntersectionObserverEntry>,
      observer: IntersectionObserver,
    ) => {
      const handleSuccess = async () => {
        await setPsychicsFromRemote();
        dispatch(setFirebaseRequiredState(true));

        if (sectionRef.current) {
          observer.unobserve(sectionRef.current);
        }
      };
      const observed: Array<any> = [];
      entries
        .forEach((entry) => {
          if (entry.isIntersecting) {
            try {
              observed.push(handleSuccess());
            } catch (e) {
              Logger.error(e);
            }
          }
        });

      await Promise.all(observed);
    };

    const options: IntersectionObserverInit = {
      rootMargin: '300px',
    };

    const observer = IntersectionObserverWrapper.getInstance(intersectionHandler, options);

    observer?.observe(sectionRef.current);
  }, [setPsychicsFromRemote]);

  useEffect(() => {
    const isInitialLoad = pageIndex === 0;
    const areLoadingsOnScrollLeft = !isMobile && pageIndex >= apiCallsAmountBeforePaginationButton;

    if (isInitialLoad
      || pageIndex >= maxScrollPageIndex
      || paginationType !== PaginationType.SCROLL
      || !scrollsAmountBeforeButton
      || areLoadingsOnScrollLeft) {
      return;
    }

    let loading = false;
    const handleObserver = (entries: IntersectionObserverEntry[]) => {
      const target = entries[0];
      const isDesktopHasPageToLoad = !isMobile && pageIndex > 0 && pageIndex < maxScrollPageIndex;

      if (target.isIntersecting && !loading && (isDesktopHasPageToLoad || isMobile)) {
        loading = true;
        setPsychicsFromRemote(pageIndex).finally(() => {
          loading = false;
        });
      }
    };

    const rootMargin = window.innerWidth > 700
      ? `${window.innerHeight * 0.6}px`
      : `${window.innerHeight * 0.3}px`;

    const observer = new IntersectionObserver(handleObserver, {
      root: null,
      rootMargin,
      threshold: 0,
    });

    if (observerRef.current) {
      observer.observe(observerRef.current);
    }

    return () => {
      if (observerRef.current) {
        observer.unobserve(observerRef.current);
      }
    };
  }, [pageIndex, maxScrollPageIndex, setPsychicsFromRemote]);

  const loadMorePsychicHandler = async () => {
    await setPsychicsFromRemote(pageIndex);
  };

  const clearFilter = () => {
    setFilterToolbarSelections({
      ...filterToolbarSelections,
      selectedSortItem: { SortBy: '', SortByText: '' },
      selectedFilters: {},
    });
  };

  const updateFilter = (
    changedSelectedSortItem: SelectedSort,
    changedSelectedFilters: Record<string, any>,
  ) => {
    setFilterToolbarSelections({
      ...filterToolbarSelections,
      selectedSortItem: changedSelectedSortItem,
      selectedFilters: changedSelectedFilters,
    });
  };

  const displayGridView = async () => {
    setCurrentViewCookie(ItemsLayout.GRID);
    updatePsychicListOptionCookie('CurrentView', 0);
    setFilterToolbarSelections({
      ...filterToolbarSelections,
      selectedLayout: ItemsLayout.GRID,
      psychicCardAppearance: PsychicCardAppearance.TILE,
    });
    await setPsychicsFromRemote(0);
  };

  const displayListView = async () => {
    setCurrentViewCookie(ItemsLayout.COLUMN);
    updatePsychicListOptionCookie('CurrentView', 1);
    setFilterToolbarSelections({
      ...filterToolbarSelections,
      selectedLayout: ItemsLayout.COLUMN,
      psychicCardAppearance: PsychicCardAppearance.LIST,
    });
    await setPsychicsFromRemote(0);
  };

  const updatedExtraData = filterToolbarSelections.selectedLayout
    ? {
      ...extraData,
      itemsLayout: filterToolbarSelections.selectedLayout,
      psychicCardAppearance: filterToolbarSelections.psychicCardAppearance,
    }
    : extraData;

  const filterComponent = (
    <PsychicFilter
      content={filteringHeader}
      isFilterVisible={filter}
      psychicCategory={psychicCategory}
      clearFilter={clearFilter}
      updateFilter={updateFilter}
      filteredExtIds={filteredExtIds}
      apiData={apiData}
      filterToolbarSelections={filterToolbarSelections}
      sortid={sortid}
      displayGridView={displayGridView}
      displayListView={displayListView}
      zeroResultPanelVisibility={zeroResultPanelVisibility}
    />
  );

  /** Now should be not reachable because view filtered result button will
   * be disabled if 0 result
   */
  if (zeroResultPanelVisibility && filteringHeader) {
    return (
      <section
        ref={sectionRef}
        className={cn(styles.wrapper, mergingStyles)}
        style={{ background: bgColor }}
      >
        { filterComponent }
      </section>
    );
  }

  return (
    <PsychicContextProvider
      store={store}
      localDispatch={localDispatch}
    >
      <section
        ref={sectionRef}
        className={cn(styles.wrapper, mergingStyles)}
        style={{ background: bgColor }}
      >
        <PsychicsSetMediumSizeImages
          bootStatus={bootStatus}
          extraData={updatedExtraData}
          psychics={psychics}
          viewerDevice={viewerDevice}
          content={content as any}
          topDivider={topDivider}
          bottomDivider={bottomDivider}
          changePage={changePage}
          commonPageMaxWidth={commonPageMaxWidth}
          paginationInfo={paginationInfo}
          loadingScroll={loadingScroll}
          observerRef={observerRef}
          isShowLoadMoreButton={isShowLoadMoreButton}
          loadMorePsychicHandler={loadMorePsychicHandler}
          banner={banner}
          filters={filteringHeader && filterComponent}
        />
      </section>
    </PsychicContextProvider>
  );
};

export default PsychicsSetMediumSizeImagesContainer;
