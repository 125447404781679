import { FC } from 'react';
import parse from 'html-react-parser';

import type { Text } from 'src/__generated__/graphqlTypes';

import styles from './styles.module.scss';

import { formatPhoneNumber } from '../../lib/message';
import type { IEcRError } from '../../config/declarations';

const ReserveErrors: FC<IEcRError> = ({
  block,
  slug,
  telephone,
  psychic,
  handleCallback,
  cancel,
}) => {
  if (!block) {
    return null;
  }

  const text = block?.contentTypesCollection?.items as Array<Text> | undefined;
  const filterText = text?.filter((item) => item?.slug === slug)!;
  const number = telephone?.phoneNumber ?? '';
  const cleanNumber: string = formatPhoneNumber(number) ?? '';
  const errorheading = filterText[0]?.text;
  const updateMessage = (filterText[0]?.fullText?.replace(/{number}/g, cleanNumber))?.replace(/{telNumber}/g, number);
  const errorMessage = updateMessage;

  const createCallback = () => {
    try {
      const pageUrl = window.location.origin;
      window.location.href = `${pageUrl}/purchase-reading?extid=${psychic?.extId}`;
    } catch (error) {
      console.log('Error');
    }
  };

  return (
    <div className={styles.reserveError}>
      <h2 className={styles.errorHeading}>{errorheading}</h2>
      <p className={styles.errorMessage}>{parse(errorMessage!)}</p>
      {
        errorheading === 'Low minutes'
          ? (
            <div className={styles.callbackError}>
              <button
                type="button"
                onClick={handleCallback}
                className={styles.talk}
              >
                Talk Now
              </button>
              <button
                type="button"
                onClick={createCallback}
                className={styles.talk}
              >
                Create Callback
              </button>
              <button
                type="button"
                onClick={cancel}
                className={styles.talk}
              >
                Cancel
              </button>
            </div>
          ) : null
      }
    </div>
  );
};

export default ReserveErrors;
