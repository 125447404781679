import React, {
  ChangeEvent,
  FC,
  FormEvent,
  useEffect,
  useState,
} from 'react';
import cn from 'classnames';
import { BLOCKS, MARKS } from '@contentful/rich-text-types';

import type { Text } from 'src/__generated__/graphqlTypes';
import {
  DataButton,
  WebpImage,
  CommonRichText,
} from 'components/Shared/SharedComponents';
import {
  IdNameType,
  ValidationAndValue,
  RichTextParsersConfig,
} from 'types/objectTypes';
import { commonEmailRegexp } from 'lib/regexp.service';
import { EXTERNAL_WEBP_IMAGE, TEXTAREA_MAX_LENGTH } from 'constants/constants';
import classes from 'src/styles/_commonClasses.module.scss';

import styles from './Form.module.scss';
import ZendeskModal from './ZendeskModal';
import FeedbackFormSelect from './Select';
import { FeedbackFormInput, FeedbackFormTextarea } from './Input';

import { FeedbackFormInterfaceProps, ErrorHandler } from '../config/declarations';
import {
  checkInputDataInvalidity,
  getCommonValidationAndError,
  checkInputDataValidity,
} from '../lib';
import { FeedbackFormStateValue } from '../config/constants';

const getParsersConfig = (): RichTextParsersConfig => ({
  [BLOCKS.HEADING_2]: {
    classNames: cn(styles.dailyText, styles.dailyTextTitle),
  },
  [BLOCKS.HEADING_3]: {
    classNames: cn(styles.dailyText, styles.dailyTextTitle),
  },
  [MARKS.BOLD]: {
    classNames: classes.textBold,
  },
});

const FeedbackForm: FC<FeedbackFormInterfaceProps> = ({
  requestTypeOptions,
  title,
  richTitle,
  button,
  modal,
  isModalVisible,
  inputValues,
  setInputValues,
  closeModal,
  handleSubmitForm,
  textList,
}) => {
  const [isInputsValid, setInputsValidState] = useState<boolean>(false);

  const handleInputChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    errorHandler: ErrorHandler = getCommonValidationAndError,
    pattern?: RegExp | string | null,
  ) => {
    const { name, value } = e?.target || {};
    const inputItem: ValidationAndValue<any> = inputValues[name];
    const { error, isValid, validation } = errorHandler(inputItem, pattern, value);

    const values = {
      ...inputValues,
      [name]: {
        ...inputItem,
        value,
        isValid: (isValid !== null) ? validation : null,
        error,
      },
    };
    setInputValues(values);
  };

  const onBlur = (
    name: FeedbackFormStateValue,
    errorHandler: ErrorHandler = getCommonValidationAndError,
    pattern?: RegExp | string | null,
  ) => setInputValues((prev) => {
    const prevItem = prev[name];
    const { validation, error } = errorHandler(prevItem, pattern);

    return ({
      ...prev,
      [name]: {
        ...prevItem,
        isValid: validation,
        error,
      },
    });
  });

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const isInvalid = checkInputDataInvalidity(inputValues, setInputValues);

    if (!isInvalid) {
      handleSubmitForm(inputValues);
    }
  };

  const setSelectValue = (
    name: FeedbackFormStateValue,
    value: string | IdNameType<string>,
  ) => {
    setInputValues((inputValues) => ({
      ...inputValues,
      [name]: {
        ...inputValues[name],
        isValid: true,
        value,
      },
    }));
  };

  const setRequestType = (requestType: Text) => setSelectValue(
    FeedbackFormStateValue.REQUEST_TYPE,
    { id: requestType.slug!, name: requestType.text! },
  );

  useEffect(
    () => {
      setInputsValidState(checkInputDataValidity(inputValues));
    }, [inputValues],
  );

  const { image } = button;

  const imageComponent = (
    image && (
      <WebpImage
        image={EXTERNAL_WEBP_IMAGE}
        src={image.url!}
        width="auto"
        height="22px"
        className={styles.formSubmitImage}
      />
    )
  );

  const submitClass = !isInputsValid ? styles.formSubmit : styles.formSubmitValid;

  return (
    <form
      id={styles.form}
      className={styles.form}
      onSubmit={handleSubmit}
    >
      <div
        className={styles.formFieldset}
      >
        <h1 className={styles.formFieldsetTitle}>
          {title}
        </h1>
        {richTitle && (
          <div className={styles.formFieldsetBrief}>
            <CommonRichText
              content={richTitle}
              parsersConfig={getParsersConfig()}
            />
          </div>
        )}
        <div className={styles.formFieldsetLabel}>
          {textList && (textList[1] || '')}
        </div>
        <FeedbackFormInput
          name={FeedbackFormStateValue.EMAIL}
          inputData={inputValues.email}
          onChange={(e) => handleInputChange(
            e,
            getCommonValidationAndError,
            commonEmailRegexp,
          )}
          onBlur={() => onBlur(
            FeedbackFormStateValue.EMAIL,
            getCommonValidationAndError,
            commonEmailRegexp,
          )}
        />
        <FeedbackFormSelect
          title={inputValues.requestType.placeholder!}
          selectOptions={requestTypeOptions!}
          inputData={inputValues.requestType}
          onBlur={() => onBlur(FeedbackFormStateValue.REQUEST_TYPE)}
          setInputValue={setRequestType}
        />
        <div className={styles.formFieldsetLabel}>
          {textList && (textList[2] || '')}
        </div>
        <FeedbackFormTextarea
          name={FeedbackFormStateValue.MESSAGE}
          inputData={inputValues.message}
          onChange={(e) => handleInputChange(e)}
          onBlur={() => onBlur(FeedbackFormStateValue.MESSAGE)}
          className={styles.formFieldsetDivInputBig}
          maxLength={TEXTAREA_MAX_LENGTH}
        />
      </div>
      {button && (
        <DataButton
          type="submit"
          form={styles.form}
          className={submitClass}
          style={{ color: button.color! }}
        >
          {imageComponent}
          {button.title}
        </DataButton>
      )}
      <ZendeskModal
        modal={modal}
        isModalVisible={isModalVisible}
        closeModal={closeModal}
      />
    </form>
  );
};

export default FeedbackForm;
