import { FC, useState } from 'react';
import { BLOCKS, MARKS } from '@contentful/rich-text-types';
import cn from 'classnames';

import classes from 'src/styles/_commonClasses.module.scss';
import { CommonRichText, DataLink } from 'components/Shared/SharedComponents';
import type { Link } from 'src/__generated__/graphqlTypes';
import { CollapseIcon } from 'src/shared/ui/CollapseIcon';

import styles from './TopicsLinks.module.scss';

import type { ITopic } from '../config/declarations';

const parsersConfig = {
  [BLOCKS.HEADING_2]: {
    classNames: styles.title,
  },
  [BLOCKS.HEADING_3]: {
    classNames: styles.title,
  },
  [MARKS.BOLD]: {
    classNames: classes.textBold,
  },
};

const Topic: FC<ITopic> = ({ block }) => {
  const [isCollapsed, setCollapsedState] = useState<boolean>(true);
  const links = block.contentTypesCollection?.items as Array<Link>;

  return (
    <section className={cn(
      styles.topic,
      { [styles.collapsed]: isCollapsed },
    )}
    >
      <button
        type="button"
        className={styles.collapse}
        onClick={() => setCollapsedState((prev) => !prev)}
      >
        <CollapseIcon width={25} isExpanded={!isCollapsed} />
      </button>
      <CommonRichText parsersConfig={parsersConfig} content={block.richTitle} />
      <ul className={styles.list}>
        {links.map((link, index) => (
          <li key={`${link._id}${index.toString()}`} className={styles.link}>
            <DataLink link={link}>
              {link.title}
            </DataLink>
          </li>
        ))}
      </ul>
    </section>
  );
};

export default Topic;
