import { FC } from 'react';
import { useSelector } from 'react-redux';

import { SectionComponentInterface } from 'types/componentTypes';
import { Store } from 'app-redux/types/storeTypes';
import { CircleImageDescriptionButtonsClone } from 'components/Sections/CircleImageDescriptionButtonClone';
import { ContentfulSlugsNoArrows } from 'types/objectTypes';
import { Block } from 'src/__generated__/graphqlTypes';
import { useCommonContentfulBlocks } from 'lib/shared.hook';
import { getZodiacSign } from 'lib/sharedMethods.service';

const CircleImageDescriptionButtonContainer: FC<SectionComponentInterface> = ({
  blocks,
  bgColor,
  extraData,
}) => {
  const {
    content,
    topDivider,
    bottomDivider,
    commonPageMaxWidth,
  } = useCommonContentfulBlocks<ContentfulSlugsNoArrows, Block>(blocks);
  const slug = useSelector((store: Store) => store.server.page.slug);
  const zodiacSign = getZodiacSign(slug);

  return (
    <CircleImageDescriptionButtonsClone
      bgColor={bgColor}
      zodiacSign={zodiacSign}
      content={content}
      extraData={extraData}
      topDivider={topDivider}
      bottomDivider={bottomDivider}
      commonPageMaxWidth={commonPageMaxWidth}
    />
  );
};

export default CircleImageDescriptionButtonContainer;
