import {
  FC,
  useReducer,
  useState,
  useEffect,
} from 'react';
import Modal from 'react-modal';
import cn from 'classnames';
import { useSelector } from 'react-redux';

import classes from 'styles/_commonClasses.module.scss';
import type { Block } from 'src/__generated__/graphqlTypes';
import { Store } from 'app-redux/types/storeTypes';
import { getGaClientId } from 'src/shared/lib/analytics/ga';
import LocalLoader from 'src/shared/ui/LocalLoader/LocalLoader';

import ECPsychicPhoto from './ECTalkPsychicPhoto';
import styles from './styles.module.scss';
import Calling from './Calling';
import ReserveErrors from './ReserveErrors';
import ECTelephone from './ECTalkTelephone';

import { useMessageButtonApis, formatPhoneNumber } from '../../lib/message';
import {
  useCallbackFunction,
  useAddNumberFunction,
  useCallbackReserveFunction,
  useSiteInfoFunction,
} from '../../lib/talkHooks';
import {
  IEcTModal,
  IEcCallback,
  IEcTReservation,
  IEcTSiteInfo,
  IECUpdateNumber,
} from '../../config/declarations';
import { messageButtonModalReducer } from '../../lib/reducers';
import {
  messageButtonContext,
  MessageContext,
  MessageContextDispatch,
} from '../../lib/messageContext';

const TalkModal: FC<IEcTModal> = ({
  psychic,
  isOpened,
  psychicFrame,
  block,
  primaryNumber,
  closeModal,
  setOpenedState,
  setPrimaryNumber,
}) => {
  const addNewNumber = useAddNumberFunction();
  const { getSiteInfo } = useSiteInfoFunction();
  const { talkReserve } = useCallbackReserveFunction();
  const { callback } = useCallbackFunction();
  const {
    errors,
    phone,
  } = (block?.contentTypesCollection?.items as Array<Block>)
    ?.reduce((store, item) => {
      if (item.__typename !== 'Block') return store;

      if (item.slug) {
        return { ...store, [item.slug]: item };
      }

      return store;
    }, {} as Record<string, Block>) || {} as Record<string, Block>;
  const [store, localDispatch] = useReducer(messageButtonModalReducer, messageButtonContext);
  const { shouldBeShown } = useMessageButtonApis(localDispatch, isOpened);
  const [showCalling, setShowCalling] = useState<boolean>(false);
  const [isChecked, setIsChecked] = useState<boolean>(false);
  const customerID = useSelector((store: Store) => store.server.auth.user?.custId);
  const [showReserve, setShowReserve] = useState<boolean>(false);

  const [error, setError] = useState<string>();
  const [callingNumber, setCallingNumber] = useState<string>();
  const [reserveNumber, setReserveNumber] = useState<string>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [telephone, setTelephone] = useState<any>('');
  const [webInfo, setWebInfo] = useState<any>('');
  const [slug, setSlug] = useState<string>();

  useEffect(() => {
    if (telephone !== '') {
      if (primaryNumber === '') {
        setPrimaryNumber?.(telephone?.phoneNumber);
        setIsChecked(true);
      } else if (primaryNumber === telephone?.phoneNumber) {
        setIsChecked(true);
      } else {
        setIsChecked(false);
      }
    }
  }, [telephone]);

  useEffect(() => () => {
    setOpenedState(false);
  }, []);

  if (!shouldBeShown) {
    return null;
  }

  const callPsychicFromMobile = async (siteInfo: IEcTSiteInfo, phoneNumber: string | undefined) => {
    try {
      const clientID = getGaClientId(document.cookie);
      const custId = customerID;
      const saleLocation = 'Mobile';
      const customerPhoneNumber = telephone?.phoneNumber;
      const PhoneType = telephone?.phoneType;
      const phoneCountryCode = telephone?.countryCode;
      const extId = psychic?.extId;

      const forceCallback: boolean = siteInfo?.mobileTalkOption > 0;
      const overrideForceCallback = false;

      if (!customerPhoneNumber || !PhoneType || !phoneCountryCode) {
        setIsLoading((prev) => !prev);

        return;
      }

      const cleanedPhoneNumber = formatPhoneNumber(customerPhoneNumber);
      setReserveNumber(phoneNumber);
      setCallingNumber(cleanedPhoneNumber);
      const details: IEcCallback = {
        customerId: custId,
        extensionId: extId,
        phone: customerPhoneNumber,
        countryCode: phoneCountryCode,
        phoneType: PhoneType,
        duration: 10,
        saleLocation,
        clientID,
        forceCallback,
        overrideForceCallback,
      };
      try {
        const res: any = await callback(details);

        if (res?.data?.callbackError === '') {
          if (
            psychic.lineStatus === 'available'
              || (psychic.lineStatus === 'OnCall' && !psychic.peopleInQueue)
              || (psychic.lineStatus === 'OnCall' && psychic.peopleInQueue === 0)
          ) {
            // call connected
            setShowCalling((prev) => !prev);
          } else if (psychic.lineStatus === 'OnCall' && !psychic.customerPlaceInQueue && psychic.peopleInQueue === 1) {
            setError(`'Error', ${res?.data?.callbackError}`);
            setTimeout(() => {
              setError('');
            }, 2000);

            return;
          }
        } else {
          setSlug('minutes');
          setShowReserve((prev) => !prev);
        }
      } catch (error) {
        setError('Error');
        setTimeout(() => {
          setError('');
        }, 2000);
      }
    } catch (error) {
      setError('Error');
      setTimeout(() => {
        setError('');
      }, 2000);
    }
  };

  const checkReserveCustomerCallbackResponse = (status: number,
    reservationResult: IEcTReservation, siteInfo: IEcTSiteInfo, phoneNumber: string) => {
    try {
      switch (status) {
        case 1:
          setIsLoading((prev) => !prev);
          callPsychicFromMobile(siteInfo, phoneNumber);

          break;
        case 2:
          if (!reservationResult.isSuccess
            && reservationResult.errorCode > 0
            && reservationResult.reservationId === 0) {
            if (reservationResult.errorMessage.includes('exceeded your purchase limit')) {
              setIsLoading((prev) => !prev);
              setSlug('depositFailed');
              setShowReserve((prev) => !prev);
            }

            setIsLoading((prev) => !prev);
            setSlug('exceededLimit');
            setShowReserve((prev) => !prev);
          } else {
            setIsLoading((prev) => !prev);
            callPsychicFromMobile(siteInfo, phoneNumber);
          }

          break;
        case 3:
          setIsLoading((prev) => !prev);
          setShowReserve((prev) => !prev);
          setSlug('queueFull');

          break;
        case 4:
          setIsLoading((prev) => !prev);
          setShowReserve((prev) => !prev);
          setSlug('discountedPsychic');

          break;
        case 5:
          setIsLoading((prev) => !prev);
          setShowReserve((prev) => !prev);
          setSlug('inQueue');

          break;
        case 6:
          setIsLoading((prev) => !prev);
          setShowReserve((prev) => !prev);
          setSlug('pendingCallbacks');

          break;
        default:
          setIsLoading((prev) => !prev);
          setShowReserve((prev) => !prev);
          setSlug('oops');

          break;
      }
    } catch (error) {
      setIsLoading((prev) => !prev);
      setError('Error');
      setTimeout(() => {
        setError('');
      }, 2000);
    }
  };

  const updateNumber = async (selectedCountry: IECUpdateNumber,
    phoneNumber: string, isVerified: boolean, primary: number) => {
    try {
      const extId = psychic?.extId;
      const currentUrl = window.location.href;
      const referrerUrl = '';
      const windowWidth = window.innerWidth;
      let siteInfo;
      let reservationResult;
      let status;
      let phoneApiStatus;
      setIsLoading((prev) => !prev);

      getSiteInfo(currentUrl, referrerUrl, windowWidth)
        .then((res) => {
          siteInfo = res;

          if (!res) {
            setIsLoading((prev) => !prev);
            setError('siteinfo api failed');
            setTimeout(() => setError(''), 2000);

            return;
          }

          return addNewNumber(selectedCountry, phoneNumber, isVerified, primary);
        })
        .then((addNumber) => {
          phoneApiStatus = addNumber;

          if (!addNumber) {
            setIsLoading((prev) => !prev);
            setError('addNewNumber api failed');
            setTimeout(() => setError(''), 2000);

            return;
          }

          return talkReserve(customerID || '', extId);
        })
        .then((callReserve) => {
          reservationResult = callReserve;
          status = parseInt(callReserve.result, 10);

          if (!callReserve) {
            setIsLoading((prev) => !prev);
            setError('callReserve api failed');
            setTimeout(() => setError(''), 2000);

            return;
          }

          if (status === 7) {
            status = 2;
          }

          if (phoneApiStatus === true && primary === 1) {
            setPrimaryNumber?.(phoneNumber);
          }

          // all calls were successful
          checkReserveCustomerCallbackResponse(status, reservationResult, siteInfo, phoneNumber);
        })
        .catch((error) => {
          setIsLoading((prev) => !prev);
          setError(`An error occurred${error?.message}`);
          setTimeout(() => setError(''), 2000);
        });
    } catch (error) {
      setIsLoading((prev) => !prev);
      setError('Error');
      setTimeout(() => setError(''), 2000);

      return undefined;
    }
  };

  const reserveCall = async (phoneNumber: string) => {
    try {
      const extId = psychic?.extId;
      const currentUrl = window.location.href;
      const referrerUrl = '';
      const windowWidth = window.innerWidth;
      let siteInfo;
      let reservationResult;
      let status;
      setIsLoading((prev) => !prev);

      getSiteInfo(currentUrl, referrerUrl, windowWidth)
        .then((res) => {
          siteInfo = res;

          if (!res) {
            setError('siteinfo api failed');
            setTimeout(() => setError(''), 2000);
            setIsLoading((prev) => !prev);

            return;
          }

          return talkReserve(customerID || '', extId);
        })
        .then((callReserve) => {
          reservationResult = callReserve;
          status = parseInt(callReserve.result, 10);

          if (!callReserve) {
            setError('callReserve api failed');
            setTimeout(() => setError(''), 2000);
            setIsLoading((prev) => !prev);

            return;
          }

          if (status === 7) {
            status = 2;
          }

          setWebInfo(siteInfo);

          // all calls were successful
          checkReserveCustomerCallbackResponse(status, reservationResult, siteInfo, phoneNumber);
        })
        .catch((error) => {
          setIsLoading((prev) => !prev);
          setError(`An error occurred${error?.message}`);
          setTimeout(() => setError(''), 2000);
        });
    } catch (error) {
      setIsLoading((prev) => !prev);
      setError('Error');
      setTimeout(() => setError(''), 2000);

      return undefined;
    }
  };

  const talkNow = () => {
    try {
      const phoneInput = document.getElementById('phone') as HTMLInputElement | null;
      const status = phoneInput === null ? 1 : 2;
      const primary = isChecked ? 1 : 0;
      const number = status === 1 ? telephone?.phoneNumber : phoneInput?.value;
      const cleanedNumber = number?.replace(/-/g, '');
      const isVerified = true;

      if (cleanedNumber !== undefined) {
        if (phoneInput) {
          setTimeout(() => {
            const selectElement = document.getElementById('code') as HTMLSelectElement | null;
            let country; let
              countrycode;

            if (selectElement) {
              const selectedOption = selectElement.options[selectElement.selectedIndex];
              country = selectedOption.value;
              countrycode = parseInt(selectedOption.text.split('+')[1], 10);
            }

            const telephoneModal = document.getElementById('telephoneModal') as HTMLInputElement | null;

            if (!telephoneModal) {
              const selectedCountry = {
                countryCallingCode: countrycode,
                countryCode: country,
              };

              if (cleanedNumber.length === 10) {
                updateNumber(selectedCountry, cleanedNumber, isVerified, primary);
              }
            }
          }, 1000);
        } else if (telephone?.phoneNumber === primaryNumber) {
          reserveCall(cleanedNumber);
        } else {
          const selectedCountry = {
            countryCallingCode: telephone.countryCallingCode,
            countryCode: telephone.countryCode,
          };
          updateNumber(selectedCountry, cleanedNumber, isVerified, primary);
        }
      }
    } catch (error) {
      setError('Error');
      setTimeout(() => {
        setError('');
      }, 2000);
    }
  };

  const resumeTalk = () => {
    try {
      setShowCalling(false);
    } catch (error) {
      setError('Error');
      setTimeout(() => {
        setError('');
      }, 2000);
    }
  };

  const handleChange = () => {
    try {
      setIsChecked((prev) => !prev);
    } catch (error) {
      setError('Error');
      setTimeout(() => {
        setError('');
      }, 2000);
    }
  };
  const closeReserve = () => {
    try {
      setShowReserve((prev) => !prev);
    } catch (error) {
      setError('Error');
      setTimeout(() => {
        setError('');
      }, 2000);
    }
  };

  const handleCallback = () => {
    callPsychicFromMobile(webInfo, reserveNumber);
  };
  const cancel = () => {
    closeModal();
    resumeTalk();
  };

  return (
    <MessageContext.Provider value={store}>
      <MessageContextDispatch.Provider value={localDispatch}>
        <Modal
          isOpen={isOpened}
          className={cn(styles.modalTalk, classes.modal)}
          overlayClassName={classes.modalBackground}
          shouldFocusAfterRender={false}
        >
          {showReserve ? (
            <ReserveErrors
              block={errors}
              telephone={telephone}
              slug={slug}
              handleCallback={handleCallback}
              psychic={psychic}
              cancel={cancel}
            />
          ) : (
            <>
              <ECPsychicPhoto
                psychicFrame={psychicFrame}
                psychic={psychic}
                block={block}
              />
              {!showCalling
                ? (
                  <>
                    <ECTelephone
                      setTelephone={setTelephone}
                      telephone={telephone}
                      block={phone}
                    />
                    <div className={styles.primaryCheckbox}>
                      <input
                        type="checkbox"
                        className={styles.talkModalInput}
                        checked={isChecked}
                        onChange={handleChange}
                      />
                      <span className={styles.primary}>{block?.title}</span>
                    </div>
                    <button type="button" className={styles.talk} onClick={talkNow}>
                      {block?.link?.title}
                    </button>
                  </>
                )
                : (
                  <Calling
                    telephone={telephone}
                    image={block.mobileImage?.url || ''}
                    callingNumber={callingNumber}
                  />
                )}
            </>
          )}
          <button
            type="button"
            className={showCalling
              ? styles.callingClose
              : styles.closeButton}
            onClick={showReserve ? closeReserve : () => {
              closeModal();
              resumeTalk();
            }}
          >
            {showReserve === true ? 'Back' : 'Close'}
          </button>
          {error && <span className={styles.error}>{error}</span>}
          {
            isLoading
            && (
              <div className={styles.talkLoader}>
                <div className={styles.load}>
                  <LocalLoader />
                </div>
              </div>
            )
          }
        </Modal>
      </MessageContextDispatch.Provider>
    </MessageContext.Provider>
  );
};

export default TalkModal;
