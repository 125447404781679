/* eslint-disable no-unused-expressions */
import { useSelector, useDispatch } from 'react-redux';
import debounce from 'lodash/debounce';
import React, {
  CSSProperties,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

import { MOUSEDOWN_EVENT, RESIZE } from 'constants/constants';
import { Store } from 'app-redux/types/storeTypes';
import { setClientWidth, setMobileViewWidthState } from 'app-redux/actions/appActions';
import { GAReplacementValue, ViewerDevice } from 'constants/enums';
import { getStyles, setItemInObjectBySlug } from 'lib/sharedMethods.service';
import { parseJsonProperties } from 'src/lib/json.service';
import {
  Block,
  ImageWrapper,
  Link,
} from 'src/__generated__/graphqlTypes';
import { contentfulSlugs } from 'constants/objects';
import { Segment } from 'lib/external/segment';
import { resetClientWidthCookie } from 'src/shared/lib/cookie';

export const useClickOutside = (
  elementRef: React.MutableRefObject<any>,
  handler: (...rest: any) => void,
  ...handlerData: Array<any>
) => {
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (!elementRef?.current?.contains(event.target)) {
        handler(...handlerData);
      }
    };

    document.addEventListener(MOUSEDOWN_EVENT, handleClickOutside);

    return () => document.removeEventListener(MOUSEDOWN_EVENT, handleClickOutside);
  }, [elementRef, handler]);
};

/** {@link useClickOutside} should use original method, this was done for fast fix */
export const useClickOutsideMultiple = (
  elementRef: React.MutableRefObject<any>[],
  handler: (...rest: any) => void,
  ...handlerData: Array<any>
) => {
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const isClickOutside = elementRef.every(
        (ref) => ref.current && !ref.current.contains(event.target),
      );

      if (isClickOutside) {
        handler(...handlerData);
      }
    };

    document.addEventListener(MOUSEDOWN_EVENT, handleClickOutside);

    return () => document.removeEventListener(MOUSEDOWN_EVENT, handleClickOutside);
  }, [elementRef, handler]);
};

export const useWatchMobileViewWidth = (
  mobileViewMaxWidth: number,
  isMobileViewWidth: boolean,
): void => {
  const dispatch = useDispatch();
  const viewerDevice = useSelector((store: Store) => store.server.app.viewerDevice);
  const clientWidth = useSelector((store: Store) => store.client.app.clientWidth);

  const setMobileWidthIfRequired = useCallback(() => {
    const isNowMobileViewWidth = mobileViewMaxWidth >= document.body.clientWidth;

    if (isMobileViewWidth !== isNowMobileViewWidth && viewerDevice === ViewerDevice.DESKTOP) {
      dispatch(setMobileViewWidthState(isNowMobileViewWidth));
    }
  },
  [
    mobileViewMaxWidth,
    isMobileViewWidth,
    viewerDevice,
    dispatch,
  ]);

  const setClientWidthIfRequired = useCallback(debounce(() => {
    if (clientWidth !== document.body.clientWidth) {
      dispatch(setClientWidth(document.body.clientWidth));
      resetClientWidthCookie();
    }
  }, 300), [dispatch, clientWidth]);

  useEffect(() => {
    setMobileWidthIfRequired();
    setClientWidthIfRequired();

    const handler = debounce(() => {
      setMobileWidthIfRequired();
      setClientWidthIfRequired();
    }, 50);
    window.addEventListener(RESIZE, handler);

    return () => window.removeEventListener(RESIZE, handler);
  }, [setMobileWidthIfRequired, setClientWidthIfRequired]);
};

export const useCommonDataButtonsProps = (
  gaData?: Partial<Record<GAReplacementValue, string>>,
  link?: Link,
) => {
  const [pathName, setPathName] = useState<string>('');
  useEffect(() => {
    setPathName(window.location.pathname);
  }, [setPathName]);

  const baseUrl = process.env.BASE_SERVER_URL || process.env.NEXT_PUBLIC_BASE_SERVER_URL;
  const styles = getStyles(link);
  const replacementEntry = gaData
    ? {
      [GAReplacementValue.SHORT_URL]: gaData[GAReplacementValue.SHORT_URL] || pathName,
      [GAReplacementValue.FULL_URL]: gaData[GAReplacementValue.FULL_URL] || `${baseUrl}${pathName}`,
      ...gaData,
    }
    : {
      [GAReplacementValue.SHORT_URL]: pathName,
      [GAReplacementValue.FULL_URL]: `${baseUrl}${pathName}`,
    };
  const parsedGaProperties = parseJsonProperties(link?.gaProperties, replacementEntry);

  return { styles, parsedGaProperties };
};

export const useCommonContentfulBlocks = <T extends string, K>(
  blocks: Array<Block> = [],
  slugs: typeof contentfulSlugs = contentfulSlugs,
): Record<T, K> & { commonPageMaxWidth?: string; } => {
  const reducer = setItemInObjectBySlug(slugs);
  const { commonPageMaxWidth } = useSelector((store: Store) => store.server.app.scssVariables);
  const content = blocks.reduce(
    reducer,
    {} as Record<T, K>,
  );

  return { ...content, commonPageMaxWidth };
};

export const useSegmentAnalytics = () => {
  const lastLocation = useRef<string>('');
  const slug = useSelector((store: Store) => store.server.page.slug);

  useEffect(() => {
    const handleAnalytics = () => {
      if (lastLocation.current === window.location.pathname) {
        return;
      }

      const referrer = (lastLocation.current)
        ? `${process.env.NEXT_PUBLIC_BASE_SERVER_URL}${lastLocation.current}`
        : document.referrer;
      const { search, href, pathname } = window.location;
      Segment.page({ referrer, path: pathname, url: href, search });
      lastLocation.current = window.location.pathname;
    };
    handleAnalytics();
  }, [slug]);
};

export const useRefState = <T>(initial: T): [T, (value: T) => void] => {
  const ref = useRef<T>(initial);

  const setValue = (value: T) => {
    ref.current = value;
  };

  return [ref.current, setValue];
};

export const useButtonBgImageStyles = (link?: Block['link']) => {
  const viewerDevice = useSelector((store: Store) => store.server.app.viewerDevice);

  if (!link || !link.backgroundsCollection?.items?.length) {
    return null;
  }

  const backgrounds = link.backgroundsCollection.items
    .reduce((store, image) => ({
      ...store,
      [image?.slug || '']: image!,
    }), {} as Record<string, ImageWrapper | undefined>);

  const currentBackground = backgrounds[viewerDevice] || backgrounds.desktop || null;

  if (!currentBackground?.image?.url) {
    return null;
  }

  const { image } = currentBackground;
  const style: CSSProperties = {
    background: `url(${currentBackground!.image!.url})`,
    height: `${currentBackground.height || image.height}px`,
    width: `${currentBackground.width || image.width}px`,
  };

  return style;
};
