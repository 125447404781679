import { FC } from 'react';
import { BLOCKS } from '@contentful/rich-text-types';
import cn from 'classnames';
import { Carousel } from 'react-responsive-carousel';
import { useSelector } from 'react-redux';

import type { Store } from 'src/redux/types/storeTypes';
import classes from 'src/styles/_commonClasses.module.scss';
import { ColorTheme } from 'constants/enums';
import { SectionComponentInterface } from 'types/componentTypes';
import { CommonRichText } from 'components/Shared/SharedComponents';
import { RichTextInterface } from 'components/Shared/declarations';
import { RichTextParsersConfig } from 'types/objectTypes';
import { capitalizeFirstLetter } from 'lib/text.service';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { mapBlocksSpan } from 'lib/richTextMappers';

import styles from './ContentWithSubBlocks.module.scss';

const getParsersConfig = (): RichTextParsersConfig => ({
  [BLOCKS.HEADING_1]: {
    mapper: mapBlocksSpan,
    classNames: cn(styles.title, styles.titleH1),
  },
  [BLOCKS.HEADING_2]: {
    mapper: mapBlocksSpan,
    classNames: cn(styles.title, styles.titleH2),
  },
  [BLOCKS.HEADING_3]: {
    mapper: mapBlocksSpan,
    classNames: cn(styles.title, styles.titleH3),
  },
  [BLOCKS.PARAGRAPH]: {
    classNames: styles.paragraph,
  },
});

const Content: FC<RichTextInterface> = ({ richText, config }) => {
  if (!richText) {
    return null;
  }

  return (
    <CommonRichText
      content={richText}
      parsersConfig={config}
    />
  );
};

const createContentList = (
  contentTypesCollection,
  isMobileViewWidth,
) => contentTypesCollection.items.reduce((acc, item, index) => {
  if (item?.slug === 'divider' && isMobileViewWidth) return acc;

  const content = item?.slug === 'divider'
    ? <div key={index.toString()} className={styles.divider} />
    : (
      <div key={item.sys?.id} className={styles.statistics}>
        <Content richText={item.content} config={getParsersConfig()} />
      </div>
    );
  acc.push(content);

  return acc;
}, []);

const Title = Content;

const ContentWithSubBlocks: FC<SectionComponentInterface> = ({
  blocks,
  bgColor,
  extraData,
}) => {
  const [block] = blocks;
  const { colorTheme = ColorTheme.BLACK } = extraData || {};
  const isMobileViewWidth = useSelector((store: Store) => store.server.app.isMobileViewWidth);

  return (
    <section
      style={{ background: bgColor }}
      className={cn(styles.wrapper, classes[`themeColor${capitalizeFirstLetter(colorTheme)}`])}
    >
      <div className={styles.content}>
        <Title richText={block?.richTitle} config={getParsersConfig()} />
        <div className={styles.statisticsWrapper}>
          {isMobileViewWidth
            ? (
              <Carousel
                swipeable
                showArrows={false}
                className={styles.blogCarousel}
                showStatus={false}
                infiniteLoop
              >
                {createContentList(block?.contentTypesCollection, isMobileViewWidth)}
              </Carousel>
            )
            : createContentList(block?.contentTypesCollection, isMobileViewWidth)}
        </div>
      </div>
    </section>
  );
};

export default ContentWithSubBlocks;
