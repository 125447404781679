import cookie, { serialize } from 'cookie';

import { setUser } from 'app-redux/actions/authActions';
import { StoreKeeper } from 'app-redux/store/storeKeeper';
import { SESSION_COOKIE_NAME } from 'constants/constants';
import { sessionCookieOptions } from 'lib/session.service';
import { getCustomerDetails } from 'src/api/customerApi';
import { buildPartialAuthObjectFromCustomerDetails } from 'lib/auth.service';
import { Logger } from 'lib/logger';

export const getAuthProviderSessionCookie = () => {
  if (window.location.origin.includes(process.env.NEXT_PUBLIC_CP_DOMAIN || '')
    && process.env.NODE_ENV === 'production') {
    return { ...sessionCookieOptions, domain: process.env.NEXT_PUBLIC_CP_DOMAIN };
  }

  return sessionCookieOptions;
};

export const invalidateSessionRedirect = () => {
  const currentPath = document.location.href;
  const authCookie = cookie.parse(document.cookie)?.[SESSION_COOKIE_NAME];
  const newAuthCookie = authCookie.replace(/("loggedIn":)(true)/g, '$1false');
  document.cookie = cookie
    .serialize(
      SESSION_COOKIE_NAME,
      newAuthCookie,
      { ...getAuthProviderSessionCookie() },
    );
  const store = StoreKeeper.getStore();
  const { user } = store?.getState().server.auth || {};
  const newUser = { ...user || {}, loggedIn: false };
  store.dispatch(setUser(newUser));
  document.cookie = cookie
    .serialize('redirect', currentPath, { secure: true });
  window.location.replace(`${document.location.origin}/sign-in`);
};

export const updateCustomerDetails = async () => {
  let customerDetails;
  try {
    const store = StoreKeeper.getStore();
    const { user } = store?.getState().server.auth || {};
    customerDetails = await getCustomerDetails(user.custId);
    const shouldUserBeUpdated = customerDetails.dollarBalance !== user.dollarBalance
          || customerDetails.availableDollarBalance !== user.availableBalance
          || customerDetails.karmaPoints !== user.karmaPoints
          || customerDetails.enableAutoRecharge !== user.enableAutoRecharge;

    if (!shouldUserBeUpdated) {
      return customerDetails;
    }

    const mappedDetails = buildPartialAuthObjectFromCustomerDetails(customerDetails);
    const newUser = { ...user, ...mappedDetails };
    document.cookie = serialize(
      SESSION_COOKIE_NAME,
      JSON.stringify(newUser),
      getAuthProviderSessionCookie(),
    );
  } catch (e) {
    Logger.error(e);
  }

  return customerDetails;
};
