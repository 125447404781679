import { FC, Fragment } from 'react';
import cn from 'classnames';

import styles from 'src/components/Sections/BreadCrumbs/BreadCrumbs.module.scss';
import { Link } from 'src/__generated__/graphqlTypes';
import { SectionComponentInterface } from 'types/componentTypes';
import { DataLink } from 'components/Shared/SharedComponents';
import { ColorTheme } from 'constants/enums';
import { Tick } from 'src/shared/ui/Tick';

const BreadCrumbs: FC<SectionComponentInterface> = ({
  blocks,
  bgColor,
  extraData,
}) => {
  const [block] = blocks;
  const { colorTheme = ColorTheme.BLACK } = extraData || {};
  const links = (block.contentTypesCollection?.items || []) as Array<Link>;

  return (
    <section className={styles.wrapper} style={{ background: bgColor }}>
      <div className={styles.breadCrumbs}>
        {links.map((link, i) => {
          const isLastLink = (i === links.length - 1);
          const className = (i === 0)
            ? styles.breadCrumbsLinkMain
            : `${styles.breadCrumbsLinkCommon} ${isLastLink ? styles.breadCrumbsLinkActive : ''}`;

          return (
            <Fragment key={link.entryName}>
              <DataLink
                link={link}
                className={cn(styles.breadCrumbsLink, className)}
                style={{ color: colorTheme }}
              >
                {link.title}
              </DataLink>
              { !isLastLink
              && (
                <Tick
                  sideSize={8}
                  direction="right"
                  className={styles.breadCrumbsSeparator}
                  color={colorTheme}
                />
              )}
            </Fragment>
          );
        })}
      </div>
    </section>
  );
};

export default BreadCrumbs;
