/* eslint-disable no-param-reassign */
import { FC, memo } from 'react';
import { BLOCKS, MARKS } from '@contentful/rich-text-types';
import cn from 'classnames';

import type {
  Block,
  Link,
  Psychic,
} from 'src/__generated__/graphqlTypes';
import classes from 'src/styles/_commonClasses.module.scss';
import { RichTextParsersConfig, SectionExtraDataType } from 'types/objectTypes';
import styles from 'components/Sections/PsychicsSetMediumSizeImagesClone/PsychicsSetMediumSizeImages.module.scss';
import {
  CommonRichText,
  DataButton,
  DataLink,
} from 'components/Shared/SharedComponents';
import {
  ElementAlign,
  ItemsLayout,
  PaginationType,
  PsychicCardAppearance,
} from 'constants/enums';
import { capitalizeFirstLetter } from 'lib/text.service';
import Divider from 'components/Sections/Divider';
import { PsychicsSetMediumSizeImagesCloneInterface, PsychicsSetMediumSizeImagesCloneButtons as ButtonsInterface } from 'components/Sections/PsychicsSetMediumSizeImagesClone/declarations';
import LocalLoader from 'src/shared/ui/LocalLoader/LocalLoader';
import { useButtonBgImageStyles } from 'lib/shared.hook';

import { reducer } from './lib';
import PaginationControl from './PaginationControl';
import PsychicsRenderer from './PsychicsRenderer';

const getParsersConfig = ({ titleAlign, pAlign }: SectionExtraDataType): RichTextParsersConfig => {
  const titleAlignClass = classes[`titleAlign${capitalizeFirstLetter(titleAlign)}`];
  const pAlignClass = classes[`pAlign${capitalizeFirstLetter(pAlign || titleAlign)}`];
  const getHeadingClasses = () => cn(
    styles.title,
    titleAlignClass,
  );

  return ({
    [BLOCKS.PARAGRAPH]: {
      classNames: cn(styles.paragraph, pAlignClass),
    },
    [BLOCKS.HEADING_1]: {
      classNames: getHeadingClasses(),
    },
    [BLOCKS.HEADING_2]: {
      classNames: getHeadingClasses(),
    },
    [BLOCKS.HEADING_3]: {
      classNames: getHeadingClasses(),
    },
    [MARKS.BOLD]: {
      classNames: classes.textBold,
    },
    [BLOCKS.OL_LIST]: {
      classNames: {
        ul: styles.list,
      },
    },
  });
};

/* Nested component */
const Buttons: FC<ButtonsInterface> = ({ primary, secondary }) => {
  const bgImageStyles = useButtonBgImageStyles(primary!);

  if (!primary && !secondary) {
    return null;
  }

  if (primary && secondary) {
    const commonClasses = cn(styles.psychicButton, styles.psychicButtonMobileSmall);

    return (
      <div className={cn(styles.psychicButtonWrapper, styles.psychicButtonMargin)}>
        <DataLink
          link={primary}
          href={primary.src!}
          className={cn(commonClasses, styles.psychicButtonPrimary)}
        >
          {primary.title}
        </DataLink>
        <DataLink
          link={secondary}
          href={secondary.src!}
          className={cn(commonClasses, styles.psychicButtonSecondary)}
        >
          {secondary.title}
        </DataLink>
      </div>
    );
  }

  const link = primary || secondary!;

  return (
    <DataLink
      link={link}
      href={link.src!}
      style={bgImageStyles || {}}
      className={cn(
        styles.psychicButton,
        styles.psychicButtonMargin,
        {
          [styles.psychicButtonPrimary]: Boolean(primary),
          [styles.psychicButtonSecondary]: Boolean(secondary),
          [styles.buttonWithBg]: Boolean(bgImageStyles),
        },
      )}
    >
      {link.title}
    </DataLink>
  );
};

/* Main component */
const PsychicsSetMediumSizeImages: FC<PsychicsSetMediumSizeImagesCloneInterface> = ({
  banner,
  content: block,
  psychics,
  extraData,
  topDivider,
  bootStatus,
  viewerDevice,
  bottomDivider,
  commonPageMaxWidth,
  paginationInfo,
  observerRef,
  loadingScroll,
  isShowLoadMoreButton,
  filters,
  loadMorePsychicHandler,
  changePage,
}) => {
  const {
    titleAlign = ElementAlign.LEFT,
    pAlign,
    psychicCardAppearance = PsychicCardAppearance.SIMPLE,
    itemsLayout = ItemsLayout.ROW,
    paginationType,
  } = extraData || {};
  const {
    richTitle,
    content,
    contentTypesCollection,
    link,
    entryName,
  } = block;
  const contentChildren = contentTypesCollection?.items as Array<Psychic | Link> | undefined;
  const richTextConfig = { titleAlign, pAlign };

  const { psychics: paginationPsychics } = paginationInfo || {};

  const {
    psychicFrame,
    secondaryButton,
    paginationButton,
    paginationBackButton,
    paginationNextButton,
  } = reducer(contentChildren);

  const isPaginationPsychics = paginationPsychics && paginationPsychics.length;
  const psychicsToDisplay = paginationType === PaginationType.SCROLL
    ? psychics : (isPaginationPsychics ? paginationPsychics : psychics) || [];

  return (
    <>
      <Divider
        block={topDivider as Block}
        maxWidth={`${commonPageMaxWidth}px`}
      />
      <div className={styles.psychic} key={entryName}>
        <CommonRichText
          content={richTitle}
          parsersConfig={getParsersConfig(richTextConfig)}
        />
        <CommonRichText
          content={content}
          parsersConfig={getParsersConfig(richTextConfig)}
        />
        {filters}
        <PsychicsRenderer
          banner={banner}
          itemsLayout={itemsLayout}
          bootStatus={bootStatus}
          psychicCardAppearance={psychicCardAppearance}
          psychicFrame={psychicFrame}
          array={psychicsToDisplay}
          viewerDevice={viewerDevice}
        />
        <PaginationControl
          paginationButton={paginationButton}
          paginationBackButton={paginationBackButton}
          paginationNextButton={paginationNextButton}
          paginationType={paginationType}
          paginationInfo={paginationInfo}
          changePage={changePage}
        />
        {paginationType === PaginationType.SCROLL ? (
          <>
            {loadingScroll && psychics?.length > 0 && (
              <LocalLoader />
            )}
            { paginationButton && (
              <DataButton
                className={styles.loadButton}
                onClick={loadMorePsychicHandler}
                style={isShowLoadMoreButton
                  ? {}
                  : { visibility: 'hidden', height: 0 }}
                ref={observerRef}
              >
                {paginationButton.title}
              </DataButton>
            )}
          </>
        ) : (
          <Buttons
            primary={link!}
            secondary={secondaryButton}
          />
        )}
      </div>
      <Divider
        block={bottomDivider as Block}
        maxWidth={`${commonPageMaxWidth}px`}
      />
    </>
  );
};

export default memo(PsychicsSetMediumSizeImages);
