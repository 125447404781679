import { capitalizeFirstLetter } from 'lib/text.service';
import { Pair } from 'types/objectTypes';

const LAST_MONTH_INDEX = 11;

/**
 * {@link https://docs.oracle.com/cd/E41183_01/DR/Date_Format_Types.html Oracle docs}
 *  */
export const getOracleFormattedDate = (
  date: Date | string,
  format: 1 | 2 | 3 | 4 | 5 | 6,
  locale: string = 'default',
  divider: string = '/',
) => {
  const handledDate = new Date(date);

  switch (format) {
    case 1: {
      return `${handledDate.getMonth()}${divider}${handledDate.getDate()}${divider}${handledDate.getFullYear()}`;
    }
    case 2: {
      return `${handledDate.getDate()}${divider}${handledDate.getMonth()}${divider}${handledDate.getFullYear()}`;
    }
    case 3: {
      return `${handledDate.getFullYear()}${divider}${handledDate.getMonth()}${divider}${handledDate.getDate()}`;
    }
    case 4: {
      const month = capitalizeFirstLetter(handledDate.toLocaleString(locale, { month: 'long' }));
      const year = handledDate.getFullYear();
      const day = handledDate.getDate();

      return `${month} ${day}, ${year}`;
    }
    case 5: {
      const month = handledDate.toLocaleString(locale, { month: 'numeric' });
      const year = handledDate.getFullYear();
      const day = handledDate.toLocaleString(locale, { day: 'numeric' });

      return `${month}${divider}${day}${divider}${year}`;
    }
    case 6: {
      const month = handledDate.toLocaleString(locale, { month: 'numeric' });
      const year = handledDate.getFullYear();
      const day = handledDate.toLocaleString(locale, { day: 'numeric' });

      return `${day}${divider}${month}${divider}${year}`;
    }
    default: {
      return handledDate.toDateString();
    }
  }
};

/**
 * Has almost the same usage as {@link getDaysInMonth}, but here you need
 * to manage indexes right, probably it should be +1. If you can use
 * {@link getDaysInMonth} instead
*/
export const getDaysAmountInMonth = (monthIndex?: number, yearIndex?: number) => {
  const date = new Date();
  const month = monthIndex || date.getMonth() + 1;
  const year = yearIndex || date.getFullYear();
  const daysAmount = new Date(year, month, 0).getDate();

  return daysAmount;
};

export const getLocalizedFullMonthDYDate = (locale?: string, date: Date = new Date()) => {
  const currentLocale = locale || 'default';
  const month = capitalizeFirstLetter(date.toLocaleString(currentLocale, { month: 'long' }));
  const daysAmount = getDaysAmountInMonth(date.getMonth() + 1, date.getFullYear());
  const currentDate = date.getDate();

  if (daysAmount < currentDate) {
    const newDay = currentDate - daysAmount;

    if (date.getMonth() === LAST_MONTH_INDEX) {
      const newYear = date.getFullYear() + 1;
      date.setFullYear(newYear);
      date.setMonth(0, newDay);
    } else {
      const newMonthIndex = date.getMonth() + 1;
      date.setMonth(newMonthIndex, newDay);
    }

    const month = capitalizeFirstLetter(date.toLocaleString(currentLocale, { month: 'long' }));
    const year = date.getFullYear();
    const day = date.getDate();

    return `${month} ${day}, ${year}`;
  }

  const year = date.getFullYear();

  return `${month} ${currentDate}, ${year}`;
};

export const formatDateToDateInput = (
  date: Date = new Date(),
  datePeriodForSubtract?: { year?: number, month?: number, day?: number },
) => {
  const year = new Intl.DateTimeFormat(undefined, { year: 'numeric' }).format(date);
  const month = new Intl.DateTimeFormat(undefined, { month: 'numeric' }).format(date);
  const day = new Intl.DateTimeFormat(undefined, { day: '2-digit' }).format(date);

  try {
    if (datePeriodForSubtract) {
      const newYear = datePeriodForSubtract.year
        ? +year - datePeriodForSubtract.year
        : year;
      const newMonth = datePeriodForSubtract.month
        ? +month - datePeriodForSubtract.month
        : month;
      const newDay = datePeriodForSubtract.day
        ? +day - datePeriodForSubtract.day
        : day;

      return `${newYear}-${newMonth}-${newDay}`;
    }

    return `${year}-${month}-${day}`;
  } catch {
    return `${year}-${month}-${day}`;
  }
};

export const getMonths = (
  format: 'short' | 'numeric' | '2-digit' | 'long' | 'narrow' = 'short',
  locale?: string,
  // @ts-ignore
) => [...new Array(12).keys()]
  .map((key) => new Date(0, key).toLocaleString(locale, { month: format }));

export const getMonthsWithIndexes = (
  format: 'short' | 'numeric' | '2-digit' | 'long' | 'narrow' = 'short',
  locale?: string,
  // @ts-ignore
): Array<Pair<string>> => [...new Array(12).keys()]
  .map((key) => ({
    key: key + 1,
    value: new Date(0, key).toLocaleString(locale, { month: format }),
  }));

// @ts-ignore
export const getDefaultDaysInMonthArray = (days: number = 31) => [...new Array(days).keys()]
  .map((key) => key + 1);

export const getYears = (yearsAmount: number) => {
  const currentYear = new Date().getFullYear();

  // @ts-ignore
  return [...new Array(yearsAmount).keys()]
    .map((key) => currentYear - key);
};

export const calculateAge = (birthDate: Date) => {
  const today = new Date();
  const age = today.getFullYear() - birthDate.getFullYear();
  const months = today.getMonth() - birthDate.getMonth();

  if (months < 0 || (months === 0 && today.getDate() < birthDate.getDate())) {
    return age - 1;
  }

  return age;
};

export const getDateWithNewTimezone = (
  timeZone: string,
  date: string | Date = new Date(),
) => new Date((typeof date === 'string' ? new Date(date) : date)
  .toLocaleString('default', { timeZone }));

export const toShortIsoString = (stringDate: string) => {
  const date = new Date(stringDate);
  const pad = (num: number) => (num < 10 ? '0' : '') + num;

  return `${date.getFullYear()
  }-${pad(date.getMonth() + 1)
  }-${pad(date.getDate())}`;
};

export const getHoroscopesTitleDate = (
  clientDate: Date | string = new Date(),
  locale: string = 'default',
) => {
  const date = new Date(clientDate);
  const weekDay = date.toLocaleString(locale, { weekday: 'long' });
  const endDate = getOracleFormattedDate(date, 4, locale);

  return `${weekDay}, ${endDate}`;
};

export const parseDotNetDate = (date: string) => {
  if (!date) {
    return date;
  }

  const dateTimeString = date.replace(/(^\/Date\()|(-0000)|(\)\/)/g, '');
  const dateTime = Number(dateTimeString);

  if (!dateTime) {
    return date;
  }

  return new Date(dateTime);
};

export const getEqualDateForTimezones = (date: Date, locale?: string, timezone?: string) => {
  const dateTimestamp = date.toLocaleString(locale, { timeZone: timezone }).concat(' UTC');
  const dateFromTimestamp = new Date(dateTimestamp);

  const equalDate = (timezone
    && (dateFromTimestamp instanceof Date && !Number.isNaN(dateFromTimestamp.getTime())))
    ? new Date(dateTimestamp)
    : new Date(Date.parse(date.toUTCString()));

  return equalDate;
};

/**
 * It has almost the same usage as {@link getDaysAmountInMonth}, but here you can
 * provide date, that you need
*/
export const getDaysInMonth = (date: Date = new Date()) => {
  const cloned = new Date(date.valueOf());

  return new Date(cloned.getFullYear(), cloned.getMonth() + 1, 0).getDate();
};

export const formatDateWithMonthName = (stringDate: string) => {
  if (!stringDate) {
    return stringDate;
  }

  const match = stringDate.match(/\/Date\((\d+)-\d+\)\//);

  if (!match) {
    return stringDate;
  }

  const timestamp = match[1];

  const date = new Date(parseInt(timestamp, 10));

  return date.toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });
};

export const convertToPST = (date: Date) => {
  if (!(date instanceof Date) || Number.isNaN(date)) {
    return date;
  }

  const dateTime = new Date(date);

  // Get PST timezone offset in minutes and adjust the time
  const pstOffset = 8 * 60; // PST is UTC-8

  return new Date(dateTime.getTime() - pstOffset * 60 * 1000);
};
