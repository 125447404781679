import type { Block, Text } from 'src/__generated__/graphqlTypes';
import { BlockPosition } from 'src/widgets/OrangeAlertBig/config/constants';

export const constructId = (id: Block['blockId'], extraData: string = '') => {
  if (!id) {
    return '';
  }

  return id.concat(extraData);
};

export const getExtraComponents = (array?: Array<Text>) => {
  if (!array?.length) {
    return null;
  }

  return array.reduce((store, component) => {
    if (!component.slug || component.__typename !== 'Text') {
      return store;
    }

    const currentComponentArray = store[component.slug] || [];
    // eslint-disable-next-line no-param-reassign
    store[component.slug] = [...currentComponentArray, component];

    return store;
  }, {} as any) as Record<BlockPosition, Array<Text>>;
};
