import cookie from 'js-cookie';
import { serialize } from 'cookie';

import { Logger } from 'lib/logger';
import { NodeEnv } from 'constants/enums';
import {
  CLIENT_WIDTH,
  PSYCHIC_LIST_OPTION_COOKIE,
  RELOAD_MODULES_ATTEMPTS,
  SORT_ID_COOKIE,
} from 'constants/constants';

export class CookiesBuilder<T> {
  constructor(private defaultOptions: T) {
    this.defaultOptions = defaultOptions;
  }

  public set(name: string, value?: string | object, options?: T) {
    if (!value) {
      return this;
    }

    if (typeof value === 'object') {
      try {
        const serializedCookie = serialize(name, JSON.stringify(value), options as any);
        document.cookie = serializedCookie;
      } catch (e) {
        Logger.error(e);
      }
    } else {
      cookie.set(name, value, { ...this.defaultOptions, ...options });
    }

    return this;
  }

  public remove(name: string, options?: T) {
    cookie.remove(name, { ...this.defaultOptions, ...(options || {}) });
  }
}

export const calculateCookiesDomain = (origin?: string, domainValue?: string) => {
  const isCurrentOriginEqualsToCPName = process.env.NODE_ENV === 'production'
    && origin?.includes(domainValue || process.env.CP_DOMAIN || '');

  if (isCurrentOriginEqualsToCPName) {
    return domainValue || process.env.CP_DOMAIN;
  }
};

export const defaultPsychicListOption = {
  SortBy: '',
  SearchOptions: null,
  SortId: '',
};

export const setIsAutoSearchCookie = (value: boolean | string) => {
  const options = {
    secure: process.env.NODE_ENV === NodeEnv.PROD,
    path: '/',
  };
  const stringValue = typeof value === 'boolean' ? String(value) : value;

  cookie.set('isAutoSearch', stringValue, options);
};

export const updatePsychicListOptionCookie = (key: string, value: string | number | null) => {
  const psychicListOptionCookie = cookie.get(PSYCHIC_LIST_OPTION_COOKIE);
  const options = {
    secure: process.env.NODE_ENV === NodeEnv.PROD,
    path: '/',
  };

  if (psychicListOptionCookie) {
    cookie.set(
      PSYCHIC_LIST_OPTION_COOKIE,
      JSON.stringify({ ...JSON.parse(psychicListOptionCookie), [key]: value }).toString(),
      options,
    );
  } else {
    cookie.set(
      PSYCHIC_LIST_OPTION_COOKIE,
      JSON.stringify({ ...defaultPsychicListOption, [key]: value }).toString(),
      options,
    );
  }
};

export const updatePsychicListOptionCookieMultiple = (changeKeys: object) => {
  const psychicListOptionCookie = cookie.get(SORT_ID_COOKIE);
  const options = {
    secure: process.env.NODE_ENV === NodeEnv.PROD,
    path: '/',
  };

  if (psychicListOptionCookie) {
    const psychicListOptionCookieJson = JSON.parse(psychicListOptionCookie);
    cookie.set(
      PSYCHIC_LIST_OPTION_COOKIE,
      JSON.stringify({ ...psychicListOptionCookieJson, ...changeKeys }),
      options,
    );
  } else {
    cookie.set(
      PSYCHIC_LIST_OPTION_COOKIE,
      JSON.stringify({ ...defaultPsychicListOption, ...changeKeys }),
      options,
    );
  }
};

export const setPageUrlCookie = (pageUrl: string) => {
  const options = {
    secure: process.env.NODE_ENV === 'production',
    path: '/',
  };
  cookie.set('urlPage', JSON.stringify(pageUrl), options);
};

export const resetClientWidthCookie = () => {
  cookie.set(
    CLIENT_WIDTH,
    `${document.body.clientWidth}`,
    {
      expires: 365,
      secure: process.env.NODE_ENV === 'production',
    },
  );
};

export const resetFailedModuleConfirmVisibility = () => {
  const count = cookie.get(RELOAD_MODULES_ATTEMPTS) || 0;
  const newCount = Number(count) + 1;

  cookie.set(
    RELOAD_MODULES_ATTEMPTS,
    `${newCount}`,
    {
      expires: 1 / 24 / 2, // 30 minutes
      secure: process.env.NODE_ENV === 'production',
    },
  );

  return newCount;
};
