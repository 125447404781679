import { FC } from 'react';
import cn from 'classnames';
import { useSelector } from 'react-redux';

import { Store } from 'src/redux/types/storeTypes';
import { WebpImage } from 'components/Shared/SharedComponents';

import type { IHeaderRating } from './declarations';
import styles from './styles.module.scss';

const Rating: FC<IHeaderRating> = ({ rating, className }) => {
  const isMobileViewWidth = useSelector((store: Store) => store.server.app.isMobileViewWidth);

  if (!rating) {
    return null;
  }

  const hasDesktopImage = !isMobileViewWidth || (isMobileViewWidth && !rating.mobileImage);

  return (
    <div className={cn(styles.rating, className)}>
      <span>{rating.title}</span>
      {hasDesktopImage && rating.image && (
        <WebpImage
          image={rating.image}
          className={styles.ratingImage}
        />
      )}
      {!hasDesktopImage && rating.mobileImage && (
        <WebpImage
          image={rating.mobileImage}
          className={styles.ratingImage}
        />
      )}
    </div>
  );
};

export default Rating;
