import { Fragment } from 'react';

import { Logger } from 'lib/logger';

import { resetFailedModuleConfirmVisibility } from '../cookie';

export const getModuleNotLoadedErrorHandler = (
  confirmationText: string,
  warnMessage: string,
) => (
  e: any,
) => {
  const countOfReloadAttemptsVisibleForUser = resetFailedModuleConfirmVisibility();

  if (countOfReloadAttemptsVisibleForUser > 1) {
    return Fragment;
  }

  const isWilling = window.confirm(confirmationText);

  if (isWilling) {
    window.location.reload();
  } else {
    const error = {
      message: warnMessage,
      error: e,
    };
    Logger.warn(error);
  }

  return Fragment;
};
