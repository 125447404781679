import { FC, useState } from 'react';
import cn from 'classnames';

import { DataButton, WebpImage } from 'components/Shared/SharedComponents';

import styles from './styles.module.scss';

import type { IMagnifierButton } from '../config/declarations';

const MagnifierTooltipButton: FC<IMagnifierButton> = ({
  button,
  className,
  tooltipRef,
}) => {
  const [showSearchIcon, setShowSearchIcon] = useState<boolean>(true);

  if (!button) {
    return null;
  }

  const { image } = button;

  return (
    <DataButton
      link={button}
      onClick={() => {
        const tooltip = tooltipRef.current;

        if (!tooltip) {
          return;
        }

        if (!tooltip.isOpen) {
          tooltip.open({ anchorSelect: `.${styles.toggler}` });
          setShowSearchIcon(false);
        } else {
          setShowSearchIcon(true);
          tooltip.close();
        }
      }}
      className={cn(styles.toggler, className)}
    >
      {showSearchIcon && image?.url ? (
        <WebpImage
          src={image.url}
          image="external"
          className={styles.togglerImg}
        />
      ) : <span className={styles.close} />}
    </DataButton>
  );
};

export default MagnifierTooltipButton;
