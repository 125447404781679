import { FC, useState } from 'react';

import { PsychicCardClickableImage } from 'entities/PsychicCardClickableImage';
import type { PsychicComponentInterface } from 'types/componentTypes';

import styles from './PsychicCardTile.module.scss';
import Name from './Name';
import FirstCard from './FirstCard';
import Controls from './Controls';
import SecondCard from './SecondCard';

import type { CardOrder } from '../config/declarations';

const Card: FC<PsychicComponentInterface> = ({
  psychicSkeleton: psychicFrame,
  positionInArray,
  bootStatus,
  psychic,
  user,
}) => {
  const [cardStep, setCardStep] = useState<CardOrder>('first');

  return (
    <li className={styles.wrapper}>
      <PsychicCardClickableImage
        styles={styles}
        psychic={psychic}
        psychicFrame={psychicFrame}
        positionInArray={positionInArray}
      />
      <Name
        psychic={psychic}
        psychicFrame={psychicFrame}
      />
      {(cardStep === 'first')
        ? (
          <FirstCard
            user={user}
            psychic={psychic}
            psychicFrame={psychicFrame}
            bootStatus={bootStatus}
            setCardStep={setCardStep}
          />
        )
        : (
          <SecondCard
            psychic={psychic}
            psychicFrame={psychicFrame}
            setCardStep={setCardStep}
            positionInArray={positionInArray}
          />
        )}
      <Controls
        cardStep={cardStep}
        setCardStep={setCardStep}
      />
    </li>
  );
};

export default Card;
