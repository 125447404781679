import {
  FC,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouter } from 'next/router';

import type { Block } from 'src/__generated__/graphqlTypes';
import type { Store } from 'app-redux/types/storeTypes';
import { HoroscopesAndPredictionsChildInterface } from 'types/componentTypes';
import { CompatibilityCheck } from 'components/Sections/HoroscopesSignsHoroscopesAndPredictions/CompatibilityCheck';
import { replaceContentfulVariables } from 'lib/text.service';
import { CompatibilitySelectsType } from 'components/Sections/HoroscopesSignsHoroscopesAndPredictions/CompatibilityCheck/declarations';
import { getAppropriatePathForResource } from 'lib/tag.service';
import { setLoadingState } from 'app-redux/actions/appActions';

const defaultFilledSelects: CompatibilitySelectsType = { usersSign: null, partnersSign: null };

const CompatibilityCheckContainer: FC<HoroscopesAndPredictionsChildInterface> = ({
  block: compatibilityCheck,
}) => {
  const isMobileViewWidth = useSelector((store: Store) => store.server.app.isMobileViewWidth);
  const isRedirectAvailableRef = useRef<boolean>(false);
  const setRedirectAvailability = (value: boolean) => {
    isRedirectAvailableRef.current = value;
  };
  const {
    mobileViewMaxWidth,
    horoscopeCompatibilitySelectImageSize,
    horoscopeDesktopRightSection,
    horoscopeDesktopLeftSection,
    horoscopeTabletMaxWidth,
    horoscopeMobileImageBackgroundWidth,
  } = useSelector((store: Store) => store.server.app.scssVariables);
  const [
    filledSelects,
    setFilledSelects,
  ] = useState<CompatibilitySelectsType>(defaultFilledSelects);
  const router = useRouter();
  const dispatch = useDispatch();

  useEffect(() => {
    const { usersSign, partnersSign } = filledSelects;
    const src = compatibilityCheck.link?.src;
    const isSignsSelectedAndLinkExists = usersSign && partnersSign && src;
    const isRedirectAvailable = isRedirectAvailableRef.current;

    if (isSignsSelectedAndLinkExists && isRedirectAvailable) {
      const replacementVariables = {
        yourSign: usersSign?.title?.toLowerCase(),
        partnerSign: partnersSign?.title?.toLowerCase(),
      };
      const parsedUrl = replaceContentfulVariables(src!, replacementVariables);
      const { path } = getAppropriatePathForResource(parsedUrl);
      setRedirectAvailability(false);

      router.replace(path);
      dispatch(setLoadingState(true));
    }

    return () => {
      if (isSignsSelectedAndLinkExists) {
        setFilledSelects(defaultFilledSelects);
      }
    };
  }, [filledSelects, router, compatibilityCheck]);

  const setUsersSignSelectValue = (value: Block) => {
    if (!isRedirectAvailableRef.current) {
      setRedirectAvailability(true);
    }

    setFilledSelects({ ...filledSelects, usersSign: value });
  };

  const setPartnersSignSelectValue = (value: Block) => {
    if (!isRedirectAvailableRef.current) {
      setRedirectAvailability(true);
    }

    setFilledSelects({ ...filledSelects, partnersSign: value });
  };

  return (
    <CompatibilityCheck
      filledSelects={filledSelects}
      compatibilityCheck={compatibilityCheck}
      isMobileViewWidth={isMobileViewWidth}
      mobileViewMaxWidth={+mobileViewMaxWidth}
      horoscopeTabletMaxWidth={+horoscopeTabletMaxWidth}
      horoscopeMobileImageBackgroundWidth={+horoscopeMobileImageBackgroundWidth}
      horoscopeCompatibilitySelectImageSize={+horoscopeCompatibilitySelectImageSize}
      horoscopeDesktopRightSection={+horoscopeDesktopRightSection}
      horoscopeDesktopLeftSection={+horoscopeDesktopLeftSection}
      setUsersSignSelectValue={setUsersSignSelectValue}
      setPartnersSignSelectValue={setPartnersSignSelectValue}
    />
  );
};

export default CompatibilityCheckContainer;
