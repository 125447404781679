import { FC } from 'react';
import { BLOCKS, MARKS } from '@contentful/rich-text-types';
import cn from 'classnames';

import { Block } from 'src/__generated__/graphqlTypes';
import styles from 'components/Sections/HoroscopeNewsletters/HoroscopeNewsletters.module.scss';
import classes from 'src/styles/_commonClasses.module.scss';
import { HoroscopeNewslettersModal } from 'components/Sections/HoroscopeNewsletters';
import {
  CommonRichText,
  DataButton,
  WebpImage,
} from 'components/Shared/SharedComponents';
import { RichTextParsersConfig } from 'types/objectTypes';
import { HoroscopeNewslettersInterface } from 'components/Sections/HoroscopeNewsletters/declarations';

const getApplyClassNameFunc = (isLargeImageAvailable: boolean) => (className: string) => (
  isLargeImageAvailable
    ? styles[`${className}Large`]
    : styles[className]
);
const commonClassPrefix = 'freeHoroscope';
const paragraphClassName = 'Paragraph';
const getParsersConfig = (isLargeImageAvailable: boolean): RichTextParsersConfig => {
  const applyClassName = getApplyClassNameFunc(isLargeImageAvailable);

  return ({
    [BLOCKS.PARAGRAPH]: {
      classNames: cn(
        applyClassName(`${commonClassPrefix}${paragraphClassName}Common`),
        applyClassName(`${commonClassPrefix}${paragraphClassName}`),
      ),
    },
    [MARKS.BOLD]: {
      classNames: cn(
        applyClassName(`${commonClassPrefix}${paragraphClassName}`),
        applyClassName(`${commonClassPrefix}${paragraphClassName}`),
      ),
    },
  });
};

const getInteractBlockParsersConfig = (isLargeImageAvailable: boolean): RichTextParsersConfig => {
  const applyClassName = getApplyClassNameFunc(isLargeImageAvailable);

  return ({
    [BLOCKS.PARAGRAPH]: {
      classNames: cn(
        applyClassName(`${commonClassPrefix}Cta${paragraphClassName}Common`),
        applyClassName(`${commonClassPrefix}Cta${paragraphClassName}`),
      ),
    },
    [MARKS.BOLD]: {
      classNames: cn(
        applyClassName(`${commonClassPrefix}Cta${paragraphClassName}Bold`),
        applyClassName(`${commonClassPrefix}Cta${paragraphClassName}`),
      ),
    },
  });
};

const POSSIBLE_DIFFERENCE_IN_SIZE = 10;
const HoroscopeNewsletters: FC<HoroscopeNewslettersInterface> = ({
  isModalVisible,
  imageWidthLimit,
  currentBackground,
  freeHoroscope,
  horoscopeDesktopLeftSection,
  modalStep,
  modalErrors,
  multiplyOnImageRatio,
  openModal,
  closeModal,
  setModalErrors,
  goNextStepOrShowErrors,
  subscribeToNewsletters,
}) => {
  if (!freeHoroscope) {
    return null;
  }

  const isLargeImageAvailable = multiplyOnImageRatio(currentBackground?.width!)
    > horoscopeDesktopLeftSection + POSSIBLE_DIFFERENCE_IN_SIZE;
  const applyClassName = getApplyClassNameFunc(isLargeImageAvailable);
  const { content, contentTypesCollection } = freeHoroscope;

  const [
    rightPart,
    firstModalStep,
    lastModalStep,
  ] = (contentTypesCollection?.items || []) as Array<Block>;

  return (
    <div
      className={cn(styles.wrapper, classes.horoscopeSignSectionBottomItemOffset)}
    >
      <HoroscopeNewslettersModal
        isModalVisible={isModalVisible}
        firstModalStep={firstModalStep}
        lastModalStep={lastModalStep}
        modalStep={modalStep}
        modalErrors={modalErrors}
        closeModal={closeModal}
        setModalErrors={setModalErrors}
        goNextStepOrShowErrors={goNextStepOrShowErrors}
        subscribeToNewsletters={subscribeToNewsletters}
      />
      <div className={styles.freeHoroscope}>
        {content && (
          <CommonRichText
            content={content}
            parsersConfig={getParsersConfig(isLargeImageAvailable)}
          />
        )}
        {rightPart && (
          <div className={styles.freeHoroscopeCta}>
            {rightPart.content && (
              <CommonRichText
                content={rightPart.content}
                parsersConfig={getInteractBlockParsersConfig(isLargeImageAvailable)}
              />
            )}
            {rightPart.link && (
              <DataButton
                link={rightPart.link}
                className={applyClassName(`${commonClassPrefix}CtaButton`)}
                onClick={openModal}
              >
                {rightPart.link.title}
              </DataButton>
            )}
          </div>
        )}
      </div>
      {currentBackground && (
        <WebpImage
          image={currentBackground}
          className={styles.freeHoroscopeBackground}
          widthLimit={imageWidthLimit}
        />
      )}
    </div>
  );
};

export default HoroscopeNewsletters;
