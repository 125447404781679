import { FC } from 'react';
import cn from 'classnames';

import {
  PsychicChatButton,
  PsychicCtaButton,
  PsychicMessageButton,
  PsychicReadingButton,
  PsychicTalkButton,
} from 'entities/PsychicCtaButton';
import type { Link } from 'src/__generated__/graphqlTypes';

import styles from './styles.module.scss';

import type { IButtons } from '../config/declarations';

const Buttons: FC<IButtons> = ({
  shape = 'Rectangle',
  psychic,
  className,
  bootStatus,
  chatButton,
  talkButton,
  callbackButton,
  inQueueButton,
  messageButton,
  detailsButton,
  rescheduleButton,
  reschedulePurchaseButton,
  psychicFrame,
}) => {
  const getCommonProps = (buttonName: any) => ({
    shape,
    psychic,
    buttonName,
    className: styles.button,
    'data-button': buttonName.toLowerCase(),
  });

  const isReadingTypeButton = rescheduleButton || reschedulePurchaseButton || detailsButton;

  if (isReadingTypeButton) {
    return (
      <div className={cn(styles.buttons, className, styles.buttonsReading)}>
        <div className={styles.buttonWrapper}>
          <PsychicReadingButton
            button={rescheduleButton}
            {...getCommonProps('Reschedule')}
          />
          <PsychicReadingButton
            button={reschedulePurchaseButton}
            {...getCommonProps('ReschedulePurchase')}
          />
          <PsychicReadingButton
            button={detailsButton}
            {...getCommonProps('Details')}
          />
        </div>
      </div>
    );
  }

  return (
    <div className={cn(styles.buttons, className)}>
      <div className={styles.buttonWrapper}>
        <PsychicTalkButton
          psychicFrame={psychicFrame}
          button={talkButton}
          {...getCommonProps('Talk')}
        />
        <PsychicCtaButton
          button={callbackButton}
          {...getCommonProps('Callback')}
        />
        <PsychicCtaButton
          button={inQueueButton}
          {...getCommonProps('InQueue')}
        />
      </div>
      <div className={styles.buttonWrapper}>
        <PsychicChatButton
          button={chatButton as Link}
          bootStatus={bootStatus}
          {...getCommonProps('Chat')}
        />
        <PsychicMessageButton
          psychicFrame={psychicFrame}
          button={messageButton}
          {...getCommonProps('Message')}
        />
      </div>
    </div>
  );
};

export default Buttons;
