import { FC } from 'react';
import cn from 'classnames';
import { useSelector } from 'react-redux';

import { HeaderRating } from 'src/shared/ui/HeaderRating';
import type { Link } from 'src/__generated__/graphqlTypes';
import type { Store } from 'app-redux/types/storeTypes';
import { DataLink, WebpImage } from 'components/Shared/SharedComponents';

import styles from './styles.module.scss';

import type { IButtons, INavInteraction } from '../config/declarations';

/* Nested Component */
const Buttons: FC<IButtons> = ({ auth }) => {
  if (!auth) {
    return null;
  }

  return (
    <div className={cn(styles.buttons, styles.multiple)}>
      {auth.signUpLinkMobile && (
        <DataLink link={auth.signUpLinkMobile} className={cn(styles.button, styles.innerColor)}>
          {auth.signUpLinkMobile.title}
        </DataLink>
      )}
      { auth.signIn && (
        <DataLink link={auth.signIn} className={cn(styles.button, styles.outerColor)}>
          {auth.signIn.title}
        </DataLink>
      )}
    </div>
  );
};

/* Main Component */
const NavInteraction: FC<INavInteraction> = ({
  rating,
  auth,
  className,
  mobileApp,
}) => {
  const isMobileHeaderNavActive = useSelector((store: Store) => store
    .server.app.isMobileHeaderNavActive);
  const isAuthenticated = useSelector((store: Store) => store.server.auth.isAuthenticated);

  if (isAuthenticated) {
    const links = mobileApp?.contentTypesCollection?.items as Array<Link>;

    if (!links?.length) {
      return null;
    }

    return (
      <div className={cn(
        styles.interaction,
        styles.interactionBg,
        styles.mobileApp,
        isMobileHeaderNavActive
          ? styles.visible
          : styles.hidden,
        className,
      )}
      >
        <strong className={styles.mobileAppTitle}>{mobileApp!.title}</strong>
        <div className={styles.mobileAppStores}>
          {links.map((link) => (
            <DataLink
              key={link.entryName}
              link={link}
              className={styles.appLink}
            >
              {link.image && (
                <WebpImage
                  image={link.image}
                  className={styles.appLinkImage}
                />
              )}
            </DataLink>
          ))}
        </div>
      </div>
    );
  }

  if (!rating && !auth) {
    return null;
  }

  return (
    <div className={cn(
      styles.interaction,
      styles.interactionBg,
      isMobileHeaderNavActive
        ? styles.visible
        : styles.hidden,
      className,
    )}
    >
      <HeaderRating rating={rating} className={styles.rating} />
      <Buttons auth={auth} />
    </div>
  );
};
export default NavInteraction;
