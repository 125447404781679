import {
  ChangeEvent,
  FC,
  useEffect,
  useMemo,
} from 'react';

import { formatPhoneInput } from 'lib/text.service';
import { Tick } from 'src/shared/ui/Tick';

import style from './styles.module.scss';

import { useMessageButtonContext } from '../../lib/message';
import type { IECTelephoneSelect } from '../../config/declarations';

const ECTelephoneSelect: FC<IECTelephoneSelect> = ({ setTelephone, additionalStyles }) => {
  const styles = additionalStyles || style;
  const { telephones } = useMessageButtonContext();
  const onSelect = (event: ChangeEvent<HTMLSelectElement>) => {
    const index = event.target.selectedIndex;

    return setTelephone(telephones?.[index]);
  };
  const defaultValue = useMemo(() => telephones?.[0], [telephones]);

  useEffect(() => {
    if (!defaultValue) {
      return;
    }

    setTelephone(defaultValue);
  }, [defaultValue]);

  if (!telephones) {
    return null;
  }

  return (
    <div className={styles.selectWrapper}>
      <select
        defaultValue={defaultValue?.phoneNumber}
        onChange={onSelect}
        className={styles.selectNumbers}
      >
        {telephones?.map((telephone) => (
          <option key={telephone.phoneNumber} value={telephone.phoneNumber}>
            {formatPhoneInput(telephone)}
          </option>
        ))}
      </select>
      <Tick
        className={styles.selectNumbersIcon}
        borderWidth={3}
        color="#BB5204"
        direction="bottom"
      />
    </div>
  );
};

export default ECTelephoneSelect;
