import { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';

import { psychicBioPath } from 'contentfulScripts/migration/regex.service';

import { useCustomRouter } from '../history/hooks';
import { BrowserHistory } from '../history/structure';

import { BioOverlay, IFRAME_NAVIGATION_EVENT } from '.';

export const usePrepareBioOverlay = () => {
  const path = useRef<string>();
  const dispatch = useDispatch();
  const router = useCustomRouter();

  useEffect(() => {
    BioOverlay.setDispatch(dispatch);
  }, [dispatch]);

  useEffect(() => {
    const handler = (a: any) => {
      if (a.detail.includes(window.location.origin)
        || a.detail.includes(process.env.NEXT_PUBLIC_BASE_SERVER_URL)
        || (a.detail.startsWith('http') && !a.detail.includes('localhost'))) {
        window.location.href = a.detail;

        return;
      }

      router.push(a.detail).then(BioOverlay.remove);
    };

    document.addEventListener(IFRAME_NAVIGATION_EVENT, handler);

    return () => document.removeEventListener(IFRAME_NAVIGATION_EVENT, handler);
  }, []);

  useEffect(() => {
    BioOverlay.setRouter(router);
  }, [router]);

  useEffect(() => {
    path.current = window.location.pathname;
    const history = new BrowserHistory(window.location.pathname);

    const { pushState } = window.history;
    /* Override of url change */
    window.history.pushState = function (data: any = {}, unused: string = '', url?: string | null) {
      const { pathname } = window.location;
      history.visit(url as string);
      const current = history.getCurrent();
      const isBioFarInHistory = current?.previous?.previous?.link.match(psychicBioPath);

      if (isBioFarInHistory) {
        BioOverlay.remove();
      }

      const isNextPageBioToo = path.current !== url
        && path.current?.match(psychicBioPath)
        && url?.match(psychicBioPath);

      if (isNextPageBioToo) {
        BioOverlay.remove();
        const fullUrl = window.location.origin + url;

        return pushState.apply(window.history, [data, unused, fullUrl]);
      }

      if (path?.current) {
        path.current = url || pathname;
      }

      pushState.apply(window.history, [data, unused, url]);
    };

    /* Listening for back/forward buttons click */
    const handler = (event: Record<string, any>) => {
      const { pathname } = window.location;
      history.recordPop(pathname);
      const current = history.getCurrent();

      if (current?.link.match(psychicBioPath)) {
        const isAbleToShow = BioOverlay.show();

        if (!isAbleToShow) {
          return window.location.reload();
        }
      }

      if (current?.next?.link.match(psychicBioPath)) {
        BioOverlay.hide();
      }

      path.current = event?.state?.url || pathname;
    };

    window.addEventListener('popstate', handler);

    return () => {
      history.destroy();
      window.removeEventListener('popstate', handler);
    };
  }, []);
};
