import { FC } from 'react';
import Modal from 'react-modal';
import cn from 'classnames';

import classes from 'styles/_commonClasses.module.scss';

import styles from './styles.module.scss';

import type { IEcEModal } from '../../config/declarations';

const ErrorModal: FC<IEcEModal> = ({
  isOpened,
  closeModal,
}) => {
  const heading = 'Error';
  const errorMessage = '\'In Queue\', "You are already in queue", \'close All popup\'';

  return (
    <Modal
      isOpen={isOpened}
      className={cn(styles.messageModal, classes.modal)}
      overlayClassName={classes.modalBackground}
      shouldFocusAfterRender={false}
    >
      <div className={styles.errorModal}>
        <h2 className={styles.errorHeading}>{heading}</h2>
        <p className={styles.errorMessage}>{errorMessage}</p>
        <button
          type="button"
          className={styles.closeButton}
          onClick={() => {
            closeModal();
          }}
        >
          Close
        </button>
      </div>
    </Modal>
  );
};

export default ErrorModal;
