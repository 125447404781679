import { GAReplacementValue, Position } from 'constants/enums';
import type { Psychic } from 'src/__generated__/graphqlTypes';
import type { RightPsychic } from 'types/objectTypes';

export const useProfileLink = (
  psychicFrame: Psychic,
  psychic: RightPsychic,
  position: Position | '' = '',
) => {
  const profileLink = psychicFrame.profileButton;
  const finalProfileLink = (psychicFrame?.profileButton?.src)
    ? psychicFrame.profileButton.src + psychic.psychicBioURL
    : psychic.psychicBioURL;
  const { _meta: meta = {} } = profileLink?.gaProperties || {};

  return {
    profileLink,
    profileUrl: finalProfileLink,
    gaData: {
      [GAReplacementValue.PSYCHIC_NAME]: psychic.lineName,
      [GAReplacementValue.POSITION]: meta[position],
    },
  };
};
