import { FC, useState } from 'react';
import cn from 'classnames';

import classes from 'src/styles/_commonClasses.module.scss';
import { DataButton } from 'components/Shared/SharedComponents';
import { ElementAlign } from 'constants/enums';
import { YOUTUBE } from 'constants/constants';

import styles from './TextAndSideAsset.module.scss';

import type { IVideo } from '../config/declarations';

const Video: FC<IVideo> = ({
  video,
  preview,
  extraData,
  videoAutoplay,
}) => {
  const [isPreviewImageClicked, setPreviewImageClicked] = useState<boolean>(false);
  const {
    assetAlign = ElementAlign.LEFT,
  } = extraData || {};

  const onClickPreviewImage = () => {
    setPreviewImageClicked(true);
  };
  const isVideoWithPreview = !isPreviewImageClicked && preview;
  const isVideo = isPreviewImageClicked;

  if (isVideoWithPreview) {
    return (
      <DataButton
        link={video}
        className={cn(
          styles[`${assetAlign}Asset`],
          styles[`${assetAlign}AssetButton`],
          classes.cursorPointer,
        )}
        onClick={onClickPreviewImage}
        onKeyPress={onClickPreviewImage}
      >
        {preview}
      </DataButton>
    );
  }

  if (isVideo) {
    const { src, title, image } = video;
    const isYoutube = src?.toLowerCase()?.includes(YOUTUBE);

    if (isYoutube) {
      return (
        <>
          <iframe
            className={cn(styles[`${assetAlign}Asset`], styles[`${assetAlign}AssetVideo`])}
            src={src!}
            title={title!}
            allow={videoAutoplay ? 'autoplay' : ''}
            allowFullScreen
          />
        </>

      );
    }

    const videoSource = (src === '#' || !src)
      ? image?.url
      : src;

    return (
      // eslint-disable-next-line jsx-a11y/media-has-caption
      <video
        controls
        autoPlay={videoAutoplay}
        className={cn(styles[`${assetAlign}Asset`], styles[`${assetAlign}AssetVideo`])}
      >
        <source src={videoSource!} />
      </video>
    );
  }

  return null;
};

export default Video;
