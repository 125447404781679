import { FC, useState } from 'react';
import cn from 'classnames';
import { BLOCKS } from '@contentful/rich-text-types';

import classes from 'src/styles/_commonClasses.module.scss';
import { RichTextParsersConfig } from 'types/objectTypes';
import {
  CommonRichText,
  DataButton,
  ValidationMark,
} from 'components/Shared/SharedComponents';

import ECTelephoneSelect from './ECTalkSelect';
import { ECAddPhoneNumber } from './ECTalkActions';
import styles from './styles.module.scss';

import { useMessageButtonContext } from '../../lib/message';
import type { IEcTelephone } from '../../config/declarations';

const titleConfig: RichTextParsersConfig = {
  [BLOCKS.PARAGRAPH]: {
    classNames: styles.notificationText,
  },
};

const contentConfig: RichTextParsersConfig = {
  [BLOCKS.PARAGRAPH]: {
    classNames: styles.text,
  },
};

const ECTalkTelephone: FC<IEcTelephone> = ({
  block,
  telephone,
  setTelephone,
}) => {
  const store = useMessageButtonContext();
  const [
    isNewNumberSetup,
    setNewNumberSetupState,
  ] = useState<boolean>(store.telephones?.length === 0);
  const { countryList } = useMessageButtonContext();

  if (!block) {
    return null;
  }

  const { link, contentTypesCollection } = block;
  const additionalComponents = contentTypesCollection?.items || [];

  return (
    <div className={styles.telephone}>
      <CommonRichText content={block.richTitle} parsersConfig={titleConfig} />
      <CommonRichText content={block.content} parsersConfig={contentConfig} />
      {isNewNumberSetup && countryList
        ? (
          <ECAddPhoneNumber
            setNewNumberSetupState={setNewNumberSetupState}
            countryList={countryList}
            link={link}
            placeholder={block.title}
            additionalComponents={additionalComponents as any}
          />
        )
        : (
          <div className={cn(styles.actions, styles.actionsSidesPadding)}>
            <ECTelephoneSelect setTelephone={setTelephone} />
            {(telephone?.phoneType === 1) && <ValidationMark isValid />}
            {link && (
              <DataButton
                link={link}
                className={cn(styles.addButton, classes.textBold)}
                onClick={() => setNewNumberSetupState(true)}
              >
                {link.title}
              </DataButton>
            )}
          </div>
        )}
    </div>
  );
};
export default ECTalkTelephone;
